import React, { Component, useState, useEffect } from 'react'
import SODashboard from './SODashboard.module.css'
import Footer from '../../components/majors/Footer'
import Filter from '../../components/majors/Filter'
import StoreProducts from '../../components/majors/StoreProducts'
import { FiBell, FiHelpCircle, FiGrid, FiCircle, FiHome, FiMail, FiBox } from "react-icons/fi";
import { BsCreditCard, BsEyeSlash } from "react-icons/bs";
import { VscAccount, VscBellDot } from "react-icons/vsc";
import { FaRegCalendarAlt, FaStore } from "react-icons/fa";
import { IoMdCart, IoMdHeart, IoMdPeople } from "react-icons/io";
import { AiFillBank } from "react-icons/ai";
import { MdAddShoppingCart } from "react-icons/md";
import Kemi from '../../assets/prodItems/Kemi.jpg'
import Trophy from '../../assets/sellerDashboard/trophy.png'
import { CgMenuBoxed } from "react-icons/cg";
import {Link } from 'react-router-dom'


const StoreOwnersDashBoard = props => {



   const [menuappear, setMenuappear] = useState(false);
   const [width, setWidth] = useState(window.innerWidth);

   const updateWidth = () => {
      setWidth(window.innerWidth);
   }

   useEffect(() => {
      window.addEventListener("resize", updateWidth);
      return () => window.removeEventListener("resize", updateWidth);
   })

   const menuShow = () => {
      setMenuappear(!menuappear)
   }






   let smallSize = <div> <CgMenuBoxed onClick={menuShow} className={SODashboard.menuButton} style={{fontSize: "2.5em", color: "#000033"}} /> </div>

   let Menu = <div className={SODashboard.leftCent} onClick={menuShow}>
      <div className={SODashboard.Title}><Link to="/homepage" className={SODashboard.Link}> BAM<span className={SODashboard.ZI}>ZI</span> </Link></div>

      <div className={SODashboard.Section1}>
         <div> <Link to='/soDashboard' className={SODashboard.Link}> <span><FiHome /></span>  Dashboard </Link> </div>
         <div> <span><FiCircle /></span> Analytics </div>
         <div>  <Link to='/soMarketing' className={SODashboard.Link}> <span><FiCircle /></span> Marketing </Link> </div>
      </div>


      <div className={SODashboard.Section2}>
         <div className={SODashboard.SecTitle}  >PRODUCTS</div>
         
         <div>  <Link to='/storeOwnersStore' className={SODashboard.Link}> <span><FiBox /></span> All Products </Link> </div>
         <div> <Link to='/soAddProduct' className={SODashboard.Link}> <span><MdAddShoppingCart /></span> Add Products </Link> </div>
         <div>  <Link to='/soSales' className={SODashboard.Link}>  <span><IoMdCart /></span> Sales </Link> </div>
         <div> <span><FiGrid /></span> Categories </div>
      </div>

      <div className={SODashboard.Section3}>
         <div className={SODashboard.SecTitle}  >Transactions</div>
         <div>  <Link to='/soHistory' className={SODashboard.Link}>  <span><FaRegCalendarAlt /></span> History </Link> </div>
         <div> <span><BsCreditCard /></span> Billings </div>
      </div>

      <div className={SODashboard.Section4}>
         <div className={SODashboard.SecTitle}  >PRODUCTS</div>
         <div> <span><VscAccount /></span> Account </div>
         <div> <Link to='/soCustomize' className={SODashboard.Link}> <span><FiBox /></span>Customise Shop </Link> </div>
         <div> <span><FiHelpCircle /></span> Help </div>
      </div>

   </div>

   if (menuappear) {
      smallSize = Menu
   }

   return (
      <div className={SODashboard.StoreOwnersStore}>
         <div className={SODashboard.Container}>

            <div className={SODashboard.left}>
               {width > 860 ? Menu: smallSize}
            </div>


            <div className={SODashboard.Right}>
               <div className={SODashboard.storeOwnersTopTab}>

                  <div className={SODashboard.NavList} >
                     <div>
                        <ul className={SODashboard.NavList1} >
                           <li><div> <AiFillBank /> Account: $12,990 </div></li>
                           <li><BsEyeSlash /></li>
                           <li><FiBell /></li>
                           <li><FiMail /></li>
                        </ul>
                     </div>

                     <div>
                        <ul className={SODashboard.NavList2} >
                           <li className={SODashboard.liFlex}> <FaStore /> </li>
                           <li className={SODashboard.liFlex}> Evans Bex Electronics Store </li>
                           <li> <img src={Kemi} alt="A profile pic" /> </li>
                        </ul>
                     </div>
                  </div>

               </div>





               <div className={SODashboard.Dashboard}>
                  <div className={SODashboard.DashboardCont}>

                     <div className={SODashboard.quickStats}>Quick Stats </div>

                     <div className={SODashboard.quickStatsCard}>

                        <div className={SODashboard.totalSales}>
                           <div className={SODashboard.statTit}> Total Sales </div>
                           <div className={SODashboard.statVal}> 28,345 </div>
                        </div>

                        <div className={SODashboard.badGoods}>
                           <div className={SODashboard.statTit}> Bad Goods </div>
                           <div className={SODashboard.statVal}> 28 errors </div>
                        </div>

                        <div className={SODashboard.pendingSales}>
                           <div className={SODashboard.statTit}> Pending Sales </div>
                           <div className={SODashboard.statVal}> 120 </div>
                        </div>

                        <div className={SODashboard.wishlist}>
                           <div className={SODashboard.statTit}>Wishlist Store</div>
                           <div className={SODashboard.statVal}>500 <span><IoMdHeart /></span> </div>
                        </div>
                        
                        <div className={SODashboard.customers}>
                           <div className={SODashboard.statTit}> Customers </div>
                           <div className={SODashboard.statVal}> 829 <span><IoMdPeople /></span> </div>
                        </div>

                     </div>


                     <div className={SODashboard.middle}>

                        <div className={SODashboard.latestSales}>
                           <div className={SODashboard.latestSalesTit}>Latest Sales (28) </div>

                           <div className={SODashboard.latestSalesProd}>
                              
                              <div className={SODashboard.latestSalesProdDet}>
                                 <div className={SODashboard.latestSalesImg}>  
                                    <img src={Trophy} alt="trophy" />
                                 </div>
                                 <div className={SODashboard.latestSalesProdDetCent}>
                                    <div className={SODashboard.latestSalesProdName}>iPhone X 6GB Ram </div>
                                    <div className={SODashboard.latestSalesProdQty}> QTY <span>6</span> </div>
                                    <div className={SODashboard.latestSalesProdPrice}> $1500 </div>
                                 </div>
                              </div>
                              
                              <div className={SODashboard.latestSalesTime}> 30mins ago</div>
                              
                              
                           </div>
                           
                           <div className={SODashboard.latestSalesProd}>
                              
                              <div className={SODashboard.latestSalesProdDet}>
                                 <div className={SODashboard.latestSalesImg}>  
                                    <img src={Trophy} alt="trophy" />
                                 </div>
                                 <div className={SODashboard.latestSalesProdDetCent}>
                                    <div className={SODashboard.latestSalesProdName}>iPhone X 6GB Ram </div>
                                    <div className={SODashboard.latestSalesProdQty}> QTY <span>6</span> </div>
                                    <div className={SODashboard.latestSalesProdPrice}> $1500 </div>
                                 </div>
                              </div>
                              
                              <div className={SODashboard.latestSalesTime}> 2hours ago</div>
                              
                              
                           </div>
                           
                           
                           <div className={SODashboard.latestSalesView}>View Sales</div>
                        </div>
                        
                        <div className={SODashboard.middleSides}>
                        <div className={SODashboard.errOrder}>
                           <div  className={SODashboard.errOrderBell}> <VscBellDot /> </div>

                           <div className={SODashboard.errOrderTit}>Error in Order</div>

                           <div className={SODashboard.errOrderText}>You have an urgent package to take care of, please check and revert to them. </div>
                        </div>

                        <div className={SODashboard.congrats}>
                           <div className={SODashboard.congratsTit}>Congratulations John!</div>

                           <div className={SODashboard.congratsBest}>Best seller of the month</div>

                           <div className={SODashboard.congratsValImg}>
                              <div>
                                 <div className={SODashboard.congratsVal}> $89K </div>
                                 <div className={SODashboard.congratsText}> You have done 57.6% more sales this week.</div>
                              </div>

                              <div className={SODashboard.congratsImg}> <img src={Trophy} alt="trophy" /> </div>
                           </div>
                           
                           <div className={SODashboard.congratsView}>View Sales</div>

                        </div>
                        </div>

                     </div>


                     <div className={SODashboard.bottom}>
                        <div className={SODashboard.leadSales}>

                           <div className={SODashboard.leadSalesTit}>Lead Sales</div>

                           <div className={SODashboard.leadSalesTime}>
                              <div className={SODashboard.leadSalesTimeAct}>All Time</div>
                              <div>This Year</div>
                              <div>This Month</div>
                              <div>This Week</div>
                           </div>
                           
                           <div className={SODashboard.chart}>
                              Chart Goes in here.
                           </div>

                        </div>

                        <div className={SODashboard.latestTickets}>
                           <div className={SODashboard.latestTicketsTit}>Latest Tickets</div>

                           <div>
                              <div> <span>Ticket</span> #4231852</div>
                              <div>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sed animi quidem porro! Nihil totam fuga quas.</div>
                           </div>
                           
                           <div>
                              <div> <span>Ticket</span> #4231852</div>
                              <div>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sed animi quidem porro! Nihil totam fuga quas.</div>
                           </div>

                           <div>
                              <div> <span>Ticket</span> #4231852</div>
                              <div>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sed animi quidem porro! Nihil totam fuga quas.</div>
                           </div>

                           <div>
                              <div> <span>Ticket</span> #4231852</div>
                              <div>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sed animi quidem porro! Nihil totam fuga quas.</div>
                           </div>

                           <div>
                              <div> <span>Ticket</span> #4231852</div>
                              <div>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sed animi quidem porro! Nihil totam fuga quas.</div>
                           </div>
                        </div>
                        
                     </div>

                  </div>
               </div>
            </div>

         </div>
      </div>
      

   )

}


export default StoreOwnersDashBoard;
