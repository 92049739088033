import React, { Component } from 'react'
import classes from './StoreProducts.module.css'
import { useHistory, Link } from "react-router-dom"
import { IoMdMenu } from "react-icons/io";
import { FiGrid, FiTrash2 } from "react-icons/fi";
import ApplePodsPic from '../../assets/prodItems/applePods.png';
import AppleWatchPic from '../../assets/prodItems/appleWatch.png';
import IphonePic from '../../assets/prodItems/iPhone.png';
import LaptopPic from '../../assets/prodItems/laptop.jpg';
import { MdStar } from "react-icons/md";
import { BsPencilSquare } from 'react-icons/bs';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import axios from 'axios';
import swal from 'sweetalert';

class StoreProducts extends Component {

   state = {
      product: []
   };



   getProduct = async () => {
      const req = {
         storeName: localStorage.getItem('shopName')
      }
      /* const product = await axios.post("http://localhost/bamzi/api/shops/products", req); */
      const product = await axios.post("https://bamziapi.ivyarc.com/api/api/shops/products", req);
      this.setState({ product: product.data }, console.log(product.data));
   };

   editProduct() {
      this.props.history.push("/soAddProduct")
   }

   componentWillMount() {
      this.getProduct();
   }

   onDelete(productItem) {
         /* fetch("http://localhost/bamzi/api/shops/delete", { */
         fetch("https://bamziapi.ivyarc.com/api/shops/delete", {
         method: "DELETE",
         headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
         },
         body: JSON.stringify({
            productName: productItem.productName,
            storeName: window.localStorage.getItem("shopName"),
         })
      }).then((Response) => Response.json())
         .then((result) => {
            if (result.message == "success") {
               swal({
                  title: "Error!",
                  text: "Product Not In Cart.",
                  icon: 'error',
                  button: true
               });
               //this.props.history.push("/login");
            }
            else {
               swal({
                  text: "Product Removed From Cart...",
                  icon: 'success',
                  button: true
               });
               window.location.reload(true);
            }
         });
   };

   render() {
      const { product } = this.state;

      return (

         <div className={classes.productView}>

            <div className={classes.prodTop}>
               <div>7,618 results found in 5ms</div>
               <div className={classes.prodTopIcons}>
                  <div><FiGrid /></div>
                  <div> <IoMdMenu /></div>
               </div>

            </div>

            <div></div>
            <div className={classes.prodItems}>
               {product.length > 0 ? product.map(item => {
                  const { id, productRating, productName, description, productPrice, active, productQuantity, productSize, productColor, productOwner, productImage1, productImage2, productImage3 } = item;
                  const productItem = { id, productRating, productName, description, productPrice, active, productQuantity, productSize, productColor, productOwner, productImage1, productImage2, productImage3 };
                  return (
                     <div className={classes.prodCard}>
                        <div className={classes.prodImage}>
                           <img src={LaptopPic} alt="A profile pic" className={classes.prodPic} />
                        </div>

                        <div className={classes.prodRatPrice}>
                           <div className={classes.prodRat}> {productRating} <span><MdStar /></span></div>
                           <div> ${productPrice}</div>
                        </div>

                        <div className={classes.prodTitleDesc}>
                           <div>{productName}</div>
                           <span>{description}</span>
                        </div>

                        <div className={classes.prodUpdate}>
                           <div className={classes.prodDisable}><AiOutlineExclamationCircle /> Disable</div>
                           <Link className={classes.prodEdit} to={`/soAddProduct`}>
                              <BsPencilSquare /> Edit
                           </Link>
                           <div className={classes.prodDelete} /* onClick={() => { this.onDelete(productItem) }}  */> <FiTrash2 /> Delete </div>
                        </div>

                     </div>
                  )
               }) :
                  <div>
                     No Product(s) In Store
                  </div>
               }




            </div>


         </div>

      )

   }

}

export default StoreProducts
