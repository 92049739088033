import React, { Component, useState, useEffect } from "react";
import SOSales from "./SOSales.module.css";
import Footer from "../../components/majors/Footer";
import Filter from "../../components/majors/Filter";
import StoreProducts from "../../components/majors/StoreProducts";
import {
   FiBell,
   FiHelpCircle,
   FiGrid,
   FiCircle,
   FiHome,
   FiMail,
   FiBox,
} from "react-icons/fi";
import { BsCreditCard, BsEyeSlash } from "react-icons/bs";
import { VscAccount, VscBellDot, VscSettings } from "react-icons/vsc";
import { FaShippingFast, FaRegCalendarAlt, FaStore } from "react-icons/fa";
import { IoMdCart, IoMdHeart, IoMdPeople, IoMdMenu } from "react-icons/io";
import { AiFillBank } from "react-icons/ai";
import { MdAddShoppingCart, MdError } from "react-icons/md";
import Kemi from "../../assets/prodItems/Kemi.jpg";
import Trophy from "../../assets/sellerDashboard/trophy.png";
import { CgMenuBoxed } from "react-icons/cg";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

const StoreOwnersSales = (props) => {
   const [menuappear, setMenuappear] = useState(false);
   const [width, setWidth] = useState(window.innerWidth);

   const updateWidth = () => {
      setWidth(window.innerWidth);
   };

   useEffect(() => {
      window.addEventListener("resize", updateWidth);
      return () => window.removeEventListener("resize", updateWidth);
   });

   const menuShow = () => {
      setMenuappear(!menuappear);
   };

   let smallSize = (
      <div>

         <CgMenuBoxed
            onClick={menuShow}
            className={SOSales.menuButton}
            style={{ fontSize: "2.5em", color: "#000033" }}
         />
      </div>
   );

   let Menu = (
      <div className={SOSales.leftCent} onClick={menuShow}>
         <div className={SOSales.Title}><Link to="/homepage" className={SOSales.Link}> BAM<span className={SOSales.ZI}>ZI</span> </Link></div>

         <div className={SOSales.Section1}>
            <div> <Link to="/SODashboard" className={SOSales.Link}> <span> <FiHome /> </span> Dashboard </Link></div>
            <div> <span> <FiCircle /></span>Analytics</div>
            <div> <Link to="/soMarketing" className={SOSales.Link}> <span> <FiCircle /> </span>  Marketing </Link> </div>
         </div>

         <div className={SOSales.Section2}>
            <div className={SOSales.SecTitle}>PRODUCTS</div>

            <div><Link to="/storeOwnersStore" className={SOSales.Link}> <span> <FiBox /> </span> All Products </Link></div>
            <div> <Link to="/soAddProduct" className={SOSales.Link}> <span> <MdAddShoppingCart /> </span> Add Products </Link> </div>
            <div> <Link to="/soSales" className={SOSales.Link}> <span> <IoMdCart /> </span> Sales </Link> </div>
            <div> <span>  <FiGrid /> </span> Categories </div>  
         </div>

         <div className={SOSales.Section3}>
            <div className={SOSales.SecTitle}>Transactions </div>
            <div> <Link to="/soHistory" className={SOSales.Link}> <span> <FaRegCalendarAlt /> </span> History </Link> </div>
            <div>  <span> <BsCreditCard /> </span> Billings  </div>
         </div>

         <div className={SOSales.Section4}>
            <div className={SOSales.SecTitle}>SETTINGS </div>
            <div> <span> <VscAccount /> </span> Account </div>
            <div> <Link to="/soCustomize" className={SOSales.Link}> <span> <FiBox /> </span> Customise Shop </Link> </div>
            <div> <span> <FiHelpCircle /> </span> Help </div> </div>
         </div>
   );

   if (menuappear) {
      smallSize = Menu;
   }

   return (
      <div className={SOSales.StoreOwnersShop}>
         <div className={SOSales.Container}>
            <div className={SOSales.left}>{width > 860 ? Menu : smallSize}</div>

            <div className={SOSales.Right}>
               <div className={SOSales.storeOwnersTopTab}>
                  <div className={SOSales.NavList}>
                     <div>
                        <ul className={SOSales.NavList1}>
                           <li><div> <AiFillBank /> Account: $12,990 </div></li>
                           <li> <BsEyeSlash /> </li>
                           <li> <FiBell /> </li>
                           <li> <FiMail /> </li>
                        </ul>
                     </div>

                     <div>
                        <ul className={SOSales.NavList2}>
                           <li className={SOSales.liFlex}> <FaStore /> </li>
                           <li className={SOSales.liFlex}>  Evans Bex Electronics Store </li>
                           <li> <img src={Kemi} alt="A profile pic" /> </li>
                        </ul>
                     </div>
                  </div>
               </div>

               <div className={SOSales.customize}>
                  <div className={SOSales.customizeCont}>

                     <div className={SOSales.top}>
                        <div className={SOSales.top1}>
                           <div className={SOSales.tit}> <span> <FaShippingFast /> </span>  Sales and Orders </div>
                           
                           <div className={SOSales.save}> <Link to="/soHistory" className={SOSales.Link}> View Sales History  </Link></div>
                        </div>

                        <div className={SOSales.top2}>
                           <div className={SOSales.top2left}>
                              <div>
                                 <input type="text" placeholder="Search Anything" />
                              </div>

                              <div className={SOSales.top2LeftText}>
                                 You can check list goods that has been delivered to your
                                 customers and once a good has been delivered, set the
                                 order to delivered.
                              </div>
                           </div>

                           <div className={SOSales.top2right}>
                              <div className={SOSales.quickStatsCard}>
                                 <div className={SOSales.badGoods}>
                                    <div className={SOSales.statTit}> Bad Goods </div>
                                    <div className={SOSales.statVal}> 28 errors </div>
                                 </div>

                                 <div className={SOSales.pendingSales}>
                                    <div className={SOSales.statTit}> Pending Sales </div>
                                    <div className={SOSales.statVal}> 120 </div>
                                 </div>
                              </div>

                              <div>
                                 <div className={SOSales.prodTopIcons}>
                                    <div>
                                       <FiGrid />
                                    </div>
                                    <div>
                                       <IoMdMenu />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className={SOSales.middle}>

                        {width > 1200 ?
                           <div className={SOSales.tableTop1}>
                              <div></div>
                              <div>Product Name</div>
                              <div>Options</div>
                              <div>Shipping Address</div>
                              <div>QTY</div>
                              <div>Price</div>
                              <div>Status</div>
                              <div>Date</div>
                              <div></div>
                           </div>
                           :
                           <div className={SOSales.tableTop2}>
                              <div>Sales Details</div>
                           </div>
                        }
                        <div className={SOSales.tableRow}>
                           <div className={SOSales.rowCheck}> <input type="checkbox" />  </div>
                           <div className={SOSales.rowProduct}>
                              <div> <div> <img src={Trophy} alt="rowPic" /> </div>  </div>
                              <div className={SOSales.rowProductTit}>iPhone wrist band + free Pouch</div>
                           </div>
                           <div className={SOSales.rowOptions}>
                              <div>Color <span className={SOSales.rowOptionsColor} style={{ backgroundColor: "red" }}></span></div>
                              <div>Size <span className={SOSales.rowOptionsSize}>XL</span></div>
                           </div>
                           <div className={SOSales.rowAddress}>
                              <div>Omolola Daniel</div>
                              <div className={SOSales.rowAddressLink}>Shipping Address</div>
                           </div>
                           <div className={SOSales.rowQTY}><span>4</span></div>
                           <div className={SOSales.rowPrice}>$150</div>
                           <div className={SOSales.rowStatus}>Pending</div>
                           <div className={SOSales.rowDate}>3/12/2021</div>
                           <div className={SOSales.rowSettings}> <VscSettings /> </div>
                        </div>
                        

                        <div className={SOSales.tableRow}>
                           <div className={SOSales.rowCheck}> <input type="checkbox" />  </div>
                           <div className={SOSales.rowProduct}>
                              <div> <div> <img src={Trophy} alt="rowPic" /> </div>  </div>
                              <div className={SOSales.rowProductTit}>iPhone X 6GB Ram</div>
                           </div>
                           <div className={SOSales.rowOptions}>
                              <div>Ram <span className={SOSales.rowOptionsSize}>256</span></div>
                              <div>Size <span className={SOSales.rowOptionsSize}>XL</span></div>
                           </div>
                           <div className={SOSales.rowAddress}>
                              <div>Agnes Angela</div>
                              <div className={SOSales.rowAddressLink}>Shipping Address</div>
                           </div>
                           <div className={SOSales.rowQTY}><span>1</span></div>
                           <div className={SOSales.rowPrice}>$1500</div>
                           <div className={SOSales.rowStatus}>Pending</div>
                           <div className={SOSales.rowDate}>3/12/2021</div>
                           <div className={SOSales.rowSettings}> <VscSettings /> </div>
                        </div>


                        <div className={SOSales.tableRow}>
                           <div className={SOSales.rowCheck}> <input type="checkbox" />  </div>
                           <div className={SOSales.rowProduct}>
                              <div> <div> <img src={Trophy} alt="rowPic" /> </div>  </div>
                              <div className={SOSales.rowProductTit}>iPhone wrist band + free Pouch</div>
                           </div>
                           <div className={SOSales.rowOptions}>
                              <div>Color <span className={SOSales.rowOptionsColor} style={{ backgroundColor: "red" }}></span></div>
                              <div>Size <span className={SOSales.rowOptionsSize}>XL</span></div>
                           </div>
                           <div className={SOSales.rowAddress}>
                              <div>Omolola Daniel</div>
                              <div className={SOSales.rowAddressLink}>Shipping Address</div>
                           </div>
                           <div className={SOSales.rowQTY}><span>4</span></div>
                           <div className={SOSales.rowPrice}>$150</div>
                           <div className={SOSales.rowStatus}>Pending</div>
                           <div className={SOSales.rowDate}>3/12/2021</div>
                           <div className={SOSales.rowSettings}> <VscSettings /> </div>
                        </div>

                        <div className={SOSales.tableRow}>
                           <div className={SOSales.rowCheck}> <input type="checkbox" />  </div>
                           <div className={SOSales.rowProduct}>
                              <div> <div> <img src={Trophy} alt="rowPic" /> </div>  </div>
                              <div className={SOSales.rowProductTit}>iPhone wrist band + free Pouch</div>
                           </div>
                           <div className={SOSales.rowOptions}>
                              <div>Color <span className={SOSales.rowOptionsColor} style={{ backgroundColor: "aquamarine" }}></span></div>
                              <div>Size <span className={SOSales.rowOptionsSize}>XL</span></div>
                           </div>
                           <div className={SOSales.rowAddress}>
                              <div>Omolola Daniel</div>
                              <div className={SOSales.rowAddressLink}>Shipping Address</div>
                           </div>
                           <div className={SOSales.rowQTY}><span>2</span></div>
                           <div className={SOSales.rowPrice}>$150</div>
                           <div className={SOSales.rowStatus}>Error <span> <MdError /> </span></div>
                           <div className={SOSales.rowDate}>3/12/2021</div>
                           <div className={SOSales.rowSettings}> <VscSettings /> </div>
                        </div>

                     </div>

                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default StoreOwnersSales;
