import React, { Component } from 'react';
import preRegStyles from './preReg.module.css';
import LeftImg from '../../assets/preReg/Home - 7-image.jpg';
import { MdStore } from "react-icons/md";
/* import axios from 'axios'; */
import swal from 'sweetalert';
import Header from '../../components/majors/Headers'



class CustomerReg extends Component {
    constructor() {
        super();
        this.onchangeFirstname = this.onchangeFirstname.bind(this);
        this.onchangeLastname = this.onchangeLastname.bind(this);
        this.onchangeEmail = this.onchangeEmail.bind(this);
        this.onchangeAddress = this.onchangeAddress.bind(this);
        this.onchangeCity = this.onchangeCity.bind(this);
        this.onchangeState= this.onchangeState.bind(this);
        this.onchangeCountry= this.onchangeCountry.bind(this);
        this.onchangePostalCode= this.onchangePostalCode.bind(this);
        this.onchangePhoneNumber= this.onchangePhoneNumber.bind(this);
        this.onchangeSalesRepEmployeeNumber= this.onchangeSalesRepEmployeeNumber.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        
        this.state = { 
            firstname: "",
            lastname: "",
            email: "",
            address: "",
            state:"",
            city: "",
            country:"",
            postalCode: "",
            phoneNumber: "",
            salesRepEmployeeNumber: ""
        };
    }

    /* Delete when done */
    componentDidMount() {
        
        setTimeout(() => {
            this.props.history.push("/userDetails");
        }, 5000);
    }

    onchangeFirstname(e) {
        this.setState({
            firstname: e.target.value
        });
    };
    onchangeLastname(e) {
        this.setState({
            lastname: e.target.value
        });
    };
    onchangeEmail(e) {
        this.setState({
            email: e.target.value
        });
    };
    onchangeAddress(e) {
        this.setState({
            address: e.target.value
        });
    };

    onchangePhoneNumber(e) {
        this.setState({
            phoneNumber: e.target.value
        });
    };
    onchangeCity(e) {
        this.setState({
            city: e.target.value
        });
    };
    onchangeState(e) {
        this.setState({
            state: e.target.value
        });
    };
    onchangeCountry(e) {
        this.setState({
            country: e.target.value
        });
    };
    onchangePostalCode(e) {
        this.setState({
            postalCode: e.target.value
        });
    };
    onchangeSalesRepEmployeeNumber(e) {
        this.setState({
            salesRepEmployeeNumber: e.target.value
        });
    };


    onSubmit(event){
        fetch("https://bamziapi.ivyarc.com/api/customerReg", { 
        // fetch("http://localhost/bamzi/api/customerReg", {
            method: "POST",
            headers: {
                "Accept":"application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                email: this.state.email,
                address: this.state.address,
                city: this.state.city,
                state: this.state.state,
                country: this.state.country,
                postalCode: this.state.postalCode,
                phoneNumber: this.state.phoneNumber,
                salesRepEmployeeNumber: this.state.salesRepEmployeeNumber,
            })
        }).then((Response) => Response.json())
            .then((result) => {
                if(result.message == "success"){
                    swal({
                        title: "Updated!",
                        text: "Customer Details Updated Successfully...",
                        icon: 'success',
                        button: true
                     });
                     this.props.history.push("/login");
                }
                else{
                    swal({
                        title: "Error!",
                        text: "Please Fill All Fields...",
                        icon: 'error',
                        button: true
                     });
                }
            });
    };

    render() {

        return (
            <div className={preRegStyles.bodyContainer}>
                
                <Header />

                <div className={preRegStyles.bodyContent}>

                    <div className={preRegStyles.innerBodyContent}>
                        <div className={preRegStyles.leftSection}>
                            <div className={preRegStyles.leftContent}>
                                <div className={preRegStyles.fieldSet}>
                                    <div className={preRegStyles.actionBtn}>
                                        <div className={preRegStyles.signup}> Update Customer Details </div>
                                    </div>

                                    <div className={preRegStyles.preForm} >
                                        <form onSubmit={this.onSubmit}>
                                            <div><input type="text" placeholder="First Name " value={this.state.firstname} name="firstname" onChange={this.onchangeFirstname} className={preRegStyles.inputForm} /></div>
                                            <div><input type="text" placeholder="Last Name " value={this.state.lastname} name="lastname" onChange={this.onchangeLastname} className={preRegStyles.inputForm} /></div>
                                            <div><input type="email" placeholder="Email Address" value={this.state.email} name="email" onChange={this.onchangeEmail} className={preRegStyles.inputForm} /></div>
                                            <div><input type="number" placeholder="Phone Number" value={this.state.phonenumber} name="phoneNumber" onChange={this.onchangePhoneNumber} className={preRegStyles.inputForm} /></div>
                                            <div><input type="text" placeholder="Address" value={this.state.address} name="address" onChange={this.onchangeAddress} className={preRegStyles.inputForm} /></div>
                                            <div><input type="text" placeholder="City " value={this.state.city} name="city" onChange={this.onchangeCity} className={preRegStyles.inputForm} /></div>
                                            <div><input type="text" placeholder="State " value={this.state.state} name="state" onChange={this.onchangeState} className={preRegStyles.inputForm} /></div>
                                            <div><input type="text" placeholder="Country " value={this.state.country} name="country" onChange={this.onchangeCountry} className={preRegStyles.inputForm} /></div>
                                            <div><input type="number" placeholder="Postal Code " value={this.state.postalCode} name="postalCode" onChange={this.onchangePostalCode} className={preRegStyles.inputForm} /></div>
                                            <div><input type="text" placeholder="Sales Rep Employee Number " value={this.state.salesRepEmployeeNumber} name="salesRepEmployeeNumber" onChange={this.onchangeSalesRepEmployeeNumber} className={preRegStyles.inputForm} /></div>
                                        </form>
                                        <div className={preRegStyles.BookBtn} onClick={this.onSubmit}>
                                            <div>
                                                <span><MdStore /></span> Update Customer Details
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className={preRegStyles.rightSection}>

                            <div className={preRegStyles.rightBg}>
                                <img src={LeftImg} alt="leftImage" />
                            </div>



                        </div>
                    </div>


                </div>
            </div>
        )

    }

}

export default CustomerReg;
