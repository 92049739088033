import React, { Component } from 'react';
import preRegStyles from './preReg.module.css';
import StoreLogo from '../../assets/preReg/BAMZI.png';
import LeftImg from '../../assets/preReg/Home - 7-image.jpg';
import { MdStore } from "react-icons/md";
import axios from 'axios';



class PreReg extends Component {
    constructor() {
        super();
        this.onchangeFullname = this.onchangeFullname.bind(this);
        this.onchangeName = this.onchangeName.bind(this);
        this.onchangeEmail = this.onchangeEmail.bind(this);
        this.onchangeIndustry = this.onchangeIndustry.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = { fullname: "", name: "", email: "", industry: "" };
    }

    onchangeFullname(e) {
        this.setState({
            fullname: e.target.value
        });
    };
    onchangeName(e) {
        this.setState({
            name: e.target.value
        });
    };
    onchangeEmail(e) {
        this.setState({
            email: e.target.value
        });
    };
    onchangeIndustry(e) {
        this.setState({
            industry: e.target.value
        });
    };

    onSubmit(e) {
        e.preventDefault();
        const regdata = {
            fullname: this.state.fullname,
            name: this.state.name,
            email: this.state.email,
            industry: this.state.industry,
        }
        // axios.post('http://localhost/bamzi/api/storeowners', regdata).then(res => alert('Registered!!'))
         axios.post('https://bamziapi.ivyarc.com/api/storeowners', regdata).then(res => alert('Registered!!'))
    };

    render() {

        return (
            <div className={preRegStyles.bodyContainer}>
                <div className={preRegStyles.Header}>
                    <div className={preRegStyles.Title}>BAM<span className={preRegStyles.ZI}>ZI</span></div>
                </div>
                <div className={preRegStyles.bodyContent}>

                    <div className={preRegStyles.innerBodyContent}>
                        <div className={preRegStyles.leftSection}>
                            <div className={preRegStyles.leftContent}>
                                <div className={preRegStyles.fieldSet}>
                                    <div className={preRegStyles.actionBtn}>
                                        <div className={preRegStyles.signup}> Pre order your store </div>
                                    </div>

                                    <div className={preRegStyles.preForm} >
                                        <form onSubmit={this.onSubmit}>
                                            <div><input type="text" placeholder="Full Name " value={this.state.fullname} name="name" onChange={this.onchangeFullname} className={preRegStyles.inputForm} /></div>
                                            <div><input type="text" placeholder="Business Name " value={this.state.name} name="business" onChange={this.onchangeName} className={preRegStyles.inputForm} /></div>
                                            <div><input type="email" placeholder="Email Address" value={this.state.email} name="email" onChange={this.onchangeEmail} className={preRegStyles.inputForm} /></div>
                                            <div><input type="text" placeholder="Industry" value={this.state.industry} name="industry" onChange={this.onchangeIndustry} className={preRegStyles.inputForm} /></div>
                                        </form>
                                        <div className={preRegStyles.BookBtn} onClick={this.onSubmit}>
                                            <div>
                                                <span><MdStore /></span> Book your online store
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className={preRegStyles.rightSection}>

                            <div className={preRegStyles.rightBg}>
                                <img src={LeftImg} alt="leftImage" />
                            </div>



                        </div>
                    </div>


                </div>
            </div>
        )

    }

}

export default PreReg
/*
<div className="preForm">
                                <form onSubmit={this.onSubmit}>
                                <div><input type="text" placeholder="Full Name " value={this.state.fullname} name="name" onChange={this.onchangeFullname} className="inputForm" /></div>
                                <div><input type="text" placeholder="Business Name " value={this.state.name} name="business" onChange={this.onchangeName} className="inputForm" /></div>
                                <div><input type="text" placeholder="Email Address" value={this.state.email} name="email" onChange={this.onchangeEmail} className="inputForm" /></div>
                                <div><input type="text" placeholder="Industry" value={this.state.industry} name="industry" onChange={this.onchangeIndustry} className="inputForm" /></div>
                                <div className="BookBtn" style={{marginTop:"40px"}}>
                                    <button type="submit">
                                        <span><MdStore/></span><p>Book your online store</p>
                                    </button>
                                </div>
                                </form>

                            </div> */
