import React, { Component, setState } from 'react'
import ProdDet from './ProductDetails.module.css';
import Header from '../../components/majors/Headers';
import ProdReview from '../../components/majors/ProdReviews';
import ProdSpec from '../../components/majors/ProdSpec';
import CoverImage from '../../components/majors/CoverImage';
import Footer from '../../components/majors/Footer';
import Kemi from '../../assets/prodItems/Kemi.jpg';
import descCoverImage from '../../assets/productDetails/ElectronicsRUs.jpg';
import ApplePodsPic from '../../assets/prodItems/applePods.png';
import AppleWatchPic from '../../assets/prodItems/appleWatch.png';
import IphonePic from '../../assets/prodItems/iPhone.png';
import LaptopPic from '../../assets/prodItems/laptop.jpg';
import Saloon from '../../assets/prodItems/saloon.jpg';
import { FiSearch } from "react-icons/fi";
import { IoMdHeart, IoMdCart, IoMdHeartEmpty, IoMdMenu } from "react-icons/io";
import { BsCreditCard, BsEyeSlash } from "react-icons/bs";
import { FiGrid, FiTrash2 } from "react-icons/fi";
import { MdStore, MdStar } from "react-icons/md";
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';



var pageURL = decodeURIComponent(window.location.href);
var splitURL = pageURL.split('/');
var productName = splitURL[4]


class ProductDetails extends Component {

   state = {
      product: [],
      disabled : false,
      descRev: true
   };

   getProduct = async () => {
      const req = {
         productTitle: productName
      }
       /* const product = await axios.post("http://localhost/bamzi/api/products/details", req); */
      const product = await axios.post("https://bamziapi.ivyarc.com/api/products/details", req); 
      this.setState({ product: product.data });
      if(product.data[0].active == 0){
         this.setState({disabled: true})
      }
   };


   componentDidMount() {
      this.getProduct();
   }

   setDesc(){
      this.setState({descRev: true})
   }
   
   setRev(){
      this.setState({descRev: false})
   }

   onSubmit(cartItem) {
      // fetch("http://localhost/bamzi/public/api/productCart", {
          fetch("https://bamziapi.ivyarc.com/api/productCart", { 
         method: "POST",
         headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
         },
         body: JSON.stringify({
            id: cartItem.id,
            productName: cartItem.productName,
            productPrice: cartItem.productPrice,
            active: cartItem.active,
            productQuantity: cartItem.productQuantity,
            productSize: cartItem.productSize,
            productColor: cartItem.productColor,
            productOwner: cartItem.productOwner,
            userId: window.localStorage.getItem("userEmail"),
            productImage1: cartItem.productImage1,
            productImage2: cartItem.productImage2,
            productImage3: cartItem.productImage3
         })
      }).then((Response) => Response.json())
         .then((result) => {
            if (result.message == "success") {
               swal({
                  text: "Product Added to Cart...",
                  icon: 'success',
                  button: true
               });
               //this.props.history.push("/login");
            }
            else {
               swal({
                  title: "Error!",
                  text: "Please Fill All Fields...",
                  icon: 'error',
                  button: true
               });
            }
         });
   };

   

   render() {
      const { product } = this.state;

      return (
         <div className={ProdDet.ProductDetails}>
            <div className={ProdDet.Container}>

               {/* Navbar Section */}
               <Header
                  A1="Products" A1r="/products"
                  A2="Stores"
                  A3={<input type="text" name="searchBar" placeholder="Search anything" />}

                  B1={<span> Wishlist <IoMdHeartEmpty /> </span>}  B1r="/wishlist"
                  B2={<span> Cart <IoMdCart /></span>} B2r="/cart"
                  B3={<img src={Kemi} alt="A profile pic" />} B3r="/userDetails"
               /* B4= {loggedIn ? "Logout" : "Login"  */

                  style={{ backgroundColor: "#fefefe", borderBottom: "1px solid #ccc" }}

               />

               {/* Content */}

               <div className={ProdDet.Content} >

                  {product.map(item => {
                     const { id, productRating, productName, description, productPrice, active, productQuantity, productSize, productColor, productOwner, productImage1, productImage2, productImage3 } = item;
                     const cartItem = { id, productRating, productName, description, productPrice, active, productQuantity, productSize, productColor, productOwner, productImage1, productImage2, productImage3 }
                     let stockValue;
                     if (active == 1) {
                        stockValue = <p>In Stock</p>
                     } else {
                        stockValue = <p>Out Of Stock</p>;
                     }
                  
                     return (
                        <div className="to">
                           <div className={ProdDet.storeProd}>

                              <div className={ProdDet.storeProd1}>
                                 <div className={ProdDet.storeProdPicCont}>
                                    <img src={ApplePodsPic} alt="A profile pic" className={ProdDet.storeProdPicSmall} />
                                    <img src={ApplePodsPic} alt="A profile pic" className={ProdDet.storeProdPicSmall} />
                                    <img src={ApplePodsPic} alt="A profile pic" className={ProdDet.storeProdPicSmall} />

                                 </div>

                                 <div className={ProdDet.storeProdPicCont}>
                                    <img src={ApplePodsPic} alt="A profile pic" className={ProdDet.storeProdPic} />

                                 </div>
                              </div>

                              <div className={ProdDet.storeProd2}>
                                 <div className={ProdDet.storeProdDet}>
                                    <div className={ProdDet.storeTitStock}>
                                       <div className={ProdDet.storeTit}>
                                          <div><MdStore /> {productOwner} </div>
                                       </div>

                                       <div className={ProdDet.stock}>
                                          {stockValue}
                                       </div>
                                    </div>

                                    <div className={ProdDet.storeProdDetTit}>{productName}</div>
                                    <div className={ProdDet.storeProdDetPri}>US ${productPrice}</div>
                                    <div className={ProdDet.storeProdDetVar}>
                                       Color: {productColor}
                                       <div className={ProdDet.storeProdSmallCol} style={{ backgroundColor: productColor }}> </div>

                                    </div>
                                    <div className={ProdDet.storeProdDetVar}>

                                       Size
                                    <div className={ProdDet.storeProdSmallSize}> {productSize} </div>
                                    </div>




                                    <div className={ProdDet.storeProdAct}>


                                       <div className={ProdDet.storeProdDetQty} >
                                          <div className={ProdDet.storeProdSmallQty}>QTY </div>
                                          <div className={ProdDet.storeProdSmallDec}> - </div>
                                          <div className={ProdDet.storeProdSmallPri}> {productQuantity} </div>
                                          <div className={ProdDet.storeProdSmallInc}> + </div>

                                       </div>

                                       <div>
                                          <button disabled = {this.state.disabled} className={ProdDet.addCart} onClick={() => { this.onSubmit(cartItem) }}>
                                             <div >
                                                <IoMdCart /> Add to Cart
                                             </div>
                                          </button>

                                          <div className={ProdDet.addWish}>
                                             <IoMdHeartEmpty />
                                          </div>
                                       </div>
                                    </div>



                                 </div>

                              </div>


                           </div>



                           <div className={ProdDet.middle}>

                              <div className={ProdDet.middleTab}>
                                 <div onClick={this.setDesc}>Specifications</div>
                                 <div onClick={this.setRev}>Review</div>
                              </div>

                              <div className={ProdDet.middleCont}>
                                 
                                 { this.state.descRev ? <ProdSpec description={description} descCoverImage={descCoverImage} /> : <ProdReview /> }
                                 

                              </div>

                           </div>
                        </div>
                     )
                  })
                  }





                  <div className={ProdDet.relatedProducts}>

                     <div className={ProdDet.relProdTit}>
                        Related Products
                     </div>

                     <div className={ProdDet.relProdItems}>

                        <div className={ProdDet.prodCard}>
                           <div className={ProdDet.prodImage}>
                              <img src={ApplePodsPic} alt="A profile pic" className={ProdDet.prodPic} />
                           </div>

                           <div className={ProdDet.prodRatPrice}>
                              <div className={ProdDet.prodRat}> 3.4 <span><MdStar /></span></div>
                              <div> $399</div>
                           </div>

                           <div className={ProdDet.prodTitleDesc}>
                              <div>Apple Pods Series Redtooth 5</div>
                              <span>Redesigned from scratch and completely revised</span>
                           </div>

                           <div className={ProdDet.prodWishCart}>
                              <div className={ProdDet.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                              <div className={ProdDet.prodCart}> <IoMdCart /> ADD TO CART </div>
                           </div>

                        </div>


                        <div className={ProdDet.prodCard}>
                           <div className={ProdDet.prodImage}>
                              <img src={IphonePic} alt="A profile pic" className={ProdDet.prodPic} />
                           </div>

                           <div className={ProdDet.prodRatPrice}>
                              <div className={ProdDet.prodRat}> 3.4 <span><MdStar /></span></div>
                              <div> $399</div>
                           </div>

                           <div className={ProdDet.prodTitleDesc}>
                              <div>iPhone XIVV</div>
                              <span>Redesigned from scratch and completely revised</span>
                           </div>

                           <div className={ProdDet.prodWishCart}>
                              <div className={ProdDet.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                              <div className={ProdDet.prodCart}> <IoMdCart /> ADD TO CART </div>
                           </div>

                        </div>


                     </div>

                  </div>

               </div>


               {/* footer */}
               <Footer />

            </div>



         </div>



      )

   }

}

export default ProductDetails;
