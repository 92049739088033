import React, { Component, useState, useEffect } from 'react'
import SOEditProduct from './SOEditProduct.module.css'
import Footer from '../../components/majors/Footer'
import Filter from '../../components/majors/Filter'
import StoreProducts from '../../components/majors/StoreProducts'
import { FiBell, FiHelpCircle, FiGrid, FiCircle, FiHome, FiMail, FiBox } from "react-icons/fi";
import { BsCreditCard, BsEyeSlash } from "react-icons/bs";
import { VscAccount, VscBellDot } from "react-icons/vsc";
import { FaRegCalendarAlt, FaStore } from "react-icons/fa";
import { IoMdCart, IoMdHeart, IoMdPeople } from "react-icons/io";
import { AiFillBank } from "react-icons/ai";
import { MdAddShoppingCart } from "react-icons/md";
import Kemi from '../../assets/prodItems/Kemi.jpg'
import Trophy from '../../assets/sellerDashboard/trophy.png'
import { CgMenuBoxed } from "react-icons/cg";
import { Link } from 'react-router-dom'


const StoreOwnersEditProduct = props => {



   const [menuappear, setMenuappear] = useState(false);
   const [width, setWidth] = useState(window.innerWidth);

   const updateWidth = () => {
      setWidth(window.innerWidth);
   }

   useEffect(() => {
      window.addEventListener("resize", updateWidth);
      return () => window.removeEventListener("resize", updateWidth);
   })

   const menuShow = () => {
      setMenuappear(!menuappear)
   }






   let smallSize = <div> <CgMenuBoxed onClick={menuShow} className={SOEditProduct.menuButton} style={{ fontSize: "2.5em", color: "#000033" }} /> </div>

   let Menu = <div className={SOEditProduct.leftCent} onClick={menuShow}>
      <div className={SOEditProduct.Title}><Link to="/homepage" className={SOEditProduct.Link}> BAM<span className={SOEditProduct.ZI}>ZI</span> </Link></div>

      <div className={SOEditProduct.Section1}>
         <div> <Link to='/SODashboard' className={SOEditProduct.Link}> <span><FiHome /></span>  Dashboard </Link> </div>
         <div> <span><FiCircle /></span> Analytics </div>
         <div>  <Link to='/soMarketing' className={SOEditProduct.Link}> <span><FiCircle /></span> Marketing </Link> </div>
      </div>


      <div className={SOEditProduct.Section2}>
         <div className={SOEditProduct.SecTitle}  >PRODUCTS</div>

         <div>  <Link to='/storeOwnersStore' className={SOEditProduct.Link}> <span><FiBox /></span> All Products </Link> </div>
         <div> <Link to='/soAddProduct' className={SOEditProduct.Link}> <span><MdAddShoppingCart /></span> Add Products </Link> </div>
         <div>  <Link to='/soSales' className={SOEditProduct.Link}>  <span><IoMdCart /></span> Sales </Link></div>
         <div> <span><FiGrid /></span> Categories </div>
      </div>

      <div className={SOEditProduct.Section3}>
         <div className={SOEditProduct.SecTitle}  >Transactions </div>
         <div>  <Link to='/soHistory' className={SOEditProduct.Link}>  <span><FaRegCalendarAlt /></span> History </Link> </div>
         <div> <span><BsCreditCard /></span> Billings </div>
      </div>

      <div className={SOEditProduct.Section4}>
         <div className={SOEditProduct.SecTitle}  >PRODUCTS </div>
         <div> <span><VscAccount /></span> Account </div>
         <div> <Link to='/soCustomize' className={SOEditProduct.Link}> <span><FiBox /></span> Customise Shop </Link> </div>
         <div> <span><FiHelpCircle /></span> Help </div>
      </div>

   </div>

   if (menuappear) {
      smallSize = Menu
   }

   return (
      <div className={SOEditProduct.StoreOwnersStore}>
         <div className={SOEditProduct.Container}>

            <div className={SOEditProduct.left}>
               {width > 860 ? Menu : smallSize}
            </div>


            <div className={SOEditProduct.Right}>
               <div className={SOEditProduct.storeOwnersTopTab}>

                  <div className={SOEditProduct.NavList} >
                     <div>
                        <ul className={SOEditProduct.NavList1} >
                           <li><div> <AiFillBank /> Account: $12,990 </div></li>
                           <li><BsEyeSlash /></li>
                           <li><FiBell /></li>
                           <li><FiMail /></li>
                        </ul>
                     </div>

                     <div>
                        <ul className={SOEditProduct.NavList2} >
                           <li className={SOEditProduct.liFlex}> <FaStore /> </li>
                           <li className={SOEditProduct.liFlex}> Evans Bex Electronics Store </li>
                           <li> <img src={Kemi} alt="A profile pic" /> </li>
                        </ul>
                     </div>
                  </div>

               </div>





               <div className={SOEditProduct.EditProduct}>
                  <div className={SOEditProduct.EditProductCont}>




                     <div className={SOEditProduct.tit}>EDIT PRODUCT</div>

                     <form>
                        <label> Product Name:  <input type="text" placeholder="required" required name="productName" /> </label>

                        <label> Product Price:  <input type="number" placeholder="required" required name="productPrice" /> </label>

                        <label> Product Description:  <textarea placeholder="required" required rows={5} cols={50} name="productDesc" /> </label>

                        <div>
                           <label> Product Rating:  <input type="number" placeholder="required" required name="productRating" /> </label>

                           <label> Product Availability:
                                 <select value={"hint: this.state.selected"}>
                                 <option value="1">In Stock</option>
                                 <option value="0">Out of Stock</option>
                              </select>
                           </label>
                        </div>

                        <div>
                           <label> Product Image 1:  <input type="text" placeholder="required - enter url" required name="productImg1" /> </label>

                           <label> Product Image 2:  <input type="text" placeholder="optional" name="productImg2" /> </label>

                           <label> Product Image 3:  <input type="text" placeholder="optional" name="productImg3" /> </label>
                        </div>

                        <div>
                           <label> Product Category:  <input type="text" placeholder="required" required name="productCat" /> </label>

                           <label> Product Quantity:  <input type="number" placeholder="required" required name="productQty" /> </label>

                           <label> Product Size:  <input type="text" placeholder="required" required name="productSize" /> </label>
                        </div>

                        <div>
                           <label> Product Color: <input type="color" required name="productColor" /> </label>

                           <label> Product Owner:  <input type="text" placeholder="required" required name="productOwn" /> </label>
                        </div>

                        <button type="submit"> Update Product</button>
                     </form>






                  </div>
               </div>
            </div>

         </div >
      </div >


   )

}


export default StoreOwnersEditProduct;
