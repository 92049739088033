import React, { Component } from 'react'
import feature from './features.module.css'
import Mask from '../../assets/Mask.png'
import iPhone from '../../assets/homePage/iphone.png'
import { MdStar } from "react-icons/md";
import Car from '../../assets/homePage/car.jpg'
import Food from '../../assets/homePage/food.jpg'
import Phone from '../../assets/homePage/phones.jpg'
import CartBox from '../../assets/homePage/cartBox.png'
import agicFoods from '../../assets/homePage/agicFoods.png'
import stripImage from '../../assets/homePage/stripImage.png'
import Shoes from '../../assets/homePage/shoes.jpg'
import fashion from '../../assets/homePage/fashion.jpg'
import ApplePodsPic from '../../assets/prodItems/applePods.png';
import IphonePic from '../../assets/prodItems/iPhone.png';
import LaptopPic from '../../assets/prodItems/laptop.jpg';
import { FaLongArrowAltRight } from "react-icons/fa";
import { IoMdHeart, IoMdCart, IoMdHeartEmpty, IoMdMenu } from "react-icons/io";
import SidePic1 from '../../assets/features/Carousel2.png';
import SidePic2 from '../../assets/features/Carousel3.jpg';
import Top from './Home'
import Header from '../../components/majors/Headers'
import Footer from '../../components/majors/Footer'
import Carousel from '../../components/minors/Carousel'
import Kemi from '../../assets/prodItems/Kemi.jpg'



class Features extends Component {


   render() {

      return (
         <div className={feature.Home}>

            {/* Navbar Section */}
            <Header
               A1="Products" A1r="/products"
               A2="Stores" A2r="/stores"
               A3={<input type="text" name="searchBar" placeholder="Search anything" />}

               B1={<span> Wishlist <IoMdHeartEmpty /> </span>}  B1r="/wishlist"
               B2={<span> Cart <IoMdCart /></span>} B2r="/cart"
               B3={<img src={Kemi} alt="A profile pic" />} B3r="/userDetails"
               /* B4= {loggedIn ? "Logout" : "Login"  */

               style={{ backgroundColor: "#fff", borderBottom: "1px solid #ccc" }}

            />


            <Carousel />


            <div className={feature.bestSales}>

               <div className={feature.bestSalesTit}>Top Products</div>

               <div className={feature.bestSalesCont}>


                  <div className={feature.prodCard2}>
                     <div className={feature.prodImage}>
                        <img src={ApplePodsPic} alt="A profile pic" className={feature.prodPic} />
                     </div>

                     <div className={feature.prodRatPrice}>
                        <div className={feature.prodRat}> 3.4 <span><MdStar /></span></div>
                        <div> $399</div>
                     </div>

                     <div className={feature.prodTitleDesc}>
                        <div>Apple Pods Series Redtooth 5</div>
                        <span>Redesigned from scratch and completely revised</span>
                     </div>

                     <div className={feature.prodWishCart}>
                        <div className={feature.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                        <div className={feature.prodCart}> <IoMdCart /> ADD TO CART </div>
                     </div>


                  </div>

                  <div className={feature.prodCard2}>
                     <div className={feature.prodImage}>
                        <img src={LaptopPic} alt="A profile pic" className={feature.prodPic} />
                     </div>

                     <div className={feature.prodRatPrice}>
                        <div className={feature.prodRat}> 3.4 <span><MdStar /></span></div>
                        <div> $399</div>
                     </div>

                     <div className={feature.prodTitleDesc}>
                        <div>Xiaomi Laptop Kiddies Version</div>
                        <span>Redesigned from scratch and completely revised</span>
                     </div>

                     <div className={feature.prodWishCart}>
                        <div className={feature.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                        <div className={feature.prodCart}> <IoMdCart /> ADD TO CART </div>
                     </div>

                  </div>


                  <div className={feature.prodCard2}>
                     <div className={feature.prodImage}>
                        <img src={ApplePodsPic} alt="A profile pic" className={feature.prodPic} />
                     </div>

                     <div className={feature.prodRatPrice}>
                        <div className={feature.prodRat}> 3.4 <span><MdStar /></span></div>
                        <div> $399</div>
                     </div>

                     <div className={feature.prodTitleDesc}>
                        <div>Apple Pods Series Redtooth 5</div>
                        <span>Redesigned from scratch and completely revised</span>
                     </div>

                     <div className={feature.prodWishCart}>
                        <div className={feature.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                        <div className={feature.prodCart}> <IoMdCart /> ADD TO CART </div>
                     </div>

                  </div>

                  <div className={feature.prodCard2}>
                     <div className={feature.prodImage}>
                        <img src={LaptopPic} alt="A profile pic" className={feature.prodPic} />
                     </div>

                     <div className={feature.prodRatPrice}>
                        <div className={feature.prodRat}> 3.4 <span><MdStar /></span></div>
                        <div> $399</div>
                     </div>

                     <div className={feature.prodTitleDesc}>
                        <div>Xiaomi Laptop Kiddies Version</div>
                        <span>Redesigned from scratch and completely revised</span>
                     </div>

                     <div className={feature.prodWishCart}>
                        <div className={feature.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                        <div className={feature.prodCart}> <IoMdCart /> ADD TO CART </div>
                     </div>

                  </div>


                  <div></div>
               </div>
            </div>

            <div className={feature.topStores}>

               <div className={feature.topStoreTit}>
                  Top Stores on Bamzi
               </div>

               <div className={feature.topStoreCont}>

                  <div className={feature.prodCard11}>


                     <div className={feature.prodCardbase}>
                        <div>
                           <div className={feature.prodCardTitle}>
                              <div>Agic Foods</div>
                              <span>We are all about great meals to the fullest and all content dinning out or in. We give the best.</span>
                           </div>

                           <div className={feature.locShopNow}>
                              <div className={feature.loc}><span><MdStar /></span>  Hitech city</div>
                              <div className={feature.shopNow}> Shop now </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className={feature.prodCard12}>

                     <div className={feature.prodCardbase}>
                        <div>
                           <div className={feature.prodCardTitle}>
                              <div>Barmer's Fashion</div>
                              <span>Want the best of swag and Class? Upgrade your style to the next level</span>
                           </div>

                           <div className={feature.locShopNow}>
                              <div className={feature.loc}><span><MdStar /></span>  Milano </div>
                              <div className={feature.shopNow}> Shop now </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className={feature.prodCard13}>

                     <div className={feature.prodCardbase}>
                        <div>
                           <div className={feature.prodCardTitle}>
                              <div>Jack's Gadgets</div>
                              <span>Level up on your game. Use the Best devices to achieveyour Best.</span>
                           </div>

                           <div className={feature.locShopNow}>
                              <div className={feature.loc}><span><MdStar /></span>  Hitech city</div>
                              <div className={feature.shopNow}> Shop now </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className={feature.prodCard14}>

                     <div className={feature.see}>
                        <p>  <FaLongArrowAltRight />  </p>
                        <div> See more ...</div>
                     </div>
                  </div>

               </div>
            </div>

            <div className={feature.bestSales}>

               <div className={feature.bestSalesTit}>Best Sales This Month</div>

               <div className={feature.bestSalesCont}>


                  <div className={feature.prodCard2}>
                     <div className={feature.prodImage}>
                        <img src={ApplePodsPic} alt="A profile pic" className={feature.prodPic} />
                     </div>

                     <div className={feature.prodRatPrice}>
                        <div className={feature.prodRat}> 3.4 <span><MdStar /></span></div>
                        <div> $399</div>
                     </div>

                     <div className={feature.prodTitleDesc}>
                        <div>Apple Pods Series Redtooth 5</div>
                        <span>Redesigned from scratch and completely revised</span>
                     </div>

                     <div className={feature.prodWishCart}>
                        <div className={feature.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                        <div className={feature.prodCart}> <IoMdCart /> ADD TO CART </div>
                     </div>


                  </div>

                  <div className={feature.prodCard2}>
                     <div className={feature.prodImage}>
                        <img src={LaptopPic} alt="A profile pic" className={feature.prodPic} />
                     </div>

                     <div className={feature.prodRatPrice}>
                        <div className={feature.prodRat}> 3.4 <span><MdStar /></span></div>
                        <div> $399</div>
                     </div>

                     <div className={feature.prodTitleDesc}>
                        <div>Xiaomi Laptop Kiddies Version</div>
                        <span>Redesigned from scratch and completely revised</span>
                     </div>

                     <div className={feature.prodWishCart}>
                        <div className={feature.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                        <div className={feature.prodCart}> <IoMdCart /> ADD TO CART </div>
                     </div>

                  </div>


                  <div className={feature.prodCard2}>
                     <div className={feature.prodImage}>
                        <img src={ApplePodsPic} alt="A profile pic" className={feature.prodPic} />
                     </div>

                     <div className={feature.prodRatPrice}>
                        <div className={feature.prodRat}> 3.4 <span><MdStar /></span></div>
                        <div> $399</div>
                     </div>

                     <div className={feature.prodTitleDesc}>
                        <div>Apple Pods Series Redtooth 5</div>
                        <span>Redesigned from scratch and completely revised</span>
                     </div>

                     <div className={feature.prodWishCart}>
                        <div className={feature.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                        <div className={feature.prodCart}> <IoMdCart /> ADD TO CART </div>
                     </div>

                  </div>

                  <div className={feature.prodCard2}>
                     <div className={feature.prodImage}>
                        <img src={LaptopPic} alt="A profile pic" className={feature.prodPic} />
                     </div>

                     <div className={feature.prodRatPrice}>
                        <div className={feature.prodRat}> 3.4 <span><MdStar /></span></div>
                        <div> $399</div>
                     </div>

                     <div className={feature.prodTitleDesc}>
                        <div>Xiaomi Laptop Kiddies Version</div>
                        <span>Redesigned from scratch and completely revised</span>
                     </div>

                     <div className={feature.prodWishCart}>
                        <div className={feature.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                        <div className={feature.prodCart}> <IoMdCart /> ADD TO CART </div>
                     </div>

                  </div>


                  <div></div>
               </div>
            </div>


            <div className={feature.sideside}>
               <div className={feature.sideleft}>
                  <img src={SidePic1} alt="Sideside Pic" />
               </div>
               <div className={feature.sideright}>
                  <img src={SidePic2} alt="Sideside Pic" />  
               </div>
            </div>

            <div className={feature.startShopBestSales}>
               <div className={feature.startShopPad}>
                  <div className={feature.startShopCont}>
                     <div className={feature.startShopTitle}>
                        Launching Mobile App
                     </div>

                     <div className={feature.startShopSubcaption}>
                        <div className={feature.startShopText}>We aslo can wait for you to always have this great deal of awesomeness on your mobiles devices. </div>
                     </div>

                  </div>

                  <div className={feature.boxCart}>
                     <img src={iPhone} alt="Mobile app Launch" className={feature.boxCartImg} />
                  </div>
               </div>
            </div>



            <Footer style={{backgroundColor: "#fdd25a" }} />

         </div>
      )

   }

}

export default Features;
