import React, { Component, useState, useEffect } from "react";
import SOHistory from "./SOHistory.module.css";
import Footer from "../../components/majors/Footer";
import Filter from "../../components/majors/Filter";
import StoreProducts from "../../components/majors/StoreProducts";
import { FiBell, FiHelpCircle, FiGrid, FiCircle, FiHome, FiMail, FiBox } from "react-icons/fi";
import { BsCreditCard, BsEyeSlash } from "react-icons/bs";
import { VscAccount, VscBellDot, VscSettings } from "react-icons/vsc";
import { FaShippingFast, FaRegCalendarAlt, FaStore } from "react-icons/fa";
import { IoMdCart, IoMdHeart, IoMdPeople, IoMdMenu } from "react-icons/io";
import { AiFillBank } from "react-icons/ai";
import { MdAddShoppingCart, MdError } from "react-icons/md";
import Kemi from "../../assets/prodItems/Kemi.jpg";
import Trophy from "../../assets/sellerDashboard/trophy.png";
import { CgMenuBoxed } from "react-icons/cg";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

const StoreOwnersSalesHistory = (props) => {
   const [menuappear, setMenuappear] = useState(false);
   const [width, setWidth] = useState(window.innerWidth);

   const updateWidth = () => {
      setWidth(window.innerWidth);
   };

   useEffect(() => {
      window.addEventListener("resize", updateWidth);
      return () => window.removeEventListener("resize", updateWidth);
   });

   const menuShow = () => {
      setMenuappear(!menuappear);
   };

   let smallSize = (
      <div>

         <CgMenuBoxed
            onClick={menuShow}
            className={SOHistory.menuButton}
            style={{ fontSize: "2.5em", color: "#000033" }}
         />
      </div>
   );

   let Menu = (
      <div className={SOHistory.leftCent} onClick={menuShow}>
         <div className={SOHistory.Title}><Link to="/homepage" className={SOHistory.Link}> BAM<span className={SOHistory.ZI}>ZI</span> </Link></div>

         <div className={SOHistory.Section1}>
            <div> <Link to="/SODashboard" className={SOHistory.Link}> <span> <FiHome /> </span> Dashboard </Link></div>
            <div> <span> <FiCircle /></span>Analytics</div>
            <div> <Link to="/soMarketing" className={SOHistory.Link}> <span> <FiCircle /> </span>  Marketing </Link> </div>
         </div>

         <div className={SOHistory.Section2}>
            <div className={SOHistory.SecTitle}>PRODUCTS</div>

            <div><Link to="/storeOwnersStore" className={SOHistory.Link}> <span> <FiBox /> </span> All Products </Link></div>
            <div> <Link to="/soAddProduct" className={SOHistory.Link}> <span> <MdAddShoppingCart /> </span> Add Products </Link> </div>
            <div> <Link to="/soSales" className={SOHistory.Link}> <span> <IoMdCart /> </span> Sales </Link> </div>
            <div> <span>  <FiGrid /> </span> Categories </div>  
         </div>

         <div className={SOHistory.Section3}>
            <div className={SOHistory.SecTitle}>Transactions </div>
            <div> <Link to="/soHistory" className={SOHistory.Link}> <span> <FaRegCalendarAlt /> </span> History </Link> </div>
            <div>  <span> <BsCreditCard /> </span> Billings  </div>
         </div>

         <div className={SOHistory.Section4}>
            <div className={SOHistory.SecTitle}>SETTINGS </div>
            <div> <span> <VscAccount /> </span> Account </div>
            <div> <Link to="/soCustomize" className={SOHistory.Link}> <span> <FiBox /> </span> Customise Shop </Link> </div>
            <div> <span> <FiHelpCircle /> </span> Help </div> </div>
         </div>
   );

   if (menuappear) {
      smallSize = Menu;
   }

   return (
      <div className={SOHistory.StoreOwnersShop}>
         <div className={SOHistory.Container}>
            <div className={SOHistory.left}>{width > 860 ? Menu : smallSize}</div>

            <div className={SOHistory.Right}>
               <div className={SOHistory.storeOwnersTopTab}>
                  <div className={SOHistory.NavList}>
                     <div>
                        <ul className={SOHistory.NavList1}>
                           <li><div> <AiFillBank /> Account: $12,990 </div></li>
                           <li> <BsEyeSlash /> </li>
                           <li> <FiBell /> </li>
                           <li> <FiMail /> </li>
                        </ul>
                     </div>

                     <div>
                        <ul className={SOHistory.NavList2}>
                           <li className={SOHistory.liFlex}> <FaStore /> </li>
                           <li className={SOHistory.liFlex}>  Evans Bex Electronics Store </li>
                           <li> <img src={Kemi} alt="A profile pic" /> </li>
                        </ul>
                     </div>
                  </div>
               </div>

               <div className={SOHistory.customize}>
                  <div className={SOHistory.customizeCont}>
                     <div className={SOHistory.top}>  
                        <div className={SOHistory.top1}> 
                           <div className={SOHistory.tit}>
                              <span> <IoMdPeople /> </span> Sales Hsitory 
                           </div>
                        </div>

                        <div className={SOHistory.top2}>
                           <div className={SOHistory.top2left}>
                              <div> <input type="text" placeholder="Search Anything" /> </div>
                           </div>

                           <div className={SOHistory.top2right}>
                              <div> 
                                 <div className={SOHistory.prodTopIcons}>
                                    <div><FiGrid /> </div>
                                    <div> <IoMdMenu />  </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className={SOHistory.middle}>

                        {width > 1200 ?
                           <div className={SOHistory.tableTop1}>
                              <div></div>
                              <div>Product Name</div>
                              <div>Transaction ID</div>
                              <div>Price</div>
                              <div>Status</div>
                              <div>Buyer's Info</div>
                              <div>QTY</div>
                              <div>Date</div>
                              <div></div>
                           </div>
                           :
                           <div className={SOHistory.tableTop2}>
                              <div>Sales History</div>
                           </div>
                        }
                        <div className={SOHistory.tableRow}>
                           <div className={SOHistory.rowCheck}> <input type="checkbox" />  </div>
                           <div className={SOHistory.rowProduct}>
                              <div className={SOHistory.rowProductTit}>iPhone wrist band + free Pouch</div>
                           </div>
                           <div className={SOHistory.rowTransaction}>
                              S230Pn00956881
                           </div>
                           <div className={SOHistory.rowPrice}>$1500</div>
                           <div className={SOHistory.rowStatus}>Dellivered</div>
                           <div className={SOHistory.rowBuyer}>
                              <div>Omolola Daniel</div>
                           </div>
                           <div className={SOHistory.rowQTY}><span>4</span></div>
                           <div className={SOHistory.rowDate}>3/12/2021</div>
                           <div className={SOHistory.rowSettings}> <VscSettings /> </div>
                        </div>


                        <div className={SOHistory.tableRow}>
                           <div className={SOHistory.rowCheck}> <input type="checkbox" />  </div>
                           <div className={SOHistory.rowProduct}>
                              <div className={SOHistory.rowProductTit}>iPhone X 6GB Ram</div>
                           </div>
                           <div className={SOHistory.rowTransaction}>
                              S230Pn00956881
                           </div>
                           <div className={SOHistory.rowPrice}>-$1500</div>
                           <div className={SOHistory.rowStatus}>Dellivered</div>
                           <div className={SOHistory.rowBuyer}>
                              <div>Agnes Angela</div>
                           </div>
                           <div className={SOHistory.rowQTY}><span>1</span></div>
                           <div className={SOHistory.rowDate}>3/12/2021</div>
                           <div className={SOHistory.rowSettings}> <VscSettings /> </div>
                        </div>


                        <div className={SOHistory.tableRow}>
                           <div className={SOHistory.rowCheck}> <input type="checkbox" />  </div>
                           <div className={SOHistory.rowProduct}>
                              <div className={SOHistory.rowProductTit}>iPhone wrist band + free Pouch</div>
                           </div>
                           <div className={SOHistory.rowTransaction}>
                              S230Pn00956881
                           </div>
                           <div className={SOHistory.rowPrice}>$1500</div>
                           <div className={SOHistory.rowStatus}>Refunded</div>
                           <div className={SOHistory.rowBuyer}>
                              <div>Omolola Daniel</div>
                           </div>
                           <div className={SOHistory.rowQTY}><span>4</span></div>
                           <div className={SOHistory.rowDate}>3/12/2021</div>
                           <div className={SOHistory.rowSettings}> <VscSettings /> </div>
                        </div>

                        <div className={SOHistory.tableRow}>
                           <div className={SOHistory.rowCheck}> <input type="checkbox" />  </div>
                           <div className={SOHistory.rowProduct}>
                              <div className={SOHistory.rowProductTit}>iPhone wrist band + free Pouch</div>
                           </div>
                           <div className={SOHistory.rowTransaction}>
                              S230Pn00956881
                           </div>
                           <div className={SOHistory.rowPrice}>$1500</div>
                           <div className={SOHistory.rowStatus}>Dellivered</div>
                           <div className={SOHistory.rowBuyer}>
                              <div>Omolola Daniel</div>
                           </div>
                           <div className={SOHistory.rowQTY}><span>2</span></div>

                           <div className={SOHistory.rowDate}>3/12/2021</div>
                           <div className={SOHistory.rowSettings}> <VscSettings /> </div>
                        </div>

                     </div>

                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default StoreOwnersSalesHistory;
