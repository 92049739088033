import React, { Component } from 'react'
import { useHistory, Link } from "react-router-dom"
import homePage from './HomePage.module.css'
/* import Mask from '../../assets/Mask.png' */
import iPhone from '../../assets/homePage/iphone.png'
import { MdStar } from "react-icons/md";
/* import Car from '../../assets/homePage/car.jpg'/
import Food from '../../assets/homePage/food.jpg'
import Phone from '../../assets/homePage/phones.jpg' */
import CartBox from '../../assets/homePage/cartBox.png'
/* import agicFoods from '../../assets/homePage/agicFoods.png' */
import stripImage from '../../assets/homePage/stripImage.png'
/* import Shoes from '../../assets/homePage/shoes.jpg'
import fashion from '../../assets/homePage/fashion.jpg' */
import ApplePodsPic from '../../assets/prodItems/applePods.png';
/* import IphonePic from '../../assets/prodItems/iPhone.png'; */
import LaptopPic from '../../assets/prodItems/laptop.jpg';
import { FaLongArrowAltRight } from "react-icons/fa";
import { IoMdHeart, IoMdCart, IoMdHeartEmpty, IoMdMenu } from "react-icons/io";
import axios from 'axios';
import Top from './Home'
import Footer from '../../components/majors/Footer'


class Homepage extends Component {
   constructor() {
      super();
      this.shop = this.shop.bind(this);
   }

   state = {
      shop: [],
      disabled: false
   };

   getShop = async () => {
      /* const shop = await axios.get("http://localhost/bamzi/api/shops"); */
      const shop = await axios.get("https://bamziapi.ivyarc.com/api/shops");
      this.setState({ shop: shop.data });
   };

   shop(shopInfo) {
      let specificShop = shopInfo.shopName;
      this.props.history.push("/specificstore/" + specificShop);
   }

   componentDidMount() {
      this.getShop();
   }


   render() {
      const { shop } = this.state;

      return (
         <div className={homePage.Home}>

            <Top />

            <div className={homePage.topStores}>

               <div className={homePage.topStoreTit}>
                  Top Stores on Bamzi
               </div>

               <div className={homePage.topStoreCont}>
                  {shop.map(item => {
                     const { id, shopName, description, city } = item;
                     const shopInfo = { shopName };
                     return (
                        <div className={homePage.prodCard11} onClick={() => { this.shop(shopInfo) }}>
                           <div className={homePage.prodCardbase}>
                              <div>
                                 <div className={homePage.prodCardTitle}>
                                    <div>{shopName}</div>
                                    <span>{description}.</span>
                                 </div>

                                 <div className={homePage.locShopNow}>
                                    <div className={homePage.loc}><span><MdStar /></span> {city}</div>
                                    <div className={homePage.shopNow} onClick={() => { this.shop(shopInfo) }}>
                                       <Link className={homePage.Link} to={`/specificstore/:${shopName}`}>
                                          Shop now
                                       </Link>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     )
                  })}


                  <div className={homePage.prodCard14}>

                     <div className={homePage.see}>
                        <Link to="/stores" className={homePage.Link}>
                           <p>  <FaLongArrowAltRight />  </p>
                           <div> See more ...</div>
                        </Link>
                     </div>
                  </div>

               </div>
            </div>

            <div className={homePage.bestSales}>

               <div className={homePage.bestSalesTit}>Best Sales This Month</div>

               <div className={homePage.bestSalesCont}>


                  <div className={homePage.prodCard2}>
                     <div className={homePage.prodImage}>
                        <img src={ApplePodsPic} alt="A profile pic" className={homePage.prodPic} />
                     </div>

                     <div className={homePage.prodRatPrice}>
                        <div className={homePage.prodRat}> 3.4 <span><MdStar /></span></div>
                        <div> $399</div>
                     </div>

                     <div className={homePage.prodTitleDesc}>
                        <div>Apple Pods Series Redtooth 5</div>
                        <span>Redesigned from scratch and completely revised</span>
                     </div>

                     <div className={homePage.prodWishCart}>
                        <div className={homePage.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                        <div className={homePage.prodCart}> <IoMdCart /> ADD TO CART </div>
                     </div>


                  </div>

                  <div className={homePage.prodCard2}>
                     <div className={homePage.prodImage}>
                        <img src={LaptopPic} alt="A profile pic" className={homePage.prodPic} />
                     </div>

                     <div className={homePage.prodRatPrice}>
                        <div className={homePage.prodRat}> 3.4 <span><MdStar /></span></div>
                        <div> $399</div>
                     </div>

                     <div className={homePage.prodTitleDesc}>
                        <div>Xiaomi Laptop Kiddies Version</div>
                        <span>Redesigned from scratch and completely revised</span>
                     </div>

                     <div className={homePage.prodWishCart}>
                        <div className={homePage.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                        <div className={homePage.prodCart}> <IoMdCart /> ADD TO CART </div>
                     </div>

                  </div>


                  <div className={homePage.prodCard2}>
                     <div className={homePage.prodImage}>
                        <img src={ApplePodsPic} alt="A profile pic" className={homePage.prodPic} />
                     </div>

                     <div className={homePage.prodRatPrice}>
                        <div className={homePage.prodRat}> 3.4 <span><MdStar /></span></div>
                        <div> $399</div>
                     </div>

                     <div className={homePage.prodTitleDesc}>
                        <div>Apple Pods Series Redtooth 5</div>
                        <span>Redesigned from scratch and completely revised</span>
                     </div>

                     <div className={homePage.prodWishCart}>
                        <div className={homePage.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                        <div className={homePage.prodCart}> <IoMdCart /> ADD TO CART </div>
                     </div>

                  </div>

                  <div className={homePage.prodCard2}>
                     <div className={homePage.prodImage}>
                        <img src={LaptopPic} alt="A profile pic" className={homePage.prodPic} />
                     </div>

                     <div className={homePage.prodRatPrice}>
                        <div className={homePage.prodRat}> 3.4 <span><MdStar /></span></div>
                        <div> $399</div>
                     </div>

                     <div className={homePage.prodTitleDesc}>
                        <div>Xiaomi Laptop Kiddies Version</div>
                        <span>Redesigned from scratch and completely revised</span>
                     </div>

                     <div className={homePage.prodWishCart}>
                        <div className={homePage.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                        <div className={homePage.prodCart}> <IoMdCart /> ADD TO CART </div>
                     </div>

                  </div>


                  <div></div>
               </div>
            </div>


            <div className={homePage.startShopBestSales}>
               <div className={homePage.startShopPad}>
                  <div className={homePage.startShopCont}>
                     <div className={homePage.startShopTitle}>
                        Start Shopping Best Sales
                     </div>

                     <div className={homePage.startShopSubcaption}>
                        <div className={homePage.startShopText}>Every category containing the best items at the best prices selected just for you</div>
                        <div className={homePage.startShopButton}>
                           <div> <Link className={homePage.Link} to={"/products"}> Go Shopping </Link></div>
                        </div>
                     </div>

                  </div>

                  <div className={homePage.boxCart}>
                     <img src={CartBox} alt="Assets" className={homePage.boxCartImg} />
                  </div>
               </div>
            </div>



            <div className={homePage.stripImg}>
               <img src={stripImage} alt="A profile pic" className={homePage.stripPic} />
            </div>


            <div className={homePage.iphoneGetSt}>

               <div className={homePage.iphoneCont}>
                  <img src={iPhone} alt="A profile pic" className={homePage.iphonePic} />
               </div>

               <div className={homePage.getStore}>
                  <div className={homePage.getStoreBg}>
                     <div className={homePage.getStoreCont}>
                        <div className={homePage.getStoreTit}>Get your Store online</div>

                        <div className={homePage.getStoreText1}>Every product deserves a digital presence. Create your store with Bamzi and have a wider reach of market audience. Its simple and flexible to use.</div>

                        <div className={homePage.getStoreText1}>Every product deserves a digital presence. Create your store with Bamzi and have a wider reach of market audience. Its simple and flexible to use.</div>

                        <div className={homePage.getStoreButton}>
                           <Link className={homePage.Link} to={"/signup"}>
                              Get Started <span> <FaLongArrowAltRight /> </span>
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>

            </div>

            <Footer />

         </div>
      )

   }

}

export default Homepage
