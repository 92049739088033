import React, { Component, useState } from 'react'
import classes from './UserProducts.module.css'
import { IoMdHeart, IoMdCart, IoMdHeartEmpty, IoMdMenu } from "react-icons/io";
import { FiGrid } from "react-icons/fi";
import ApplePodsPic from '../../assets/prodItems/applePods.png';
import AppleWatchPic from '../../assets/prodItems/appleWatch.png';
import IphonePic from '../../assets/prodItems/iPhone.png';
import LaptopPic from '../../assets/prodItems/laptop.jpg';
import { MdStar, MdStarBorder } from "react-icons/md";
import axios from 'axios';
import swal from 'sweetalert';
import {Link} from 'react-router-dom'

var pageURL = decodeURIComponent(window.location.href);
var splitURL = pageURL.split('/');
var storeName = splitURL[4]

class UserProducts extends Component {
   constructor() {
      super();
      this.onSubmit = this.onSubmit.bind(this);

      this.state = {
         storeProductItems: [],
         errors: null,
      }
   };

   getProductItems = async () => {
      const req = {
         storeName: storeName
      }
      /* const storeProductItems = await axios.post("http://localhost/bamzi/api/shops/products", req); */
      const storeProductItems = await axios.post("https://bamziapi.ivyarc.com/api/shops/products", req);
      this.setState({ storeProductItems: storeProductItems.data });
      console.log(storeProductItems);
   };


   componentDidMount() {
      this.getProductItems();
      /*  setTimeout(() => {
          this.getProductItems();
       }, 50); */
   };


   onSubmit(cartItem) {
      fetch("https://bamziapi.ivyarc.com/api/productCart", {
         /* fetch("http://localhost/bamzi/api/productCart", { */
         method: "POST",
         headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
         },
         body: JSON.stringify({
            id: cartItem.id,
            productName: cartItem.productName,
            productPrice: cartItem.productPrice,
            active: cartItem.active,
            productQuantity: cartItem.productQuantity,
            productSize: cartItem.productSize,
            productColor: cartItem.productColor,
            productOwner: cartItem.productOwner,
            userId: window.localStorage.getItem("userEmail"),
            productImage1: cartItem.productImage1,
            productImage2: cartItem.productImage2,
            productImage3: cartItem.productImage3
         })
      }).then((Response) => Response.json())
         .then((result) => {
            if (result.message == "success") {
               swal({
                  text: "Product Added to Cart...",
                  icon: 'success',
                  button: true
               });
               //this.props.history.push("/login");
            }
            else {
               swal({
                  title: "Error!",
                  text: "Please Fill All Fields...",
                  icon: 'error',
                  button: true
               });
            }
         });
   };

   onWishlist(cartItem) {
      fetch("https://bamziapi.ivyarc.com/api/wishlist", {
         /* fetch("http://localhost/bamzi/api/wishlist", { */
         method: "POST",
         headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
         },
         body: JSON.stringify({
            id: cartItem.id,
            productName: cartItem.productName,
            productPrice: cartItem.productPrice,
            active: cartItem.active,
            productQuantity: cartItem.productQuantity,
            productSize: cartItem.productSize,
            productColor: cartItem.productColor,
            productOwner: cartItem.productOwner,
            userId: window.localStorage.getItem("userEmail"),
            productImage1: cartItem.productImage1,
            productImage2: cartItem.productImage2,
            productImage3: cartItem.productImage3
         })
      }).then((Response) => Response.json())
         .then((result) => {
            if (result.message == "success") {
               swal({
                  text: "Product Added to wishlist...",
                  icon: 'success',
                  button: true
               });
               //this.props.history.push("/login");
            }
            else {
               swal({
                  title: "Error!",
                  text: "Please Fill All Fields...",
                  icon: 'error',
                  button: true
               });
            }
         });
   };



   render() {

      const { isLoading, storeProductItems } = this.state;
      console.log(storeProductItems)
      return (

         <div className={classes.productView}>

            <div className={classes.prodTop}>
               <div>7,618 results found in 5ms</div>
               <div className={classes.prodTopIcons}>
                  <div><FiGrid /></div>
                  <div> <IoMdMenu /></div>
               </div>

            </div>

            <div></div>
            <div className={classes.prodItems}>
               {storeProductItems.length > 0 ? storeProductItems.map(product => {
                  const {
                     id, productRating, productName, description, productPrice, active, productQuantity, productSize, productColor, productOwner, productImage1, productImage2, productImage3
                  } = product;
                  const cartItem = { id, productRating, productName, description, productPrice, active, productQuantity, productSize, productColor, productOwner, productImage1, productImage2, productImage3 }
                  return (
                     <div className={classes.prodCard}>
                        <div className={classes.prodImage}>
                           <img src={AppleWatchPic} alt="A profile pic" className={classes.prodPic} />
                        </div>

                        <div className={classes.prodRatPrice}>
                           <div className={classes.prodRat}> {productRating}<span><MdStar /></span></div>
                           <div> ${productPrice}</div>
                        </div>

                        <div className={classes.prodTitleDesc}>
                           <div>
                              <Link to={`/productDetails/${productName}`}>
                                 {productName}
                              </Link>
                           </div>
                           <span>{description}</span>
                        </div>

                        <div className={classes.prodWishCart}>
                           <div onClick={() => { this.onWishlist(cartItem) }} className={classes.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                           <div onClick={() => { this.onSubmit(cartItem) }} className={classes.prodCart}> <IoMdCart /> ADD TO CART </div>
                        </div>

                     </div>
                  )
               }) :
                  <div>
                     No Item(s) In Store
                  </div>
               }
            </div>

         </div>

      )

   }

}

export default UserProducts
