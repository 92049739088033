import React, { Component } from 'react'
import Wish from './Wishlist.module.css';
import Header from '../../components/majors/Headers'
import CoverImage from '../../components/majors/CoverImage'
import Footer from '../../components/majors/Footer'
import CategoryBar from '../../components/majors/CategoryBar'
import Filter from '../../components/majors/Filter'
import UserProducts from '../../components/majors/UserProducts'
import Kemi from '../../assets/prodItems/Kemi.jpg'
import ApplePodsPic from '../../assets/prodItems/applePods.png';
import AppleWatchPic from '../../assets/prodItems/appleWatch.png';
import IphonePic from '../../assets/prodItems/iPhone.png';
import LaptopPic from '../../assets/prodItems/laptop.jpg';
import Saloon from '../../assets/prodItems/saloon.jpg';
import { FiSearch } from "react-icons/fi";
import { IoMdHeart, IoMdCart, IoMdHeartEmpty, IoMdMenu } from "react-icons/io";
import { BsCreditCard, BsEyeSlash } from "react-icons/bs";
import { FiGrid, FiTrash2 } from "react-icons/fi";
import { MdStore, MdStar } from "react-icons/md";
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';


class Wishlist extends Component {

   state = {
      wishItems: [],
      prices: 0,
   };

   getProduct = async () => {
      const req = {
         userEmail: localStorage.getItem('userEmail')
      }
      /* const wishItems = await axios.post("http://localhost/bamzi/api/wishlist/details", req); */
      const wishItems = await axios.post("https://bamziapi.ivyarc.com/api/wishlist/details", req);
      this.setState({ wishItems: wishItems.data }, console.log(wishItems.data));
   };


   getPrice = async () => {
      const itemsList = this.state.wishItems.length;
      let totalPrice = 0;
      console.log(itemsList)
      if (itemsList == undefined) {
         this.setState({ prices: totalPrice });
      } else {
         this.state.wishItems.forEach(product => {
            totalPrice += product.productPrice;
            console.log(totalPrice)
         });
         console.log(totalPrice);
         this.setState({ prices: totalPrice });
      }
   }

   componentDidMount() {
      this.getProduct();
   }

   onSubmit(wishItems) {
      /* fetch("http://localhost/bamzi/api/wishlist/delete", { */
      fetch("https://bamziapi.ivyarc.com/api/wishlist/delete", {
         method: "DELETE",
         headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
         },
         body: JSON.stringify({
            productName: wishItems.productName,
            userEmail: window.localStorage.getItem("userEmail"),
         })
      }).then((Response) => Response.json())
         .then((result) => {
            if (result.message == "success") {
               swal({
                  title: "Error!",
                  text: "Product Not In WishList.",
                  icon: 'error',
                  button: true
               });
               //this.props.history.push("/login");
            }
            else {
               swal({
                  text: "Product Removed From Wishlist...",
                  icon: 'success',
                  button: true
               });
               window.location.reload(true);
            }
         });
   };

   inCart(cartItem) {
      fetch("https://bamziapi.ivyarc.com/api/productCart", {
      /* fetch("http://localhost/bamzi/api/productCart", { */
         method: "POST",
         headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
         },
         body: JSON.stringify({
            id: cartItem.id,
            productName: cartItem.productName,
            productPrice: cartItem.productPrice,
            active: cartItem.active,
            productQuantity: cartItem.productQuantity,
            productSize: cartItem.productSize,
            productColor: cartItem.productColor,
            productOwner: cartItem.productOwner,
            userId: window.localStorage.getItem("userEmail"),
            productImage1: cartItem.productImage1,
            productImage2: cartItem.productImage2,
            productImage3: cartItem.productImage3
         })
      }).then((Response) => Response.json())
         .then((result) => {
            if (result.message == "success") {
               swal({
                  text: "Product Added to Cart...",
                  icon: 'success',
                  button: true
               });
               //this.props.history.push("/login");
            }
            else {
               swal({
                  title: "Error!",
                  text: "Please Fill All Fields...",
                  icon: 'error',
                  button: true
               });
            }
         });
   };


   render() {
      const { wishItems } = this.state;
      const itemsList = wishItems.length;


      return (
         <div className={Wish.Cart}>
            <div className={Wish.Container}>

               {/* Navbar Section */}
               <Header
                  A1="Products" A1r="/products"
                  A2="Stores" A2r="/stores"
                  A3={<input type="text" name="searchBar" placeholder="Search anything" />}

                  B1={<span> Wishlist <IoMdHeartEmpty /> </span>}  B1r="/wishlist"
                  B2={<span> Cart <IoMdCart /></span>}  B2r="/cart"
                  B3={<img src={Kemi} alt="A profile pic" />} B3r="/userDetails"
               /* B4= {loggedIn ? "Logout" : "Login"  */

                  style={{ backgroundColor: "#fff", borderBottom: "1px solid #ccc" }}

               />

               {/* Content */}

               <div className={Wish.Content} >
                  <div className={Wish.left}>
                     <div className={Wish.wishHead}>

                        <div className={Wish.wishSubTit}>
                           <div className={Wish.wishTit}>
                              <div>Wishlist <span>{wishItems.length}</span> </div>
                           </div>
                           <div>
                              <span> <FiTrash2 /></span>Delete All
                           </div>
                        </div>
                     </div>

                     <div className={Wish.store}>
                        {wishItems.length > 0 ? wishItems.map(wish => {
                           const { id, productRating, productName, description, productPrice, active, productQuantity, productSize, productColor, productOwner, productImage1, productImage2, productImage3 } = wish;
                           const cartItem = { id, productRating, productName, description, productPrice, active, productQuantity, productSize, productColor, productOwner, productImage1, productImage2, productImage3 }
                           return (
                              <div className={Wish.storeProd}>

                                 <div className={Wish.storeProdPicCont}>
                                    <img src={ApplePodsPic} alt="A profile pic" className={Wish.storeProdPic} />

                                 </div>

                                 <div className={Wish.storeProdDetCont}>
                                    <div className={Wish.storeProdDet}>
                                       <div className={Wish.storeProdDetTit}>{productName}</div>
                                       <div className={Wish.storeProdDetPri}>US ${productPrice}</div>
                                    </div>

                                    <div className={Wish.storeProdAct}>
                                       <div>
                                          <div onClick={() => { this.onSubmit(cartItem) }}> <FiTrash2 />  </div>
                                       </div>

                                       <div onClick={() => { this.inCart(cartItem) }} className={Wish.storeProdDetQty} >
                                          Add to Cart
                                                 </div>
                                    </div>
                                 </div>

                              </div>

                           )
                        }) :
                           <div>
                              No Item(s) in wishlist
                                    </div>
                        }


                     </div>


                  </div>



                  <div className={Wish.right}>

                     <div className={Wish.relProdTit}>
                        Related Products
                     </div>

                     <div className={Wish.relProdItems}>

                        <div className={Wish.prodCard}>
                           <div className={Wish.prodImage}>
                              <img src={ApplePodsPic} alt="A profile pic" className={Wish.prodPic} />
                           </div>

                           <div className={Wish.prodRatPrice}>
                              <div className={Wish.prodRat}> 3.4 <span><MdStar /></span></div>
                              <div> $399</div>
                           </div>

                           <div className={Wish.prodTitleDesc}>
                              <div>Apple Pods Series Redtooth 5</div>
                              <span>Redesigned from scratch and completely revised</span>
                           </div>

                           <div className={Wish.prodWishCart}>
                              <div className={Wish.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                              <div className={Wish.prodCart}> <IoMdCart /> ADD TO CART </div>
                           </div>

                        </div>


                        <div className={Wish.prodCard}>
                           <div className={Wish.prodImage}>
                              <img src={IphonePic} alt="A profile pic" className={Wish.prodPic} />
                           </div>

                           <div className={Wish.prodRatPrice}>
                              <div className={Wish.prodRat}> 3.4 <span><MdStar /></span></div>
                              <div> $399</div>
                           </div>

                           <div className={Wish.prodTitleDesc}>
                              <div>iPhone XIVV</div>
                              <span>Redesigned from scratch and completely revised and completely revised</span>
                           </div>

                           <div className={Wish.prodWishCart}>
                              <div className={Wish.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                              <div className={Wish.prodCart}> <IoMdCart /> ADD TO CART </div>
                           </div>

                        </div>


                     </div>

                  </div>

               </div>


            </div>



         </div>



      )

   }

}

export default Wishlist;
