import React, { Component } from 'react'
import address from './UserAddress.module.css';
import Header from '../../../components/majors/Headers'
import Kemi from '../../../assets/prodItems/Kemi.jpg'
import { IoMdHeart, IoMdCart, IoMdHeartEmpty, IoMdMenu } from "react-icons/io";
import { VscAccount } from "react-icons/vsc";
import { RiShoppingBag3Fill } from "react-icons/ri";
import { FaShippingFast } from "react-icons/fa"
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';


class UserAddress extends Component {
   constructor() {
      super();
      this.onchangeHomeAddress = this.onchangeHomeAddress.bind(this);
      this.onchangeLandmark = this.onchangeLandmark.bind(this);
      this.onchangeCity = this.onchangeCity.bind(this);
      this.onchangeState= this.onchangeState.bind(this);
      this.onchangeZip= this.onchangeZip.bind(this);
      this.onSubmit = this.onSubmit.bind(this);
      
      this.state = { 
          HomeAddress: "",
          Landmark: "",
          City: "",
          State:"",
          Zip: "",
          addressList: []
      };
  }

  onchangeHomeAddress(e) {
      this.setState({
          HomeAddress: e.target.value
      });
  };
  onchangeLandmark(e) {
      this.setState({
          Landmark: e.target.value
      });
  };
  onchangeCity(e) {
      this.setState({
          City: e.target.value
      });
  };

  onchangeState(e) {
      this.setState({
          State: e.target.value
      });
  };
  onchangeZip(e) {
      this.setState({
          Zip: e.target.value
      });
  };

  onSubmit(event){
      fetch("https://bamziapi.ivyarc.com/api/userShipping", { 
      /* fetch("http://localhost/bamzi/api/userShipping", { */
          method: "POST",
          headers: {
              "Accept":"application/json",
              "Content-Type": "application/json"
          },
          body: JSON.stringify({
              email: localStorage.getItem('userEmail'),
              HomeAddress: this.state.HomeAddress,
              LandMark: this.state.Landmark,
              City: this.state.City,
              State: this.state.State,
              Zip: this.state.Zip,
          })
      }).then((Response) => Response.json())
          .then((result) => {
              if(result.message == "success"){
                  swal({
                      title: "Updated!",
                      text: "Customer Details Updated Successfully...",
                      icon: 'success',
                      button: true
                   });
              }
              else{
                  swal({
                      title: "Error!",
                      text: "Please Fill All Fields...",
                      icon: 'error',
                      button: true
                   });
              }
          });
  };

  getAddress = async () => {
   const req = {
      userEmail: localStorage.getItem('userEmail')
   };
   /* const addressList = await axios.get("http://localhost/bamzi/api/userShipping/" + req.userEmail ); */
   const addressList = await axios.get("https://bamziapi.ivyarc.com/api/userShipping", req.userEmail); 
   this.setState({ addressList: addressList.data }, console.log(addressList.data));
};

componentDidMount(){
   this.getAddress();
}

   render() {
      const {addressList} = this.state;

      return (
         <div className={address.Details}>
            <div className={address.Container}>

               {/* Navbar Section */}
               <Header
                  A1="Products" A1r="/products"
                  A2="Stores" A2r="/stores"
                  A3={<input type="text" name="searchBar" placeholder="Search anything" />}

                  B1={<span> Wishlist <IoMdHeartEmpty /> </span>}  B1r="/wishlist"
                  B2={<span> Cart <IoMdCart /></span>}  B2r="/cart"
                  B3={<img src={Kemi} alt="A profile pic" />} B3r="/userDetails"
               /* B4= {loggedIn ? "Logout" : "Login"  */

                  style={{ backgroundColor: "#fff", borderBottom: "1px solid #ccc" }}

               />
               

               {/* Content */}

               <div className={address.Content} >

                  <div className={address.left}>

                     <div className={address.detailsInfo}> <Link to='/userDetails' className={address.Link}> <span><VscAccount /></span> Personal Details </Link> </div>
                     <div className={address.orderInfo}> <Link to="/userOrders" className={address.Link}> <span><RiShoppingBag3Fill /></span> Orders </Link></div>
                     <div className={address.addressInfo}> <span><FaShippingFast /></span> Shipping Address </div>

                  </div>

                  <div className={address.right}>

                    { addressList.length > 0 ? addressList.map(info => {
                        const {id, HomeAddress, LandMark, City, State, Zip} = info;
                        console.log("info")
                        return(
                            <div className={address.accountInfo}>

                            Address
    
    
                            <input type="text" placeholder={HomeAddress} name="HomeAddress" onChange={this.onchangeHomeAddress} value={this.state.HomeAddress} />
                            <input type="text" placeholder="Landmark" name="Landmark" onChange={this.onchangeLandmark} value={this.state.Landmark}  />
                            <input type="text" placeholder="City" name="City" onChange={this.onchangeCity} value={this.state.City} />
                            <input type="text" placeholder="State/Region" name="State" onChange={this.onchangeState} value={this.state.State} />
                            <input type="number" placeholder="Zip Code" name="Zip" onChange={this.onchangeZip} value={this.state.Zip} />
    
                            <button type="submit" onClick={this.onSubmit}> Save Info</button>
    
    
                         </div>
                        )
                    }):
                        <div className={address.accountInfo}>

                        Address


                        <input type="text" placeholder="Home Address" name="HomeAddress" onChange={this.onchangeHomeAddress} value={addressList.HomeAddress} />
                        <input type="text" placeholder="Landmark" name="Landmark" onChange={this.onchangeLandmark} value={addressList.Landmark}  />
                        <input type="text" placeholder="City" name="City" onChange={this.onchangeCity} value={addressList.City} />
                        <input type="text" placeholder="State/Region" name="State" onChange={this.onchangeState} value={addressList.State} />
                        <input type="number" placeholder="Zip Code" name="Zip" onChange={this.onchangeZip} value={addressList.Zip} />

                        <button type="submit" onClick={this.onSubmit}> Save Info</button>


                    </div>
                    
                    }

                     
                  </div>


               </div>

            </div>

            

         </div>



      )

   }

}

export default UserAddress;
