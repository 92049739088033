import React, { Component } from 'react';
import preRegStyles from './preReg.module.css';
import LeftImg from '../../assets/preReg/Home - 7-image.jpg';
import { MdStore } from "react-icons/md";
/* import axios from 'axios'; */
import swal from 'sweetalert';


class ShopReg extends Component {
    constructor() {
        super();
        this.onchangeShopName = this.onchangeShopName.bind(this);
        this.onchangeEmployeeName = this.onchangeEmployeeName.bind(this);
        this.onchangeEmail = this.onchangeEmail.bind(this);
        this.onchangeAddress = this.onchangeAddress.bind(this);
        this.onchangeCity = this.onchangeCity.bind(this);
        this.onchangeState= this.onchangeState.bind(this);
        this.onchangeCountry= this.onchangeCountry.bind(this);
        this.onchangePostalCode= this.onchangePostalCode.bind(this);
        this.onchangePhoneNumber= this.onchangePhoneNumber.bind(this);
        this.onchangeDescription= this.onchangeDescription.bind(this);
        this.onchangeShopOwnerPassword= this.onchangeShopOwnerPassword.bind(this);
        this.onchangeShopOwnerId= this.onchangeShopOwnerId.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        
        this.state = { 
            shopName: "",
            employeeName: "",
            email: "",
            address:"",
            city: "",
            state: "",
            country:"",
            postalCode: "",
            phoneNumber: "",
            description: "",
            shopOwnerPassword:"",
            shopOwnerId: ""
         };
    }

    onchangeShopName(e) {
        this.setState({
            shopName: e.target.value
        });
    };
    onchangeEmployeeName(e) {
        this.setState({
            employeeName: e.target.value
        });
    };
    onchangeEmail(e) {
        this.setState({
            email: e.target.value
        });
    };
    onchangeAddress(e) {
        this.setState({
            address: e.target.value
        });
    };
    onchangeCity(e) {
        this.setState({
            city: e.target.value
        });
    };
    onchangeState(e) {
        this.setState({
            state: e.target.value
        });
    };
    onchangeCountry(e) {
        this.setState({
            country: e.target.value
        });
    };
    onchangePostalCode(e) {
        this.setState({
            postalCode: e.target.value
        });
    };
    onchangePhoneNumber(e) {
        this.setState({
            phoneNumber: e.target.value
        });
    };
    onchangeDescription(e) {
        this.setState({
            description: e.target.value
        });
    };
    onchangeShopOwnerPassword(e) {
        this.setState({
            shopOwnerPassword: e.target.value
        });
    };
    onchangeShopOwnerId(e) {
        this.setState({
            shopOwnerId: e.target.value
        });
    };


    onSubmit(event){
         fetch("https://bamziapi.ivyarc.com/api/shopReg", { 
        /* fetch("http://localhost/bamzi/api/shopReg", { */
            method: "POST",
            headers: {
                "Accept":"application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                shopName: this.state.shopName,
                employeeName: this.state.employeeName,
                email: this.state.email,
                address: this.state.address,
                city: this.state.city,
                state: this.state.state,
                country: this.state.country,
                postalCode: this.state.postalCode,
                phoneNumber: this.state.phoneNumber,
                description: this.state.description,
                shopOwnerPassword: this.state.shopOwnerPassword,
                shopOwnerId: this.state.shopOwnerId
            })
        }).then((Response) => Response.json())
            .then((result) => {
                if(result.message == "success"){
                    swal({
                        title: "Updated!",
                        text: "Store Details Updated Successfully...",
                        icon: 'success',
                        button: true
                     });
                     this.props.history.push("/login");
                }
                else{
                    swal({
                        title: "Error!",
                        text: "Please Fill All Fields...",
                        icon: 'error',
                        button: true
                     });
                }
            });
    };

    render() {

        return (
            <div className={preRegStyles.bodyContainer}>
                <div className={preRegStyles.Header}>
                    <div className={preRegStyles.Title}>BAM<span className={preRegStyles.ZI}>ZI</span></div>
                </div>
                <div className={preRegStyles.bodyContent}>

                    <div className={preRegStyles.innerBodyContent}>
                        <div className={preRegStyles.leftSection}>
                            <div className={preRegStyles.leftContent}>
                                <div className={preRegStyles.fieldSet}>
                                    <div className={preRegStyles.actionBtn}>
                                        <div className={preRegStyles.signup}> Update Store Details </div>
                                    </div>

                                    <div className={preRegStyles.preForm} >
                                        <form onSubmit={this.onSubmit}>
                                            <div><input type="text" placeholder="Shop Name " value={this.state.shopName} name="shopName" onChange={this.onchangeShopName} className={preRegStyles.inputForm} /></div>
                                            <div><input type="text" placeholder="Employee Name " value={this.state.employeeName} name="employeeName" onChange={this.onchangeEmployeeName} className={preRegStyles.inputForm} /></div>
                                            <div><input type="email" placeholder="Email Address" value={this.state.email} name="email" onChange={this.onchangeEmail} className={preRegStyles.inputForm} /></div>
                                            <div><input type="number" placeholder="Phone Number" value={this.state.phoneNumber} name="phoneNumber" onChange={this.onchangePhoneNumber} className={preRegStyles.inputForm} /></div>
                                            <div><input type="text" placeholder="Shop Description " value={this.state.description} name="description" onChange={this.onchangeDescription} className={preRegStyles.inputForm} /></div>
                                            <div><input type="text" placeholder="Address" value={this.state.address} name="address" onChange={this.onchangeAddress} className={preRegStyles.inputForm} /></div>
                                            <div><input type="text" placeholder="City " value={this.state.city} name="city" onChange={this.onchangeCity} className={preRegStyles.inputForm} /></div>
                                            <div><input type="text" placeholder="State " value={this.state.state} name="state" onChange={this.onchangeState} className={preRegStyles.inputForm} /></div>
                                            <div><input type="text" placeholder="Country " value={this.state.country} name="country" onChange={this.onchangeCountry} className={preRegStyles.inputForm} /></div>
                                            <div><input type="number" placeholder="Postal Code " value={this.state.postalCode} name="postalCode" onChange={this.onchangePostalCode} className={preRegStyles.inputForm} /></div>
                                            <div><input type="password" placeholder="Shop Password" value={this.state.shopOwnerPassword} name="shopOwnerPassword" onChange={this.onchangeShopOwnerPassword} className={preRegStyles.inputForm} /></div>
                                            <div><input type="text" placeholder="Shop Id" value={this.state.shopOwnerId} name="shopOwnerId" onChange={this.onchangeShopOwnerId} className={preRegStyles.inputForm} /></div>
                                            
                                            </form>
                                        <div className={preRegStyles.BookBtn} onClick={this.onSubmit}>
                                            <div>
                                                <span><MdStore /></span> Update Store
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className={preRegStyles.rightSection}>

                            <div className={preRegStyles.rightBg}>
                                <img src={LeftImg} alt="leftImage" />
                            </div>



                        </div>
                    </div>


                </div>
            </div>
        )

    }

}

export default ShopReg;
