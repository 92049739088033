import React, { Component } from 'react'
import details from './UserDetails.module.css';
import Header from '../../../components/majors/Headers'
import Kemi from '../../../assets/prodItems/Kemi.jpg'
import { IoMdHeart, IoMdCart, IoMdHeartEmpty, IoMdMenu } from "react-icons/io";
import { VscAccount } from "react-icons/vsc";
import { RiShoppingBag3Fill } from "react-icons/ri";
import { FaShippingFast } from "react-icons/fa"
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';   


class UserDetails extends Component {
   constructor() {
      super();
      this.onchangeFullname = this.onchangeFullname.bind(this);
     // this.onchangeEmail = this.onchangeEmail.bind(this);
      this.onchangeProfilePic = this.onchangeProfilePic.bind(this);
      this.onchangePhoneNumber= this.onchangePhoneNumber.bind(this);
      this.onchangeDOB= this.onchangeDOB.bind(this);
      this.onchangeGender= this.onchangeGender.bind(this);
      this.onSubmit = this.onSubmit.bind(this);
      
      this.state = { 
          FullName: "",
          email: localStorage.getItem('userEmail'),
          ProfilePic: "../deg.png",
          phoneNumber:"",
          DOB: "",
          Gender:"",
          userList : []
      };
  }

  onchangeFullname(e) {
      this.setState({
          FullName: e.target.value
      });
  };
/*   onchangeEmail(e) {
      this.setState({
          email: e.target.value
      });
  }; */
  onchangeProfilePic(e) {
      this.setState({
          ProfilePic: e.target.value
      });
  };

  onchangePhoneNumber(e) {
      this.setState({
          phoneNumber: e.target.value
      });
  };
  onchangeDOB(e) {
      this.setState({
          DOB: e.target.value
      });
  };
  onchangeGender(e) {
      this.setState({
          Gender: e.target.value
      });
  };

  onSubmit(event){
      fetch("https://bamziapi.ivyarc.com/api/userDetails", { 
      /* fetch("http://localhost/bamzi/api/userDetails", { */
          method: "POST",
          headers: {
              "Accept":"application/json",
              "Content-Type": "application/json"
          },
          body: JSON.stringify({
              FullName: this.state.FullName,
              email: this.state.email,
              profilePic: this.state.ProfilePic,
              phoneNumber: this.state.phoneNumber,
              DOB: this.state.DOB,
              gender: this.state.Gender
          })
      }).then((Response) => Response.json())
          .then((result) => {
              //console.log(result.message)
              if(result.message == "success"){
                  swal({
                      title: "Updated!",
                      text: "Customer Details Updated Successfully...",
                      icon: 'success',
                      button: true
                   });
              }
              else{
                  swal({
                      title: "Error!",
                      text: "Please Fill All Fields...",
                      icon: 'error',
                      button: true
                   });
              }
          });
  };

  getUser = async () => {
    const req = {
       userEmail: localStorage.getItem('userEmail')
    };
     /* const userList = await axios.get("http://localhost/bamzi/api/userDetails/" + req.userEmail );  */
    const userList = await axios.get("https://bamziapi.ivyarc.com/api/userDetails", req.userEmail); 
    this.setState({ userList: userList.data }, console.log(userList.data));
 };
 
 componentDidMount(){
    this.getUser();
 }

   
   render() {
       const userEmail = localStorage.getItem('userEmail')
       const {userList} = this.state;
   
      return (
         <div className={details.Details}>
            <div className={details.Container}>

               {/* Navbar Section */}
               <Header
                  A1="Products" A1r="/products"
                  A2="Stores" A2r="/stores"
                  A3={<input type="text" name="searchBar" placeholder="Search anything" />}

                  B1={<span> Wishlist <IoMdHeartEmpty /> </span>}  B1r="/wishlist"
                  B2={<span> Cart <IoMdCart /></span>} B2r="/cart"
                  B3={<img src={Kemi} alt="A profile pic" />} B3r="/userDetails"
               /* B4= {loggedIn ? "Logout" : "Login"  */

                  style={{ backgroundColor: "#fff", borderBottom: "1px solid #ccc" }}

               />

               {/* Content */}

               <div className={details.Content} >
                  
                  <div className={details.left}>

                     <div className={details.detailsInfo}> <span><VscAccount /></span> Personal Details </div>
                     <div className={details.orderInfo}> <Link to="/userOrders" className={details.Link}> <span><RiShoppingBag3Fill /></span> Orders </Link></div>
                     <div className={details.addressInfo}> <Link to='/userAddress' className={details.Link}> <span><FaShippingFast /></span> Shipping Address </Link></div>

                  </div>

                  <div className={details.right}>

                     { userList.length > 0 ? userList.map(info => {
                         return(
                            <div className={details.accountInfo}>

                            Account Info
    
                            <input type="text" placeholder="Full Name" value={this.state.FullName} name="FullName" onChange={this.onchangeFullname} />
                            <input type="email" placeholder="usersmail@mail.com" value={userEmail} name="email"/>
                            Profile Picture:  <input type="file" />
                            <input type="number" placeholder="Phone Number"  value={this.state.phoneNumber} name="phoneNumber" onChange={this.onchangePhoneNumber}/>
                            <input type="date" placeholder="Date of Birth"  value={this.state.DOB} name="DOB" onChange={this.onchangeDOB}/>
                            <input type="text" placeholder="Gender"  value={this.state.Gender} name="Gender" onChange={this.onchangeGender}/>
                            
                            <button type="submit"  onClick={this.onSubmit}> Save Info</button>
                         </div>
                         )
                     }):
                     <div className={details.accountInfo}>

                        Account Info

                        <input type="text" placeholder="Full Name" value={userList.FullName} name="FullName" onChange={this.onchangeFullname} />
                        <input autoComplete="false" type="email" placeholder="usersmail@mail.com" value={userEmail} name="email" onChange={this.onchangeEmail}/>
                        Profile Picture:  <input type="file" />
                        <input type="number" placeholder="Phone Number"  value={userList.phoneNumber} name="phoneNumber" onChange={this.onchangePhoneNumber}/>
                        <input type="date" placeholder="Date of Birth"  value={userList.DOB} name="DOB" onChange={this.onchangeDOB}/>
                        <input type="text" placeholder="Gender"  value={userList.gender} name="Gender" onChange={this.onchangeGender}/>
                        
                        <button type="submit"  onClick={this.onSubmit}> Save Info</button>
                     </div>

















                     }

                     <div className={details.password}>

                        Change Password

                        <input type="password" placeholder="Old Password" />
                        <input type="password" placeholder="New Password" />
                        <input type="password" placeholder="Confirm Password" />
                        
                        <button type="submit"> Change Password </button>

                        <div className={details.deactivate}> Deactiviate Account </div>

                     </div>

                  </div>


               </div>

            </div>

         </div>



      )

   }

}

export default UserDetails;
