import React, { Component } from 'react';
import classes from './postRegistration.module.css'
import Success from '../../assets/postRegistration/success.png'
import Bag from '../../assets/postRegistration/bag.png'
import Header from '../../components/majors/Headers'


class PostRegistration extends Component {

    constructor() {
        super();
        this.goToHome = this.goToHome.bind(this);
        this.goToCheckout = this.goToCheckout.bind(this);
        
    };

    goToHome() {
        this.props.history.push("/homepage")
    }
    goToCheckout(){
        this.props.history.push("/Checkout")
    }
  

    render() {
        return (
            <div className={classes.container}>

                {/* Navbar Section */}
                <Header />

                <div className={classes.middle}>
                    <div className={classes.text}>
                        <div className={classes.caption}>
                            <div className={classes.checked}>
                                <img src={Success} alt="successful icon" />
                                <div>Registration Successful!</div>
                            </div>
                            <div className={classes.subCaption}>
                                We will be sharing more info with you soon
                            </div>
                            <div className={classes.homepageBtn} onClick={this.goToHome}>
                                Visit Home Page
                           </div>


                        </div>

                        <div className={classes.images}>
                            <img src={Bag} alt="bag" />
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default PostRegistration;