import React, { Component } from 'react'
import classes from './SpecificStore.module.css';
import Header from '../../components/majors/Headers'
import CoverImage from '../../components/majors/CoverImage'
import Footer from '../../components/majors/Footer'
import CategoryBar from '../../components/majors/CategoryBar'
import Filter from '../../components/majors/Filter'
import UserProducts from '../../components/majors/UserProd'
import Kemi from '../../assets/prodItems/Kemi.jpg'
import { FiSearch } from "react-icons/fi";
import { IoMdHeart, IoMdCart, IoMdHeartEmpty, IoMdMenu } from "react-icons/io";



class SpecificStore extends Component {



   render() {

      return (
         <div className={classes.SpecificStore}>
            <div className={classes.Container}>
               {/* Navbar Section */}
               <Header 
                  A1="Products" A1r="/homepage" 
                  A2="Features"  A2r="/features" 
                  A3={<input type="text" name="searchBar" placeholder="Search anything" />}    
               
                  B1= {<span> Wishlist <IoMdHeartEmpty /> </span>}
                  B2= {<span> Cart <IoMdCart /></span>} B2r="/Cart"
                  B3= {<img src={Kemi} alt="A profile pic" />} B3r="/userDetails"
               
               />

               {/* Cover Image */}
               <CoverImage />

               {/* categories bar */}
               <CategoryBar />


               {/* SpecStoreProds */}
               <div className={classes.SpecStore}>
                  <div className={classes.SpecStoreCont}>

                     {/* Filter */}
                     <Filter />

                     {/* ProductsView */}
                     <UserProducts />

                  </div>
               </div>



               {/* footer */}
               <Footer />

            </div>
         </div>
      )

   }

}

export default SpecificStore
