import React, { Component } from 'react'
import classes from './SpecificStore.module.css';
import Header from '../../components/majors/Headers'
import CoverImage from '../../components/majors/CoverImage'
import Footer from '../../components/majors/Footer'
import CategoryBar from '../../components/majors/CategoryBar'
import Filter from '../../components/majors/Filter'
import UserProducts from '../../components/majors/UserProducts'
import Kemi from '../../assets/prodItems/Kemi.jpg'
import { FiSearch } from "react-icons/fi";
import { IoMdHeart, IoMdCart, IoMdHeartEmpty, IoMdMenu } from "react-icons/io";
import axios from 'axios';
import $ from 'jquery';


var pageURL = decodeURIComponent(window.location.href);
var splitURL = pageURL.split('/');
var storeName = splitURL[4]
console.log(storeName);


class SpecificStore extends Component {
   state = {
      storeProduct: [],
      storeProductItems: [],
      disabled: false
   };

   getProduct = async () => {
      const req = {
         storeName: storeName
      }
      /* const storeProduct = await axios.post("http://localhost/bamzi/api/shops/details", req); */
      const storeProduct = await axios.post("https://bamziapi.ivyarc.com/api/shops/details", req);
      this.setState({ storeProduct: storeProduct.data });
      console.log(storeProduct);
   };


   getProductItems = async () => {
      const req = {
         storeName: storeName
      }
      /* const storeProductItems = await axios.post("http://localhost/bamzi/api/shops/products", req); */
      const storeProductItems = await axios.post("https://bamziapi.ivyarc.com/api/shops/products", req);
      this.setState({ storeProductItems: storeProductItems.data });
      console.log(storeProductItems);
   };

   work = () => {
      let CoverImg ;
      let catBar ;
      let filt ;
      let prod ;
      setTimeout(() => {
          CoverImg = <CoverImage />
          catBar = <CategoryBar />
          filt = <Filter />
          prod = <UserProducts />
      }, 2000);
   }

   solve = () =>{
      if(window.localStorage){
         if( !localStorage.getItem('firstload'))
         {
            localStorage['firstload'] = true;
            window.location.reload();
         }
         else{
            localStorage.removeItem('firstload');
         }
      }
   }


   componentDidMount() {
      this.getProduct();
      this.getProductItems();
      this.work();
      setTimeout(() => {
         this.solve();
      }, 1000);
   }





   render() {
      const { storeProduct, storeProductItems } = this.state;

      return (
         <div className={classes.SpecificStore}>
            <div className={classes.Container}>
               {/* Navbar Section */}
               <Header 
                  A1="Products" A1r="/homepage" 
                  A2="Features"  A2r="/features" 
                  A3={<input type="text" name="searchBar" placeholder="Search anything" />}    
               
                  B1= {<span> Wishlist <IoMdHeartEmpty /> </span>}
                  B2= {<span> Cart <IoMdCart /></span>} B2r="/Cart"
                  B3= {<img src={Kemi} alt="A profile pic" />} B3r="/userDetails"
               />

               {/* Cover Image */}
               <CoverImage />

               {/* categories bar */}
               <CategoryBar />


               {/* SpecStoreProds */}
               <div className={classes.SpecStore}>
                  <div className={classes.SpecStoreCont}>

                     {/* Filter */}
                     <Filter />

                     {/* ProductsView */}
                     <UserProducts />

                  </div>
               </div>



               {/* footer */}
               <Footer />

            </div>
         </div>
      )

   }

}

export default SpecificStore
