import React, { Component } from 'react';
import verify from './verifyMail.module.css';
import LeftImg from '../../assets/preReg/Home - 7-image.jpg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faGooglePlusG } from '@fortawesome/free-brands-svg-icons';
import Navbar from '../../components/Navbar';
import axios from 'axios';
import swal from 'sweetalert';
import Header from '../../components/majors/Headers'



class VerifyMail extends Component {
    constructor() {
        super();
        this.onchangeEmail = this.onchangeEmail.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
           email: ""
        };
     };

     onchangeEmail(e) {
        this.setState({
           email: e.target.value
        });
     };

     onSubmit(event){
         fetch("https://bamziapi.ivyarc.com/api/forgotPassword", { 
        // fetch("http://localhost/bamzi/api/forgotPassword", {
            method: "POST",
            headers: {
                "Accept":"application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: this.state.email,
            })
        }).then((Response) => Response.json())
            .then((result) => {
                if(result.message == "User does not exists"){
                    swal({
                        title: "Error!",
                        text: "User With Mail Doesn't Exist...",
                        icon: 'error',
                        button: true
                     });
                }
                else{
                    swal({
                        title: "Updated!",
                        text: "Password Rest Token Sent To Mail Successfully...",
                        icon: 'success',
                        button: true
                     });
                    this.props.history.push("/resetPassword")
                }
            })

    };

    render() {
        return (
            <div className={verify.bodyContainer}>
                {/* Navbar Section */}
                <Header
                    A1="Products" A1r="/products"
                    A2="Stores"
                    A3="Pricing"
                    A4="Features"  A4r="/features"

                />



                <div className={verify.bodyContent}>

                    <div className={verify.innerBodyContent}>
                        <div className={verify.leftSection}>
                            <div className={verify.leftContent}>
                                <div className={verify.veryCaption}> 
                                    Verification
                                </div>
                                <div className={verify.verySubCaption}> 
                                    Input email and a verification link would be sent to your mailbox
                                </div>
                                
                                <div className={verify.fieldSet}>
                                    
                                    <div className={verify.preForm} >
                                        <form action=".">
                                            <div><input type="email" placeholder="Email Address" className={verify.inputForm} value={this.state.email} name="email" onChange={this.onchangeEmail} required /></div>
                                        </form>
                                        <div className={verify.BookBtn} onClick={this.onSubmit}>
                                            <div>
                                                Reset
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className={verify.rightSection}>

                            <div className={verify.rightBg}>
                                <img src={LeftImg} alt="leftImage" />
                            </div>

                        </div>

                    </div>


                </div>
            </div>

            
        )

    }

}

export default VerifyMail



