import React, { Component } from 'react'
import classes from './Home.module.css'
import Mask from '../../assets/Mask.png'
import Car from '../../assets/homePage/car.jpg'
import Food from '../../assets/homePage/food.jpg'
import Phone from '../../assets/homePage/phones.jpg'
import Fashion from '../../assets/homePage/fashion.jpg'
import { IoMdCart } from "react-icons/io";
import { MdStore } from "react-icons/md";
import Header from '../../components/majors/Headers'
import { Link } from 'react-router-dom';
import axios from 'axios';
class Home extends Component {
   state = {
      category: [],
      disabled: false
   };

   getCategory = async () => {
      /* const category = await axios.get("http://localhost/bamzi/api/productCategory"); */
      const category = await axios.get("https://bamziapi.ivyarc.com/api/productCategory");
      this.setState({ category: category.data });
   };


   componentDidMount() {
      this.getCategory();
   }

   render() {
      const {category} = this.state;
      return (
         <div className={classes.Home}>


            {/* Navbar Section */}
            <Header
               A1="Products" A1r="/products"
               A2="Stores" A2r="/stores"
               A3="Pricing"
               A4="Features" A4r="/features"


               B1={<span> Cart <IoMdCart /></span>} B1r="/cart"
               B2="Login" B2r="/login"



            />


            <div className={classes.middle}>
               <div className={classes.text}>
                  <div className={classes.caption}> Let's go Shopping. Buy Anything</div>

                  <div className={classes.subCaption}>
                     Every product deserves a digital presence. Create your store with Bamzi and have a wider reach of market audience.   It is simple and flexible to use.
                  </div>
                  <div className={classes.buttonContainer}>
                     <div className={classes.getStarted} >
                        <Link className={classes.Link} to="/signup" > Get Started </Link>

                     </div>
                     <div className={classes.goShopping}>
                        <Link className={classes.Link} to="/products" > Go Shopping </Link>
                     </div>
                  </div>

               </div>

               <div className={classes.images}>
                  <img src={Mask} alt="Assets" width="380px" />
               </div>
            </div>


            <div className={classes.base}>
               <div className={classes.baseContent}>
                  <div>
                  {category.map(cat => {
                     const { id, categoryName, categoryImage } = cat;
                     return (
                        
                           <div className={classes.box1}>
                              <div className={classes.imgWrap}><img src={Phone} alt="phones" /></div>
                              <div>{categoryName} </div>
                           </div>
                        
                     )
                  })}
                  </div>
               </div>

            </div>

         </div>
      )

   }

}

export default Home
