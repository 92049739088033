import React, { Component } from 'react'
import classes from './Checkout.module.css';
import Header from '../../components/majors/Headers'
import CoverImage from '../../components/majors/CoverImage'
import Footer from '../../components/majors/Footer'
import CategoryBar from '../../components/majors/CategoryBar'
import Filter from '../../components/majors/Filter'
import UserProducts from '../../components/majors/UserProducts'
import Kemi from '../../assets/prodItems/Kemi.jpg'
import { FiSearch } from "react-icons/fi";
import { IoMdHeart, IoMdCart, IoMdHeartEmpty, IoMdMenu } from "react-icons/io";
import { BsCreditCard, BsEyeSlash } from "react-icons/bs";
import { SiGooglepay } from "react-icons/si";
import Master from '../../assets/small-Icons/small-master.png'
import Ppal from '../../assets/small-Icons/small-ppal.png'
import Visa from '../../assets/small-Icons/small-visa.png'
import axios from 'axios'




class Checkout extends Component {
   constructor() {
      super();
      this.onchangeName = this.onchangeName.bind(this);
      this.onchangePhone = this.onchangePhone.bind(this);
      this.onchangeAddress = this.onchangeAddress.bind(this);
      this.onchangeCity = this.onchangeCity.bind(this);
      this.onchangeState = this.onchangeState.bind(this);
      this.onchangeZip = this.onchangeZip.bind(this);
      // this.onSubmit = this.onSubmit.bind(this);


      this.state = {
         name: "",
         phone: "",
         address: "",
         city: "",
         state: "",
         zip: "",
         disabled: true,
         userInfo: []
      };
   };

   onchangeName(e) {
      this.setState({
         fullname: e.target.value
      });
   };

   onchangePhone(e) {
      this.setState({
         phone: e.target.value
      });
   };


   onchangeAddress(e) {
      this.setState({
         address: e.target.value
      });
   };

   onchangeCity(e) {
      this.setState({
         city: e.target.value
      });
   };

   onchangeState(e) {
      this.setState({
         state: e.target.value
      });
   };

   onchangeZip(e) {
      this.setState({
         zip: e.target.value
      });
   };

   getUser = async () => {
      const req = {
         userEmail: localStorage.getItem('userEmail')
      }
      /* const userInfo = await axios.post("http://localhost/bamzi/api/customerReg/details", req); */
      const userInfo = await axios.post("https://bamziapi.ivyarc.com/api/customerReg/details", req);
      this.setState({ userInfo: userInfo.data }, console.log(userInfo.data));
   };

   getUserInfo = () => {
      let cart = localStorage.getItem("cart");
      /* for (let index = 0; index < cart.length; index++) {
         //const element = array[index];
         alert("items" + [index])
      } */
      /*  alert(JSON.parse(cart)) */
   }

   componentDidMount() {
      this.getUser();
      setTimeout(() => {
         this.getUserInfo();
      }, 5000);
   }

   render() {
      const { userInfo } = this.state;

      return (
         <div className={classes.Checkout}>
            <div className={classes.Container}>

               {/* Navbar Section */}
               <Header
                  A1="Products" A1r="/products"
                  A2="Stores" A2r="/stores"
                  A3={<input type="text" name="searchBar" placeholder="Search anything" />}

                  B1={<span> Wishlist <IoMdHeartEmpty /> </span>}  B1r="/wishlist"
                  B2={<span> Cart <IoMdCart /></span>} B2r="/cart"
                  B3={<img src={Kemi} alt="A profile pic" />} B3r="/userDetails"
               /* B4= {loggedIn ? "Logout" : "Login"  */

                  style={{ backgroundColor: "#fff", borderBottom: "1px solid #ccc" }}

               />

               {/* Content */}

               <div className={classes.Content} >
                  <div className={classes.left}>
                     <div className={classes.checkoutTit}>
                        Checkout
                     </div>

                     <div className={classes.shipping}>
                        <div className={classes.shippingTit}>Shipping Information</div>
                        {userInfo.map(user => {
                           const { id, customerFirstName, customerLastName, addressLine, postalCode, city, phoneNumber, state } = user;
                           let name = customerFirstName + " " + customerLastName
                           return (
                              <div className={classes.shippingInfo}>

                                 <div className={classes.basicInfo}>
                                    <div className={classes.basicInfoTit}>Basic Info</div>
                                    <div className={classes.basicInfoCont}>
                                       <input type="text" placeholder="Name" name="name" value={name} onChange={this.onchangeName} />
                                       <input type="number" placeholder="Phone Number" name="phoneNumber" value={phoneNumber} onChange={this.onchangePhone} />
                                    </div>
                                 </div>

                                 <div className={classes.address}>
                                    <div className={classes.addressTit}>Address</div>
                                    <div className={classes.addressInp}>
                                       <div className={classes.addressInpAdd}>
                                          <input type="text" placeholder="Home Address" name="address" value={addressLine} onChange={this.onchangeAddres} />
                                       </div>

                                       <div>
                                          <input type="text" placeholder="City" name="city" value={city} onChange={this.onchangeCity} />
                                          <input type="text" placeholder="State/Region" name="state" value={state} onChange={this.onchangeState} />
                                          <input type="number" placeholder="Zip Code" name="zipCode" value={postalCode} onChange={this.onchangeZip} />
                                       </div>
                                    </div>
                                 </div>

                                 <div className={classes.saveAndCont}>Save and Continue</div>

                              </div>

                           )
                        })}
                     </div>

                     <div className={classes.payment}>
                        <div className={classes.paymentTit}>Payment Method</div>

                        <div className={classes.paymentOption}>

                           <div className={classes.payCard}>
                              <div>
                                 <BsCreditCard />
                                 <img src={Master} alt="brands-logo" className={classes.brdIcon} />
                                 <img src={Visa} alt="brands-logo" className={classes.brdIcon} />
                              </div>
                              <p>Debit Card</p>
                           </div>

                           <div className={classes.payPal}>
                              Pay<span className={classes.payPalPal}>Pal</span>
                           </div>



                           <div className={classes.googlePay}>
                              <div> <SiGooglepay /> </div>
                              {/* <span>Google Pay</span> */}
                           </div>

                           <div className={classes.payDelivery}>Pay on delivery</div>

                        </div>


                     </div>


                  </div>










                  <div className={classes.right}>

                     <div className={classes.orderSummary}>
                        <div className={classes.orderSummaryTit}>Order Summary</div>

                        <div className={classes.breakdown}>
                           <div>
                              <div>Shipping Fee</div>
                              <div>$10.70</div>
                           </div>
                           <div>
                              <div>Sub Total</div>
                              <div>$19.00</div>
                           </div>

                        </div>

                        <div className={classes.coupon}>
                           <div className={classes.coupInp}> <input type="text" placeholder="Coupon Code" name="coupon" /> </div>
                           <div> <IoMdHeart /> My Gifts</div>
                        </div>

                        <div className={classes.total}>
                           <div>Total</div>
                           <div>$0.00</div>
                        </div>

                        <div className={classes.proceed} onClick={this.getUserInfo}>Buy</div>

                     </div>

                     <div className={classes.secure}>
                        <div className={classes.secureTit}> SECURED PAY</div>
                        <div className={classes.secureText}>
                           Payment are secured with Bamzi and additional security is ensured with Money Guaranss
                        </div>
                        <div className={classes.secureIcons}>
                           <IoMdHeart />
                           <IoMdCart />
                           <IoMdHeartEmpty />
                        </div>
                     </div>

                  </div>

               </div>


            </div>
         </div>



      )

   }

}

export default Checkout;
