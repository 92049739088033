import React, { Component, useState, useEffect } from 'react'
import SOCustomize from './SOCustomizeShop.module.css'
import Footer from '../../components/majors/Footer'
import Filter from '../../components/majors/Filter'
import StoreProducts from '../../components/majors/StoreProducts'
import { FiBell, FiHelpCircle, FiGrid, FiCircle, FiHome, FiMail, FiBox } from "react-icons/fi";
import { BsCreditCard, BsEyeSlash } from "react-icons/bs";
import { VscAccount, VscBellDot } from "react-icons/vsc";
import { FaRegCalendarAlt, FaStore } from "react-icons/fa";
import { IoMdCart, IoMdHeart, IoMdPeople } from "react-icons/io";
import { AiFillBank } from "react-icons/ai";
import { MdAddShoppingCart } from "react-icons/md";
import Kemi from '../../assets/prodItems/Kemi.jpg'
import Trophy from '../../assets/sellerDashboard/trophy.png'
import { CgMenuBoxed } from "react-icons/cg";
import { Link } from 'react-router-dom'
import axios from 'axios'
import swal from 'sweetalert'


const StoreOwnersCustomizeShop = props => {
   const [shopName, setShopName] = useState('')
   const [employeeName, setemployeeName] = useState('')
   const [email, setemail] = useState('')
   const [address, setaddress] = useState('')
   const [city, setcity] = useState('')
   const [state, setstate] = useState('')
   const [country, setcountry] = useState('')
   const [postalCode, setpostalCode] = useState('')
   const [description, setdescription] = useState('')
   const [shopOwnerId, setshopOwnerId] = useState('')
   const [phoneNumber, setphoneNumber] = useState('')
   //const [fetchedData, setFetchedData] = useState('')


   async function fetchShop() {
      // const shopData = { shopName, employeeName, email, address, city, state, country, postalCode, description, shopOwnerId, phoneNumber }
         fetch("https://bamziapi.ivyarc.com/api/shopReg", {
         /* fetch("http://localhost/bamzi/api/shopReg", { */
         method: "POST",
         headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
         },
         body: JSON.stringify({
            shopName: shopName,
            employeeName: employeeName,
            email: email,
            address: address,
            city: city,
            state: state,
            country: country,
            postalCode: postalCode,
            phoneNumber: phoneNumber,
            description: description,
            shopOwnerPassword: "store",
            shopOwnerId: shopOwnerId
         })
      }).then((Response) => Response.json())
         .then((result) => {
            console.log(shopName);
            if (result.message == "success") {
               swal({
                  title: "Updated!",
                  text: "Store Details Updated Successfully...",
                  icon: 'success',
                  button: true
               });
               localStorage.setItem("shopName",shopName);
               props.history.push("/login");
            }
            else {
               swal({
                  title: "Error!",
                  text: "Please Fill All Fields...",
                  icon: 'error',
                  button: true
               });
            }
         });
   }
/* 
   useEffect(() => {
      fetchShop()
   }, []) */

   const handleSubmit = e => {
      e.preventDefault()
      fetchShop()
   }

   /* 
     const [inputField, setInputField] = useState({
         shopName: "",
         employeeName: "",
         email: "",
         address:"",
         city: "",
         state: "",
         country:"",
         postalCode: "",
         phoneNumber: "",
         description: "",
         shopOwnerPassword:"",
         shopOwnerId: ""
     }) 
   
     const inputsHandler = (e) => {
        let value = { [e.target.name]: e.target.value },
        value = {
           ...inputField,
           ...value,
        };
        setInputField( {[e.target.name]: e.target.value})
     }
   
   
   
      const shopReg = () => {
         alert(inputField.shopName);
         console.log(inputField.shopName);
   
      }
    */


   const [menuappear, setMenuappear] = useState(false);
   const [width, setWidth] = useState(window.innerWidth);

   const updateWidth = () => {
      setWidth(window.innerWidth);
   }

   useEffect(() => {
      window.addEventListener("resize", updateWidth);
      return () => window.removeEventListener("resize", updateWidth);
   })

   const menuShow = () => {
      setMenuappear(!menuappear)
   }







   let smallSize = <div> <CgMenuBoxed onClick={menuShow} className={SOCustomize.menuButton} style={{ fontSize: "2.5em", color: "#000033" }} /> </div>

   let Menu = <div className={SOCustomize.leftCent} onClick={menuShow}>
      <div className={SOCustomize.Title}><Link to="/homepage" className={SOCustomize.Link}> BAM<span className={SOCustomize.ZI}>ZI</span> </Link></div>

      <div className={SOCustomize.Section1}>
         <div> <Link to='/soDashboard' className={SOCustomize.Link}> <span><FiHome /></span>  Dashboard </Link> </div>
         <div> <span><FiCircle /></span> Analytics </div>
         <div>  <Link to='/soMarketing' className={SOCustomize.Link}> <span><FiCircle /></span> Marketing </Link> </div>
      </div>


      <div className={SOCustomize.Section2}>
         <div className={SOCustomize.SecTitle}  >PRODUCTS</div>

         <div>  <Link to='/storeOwnersStore' className={SOCustomize.Link}> <span><FiBox /></span> All Products </Link> </div>
         <div> <Link to='/soAddProduct' className={SOCustomize.Link}> <span><MdAddShoppingCart /></span> Add Products </Link> </div>
         <div>  <Link to='/soSales' className={SOCustomize.Link}> <span><IoMdCart /></span> Sales </Link></div>
         <div> <span><FiGrid /></span> Categories </div>
      </div>

      <div className={SOCustomize.Section3}>
         <div className={SOCustomize.SecTitle}  >Transactions </div>
         <div>  <Link to='/soHistory' className={SOCustomize.Link}> <span><FaRegCalendarAlt /></span> History </Link> </div>
         <div> <span><BsCreditCard /></span> Billings </div>
      </div>

      <div className={SOCustomize.Section4}>
         <div className={SOCustomize.SecTitle}  >PRODUCTS </div>
         <div> <span><VscAccount /></span> Account </div>
         <div> <Link to='/soCustomize' className={SOCustomize.Link}> <span><FiBox /></span> Customise Shop </Link> </div>
         <div> <span><FiHelpCircle /></span> Help </div>
      </div>

   </div>

   if (menuappear) {
      smallSize = Menu
   }

   return (
      <div className={SOCustomize.StoreOwnersShop}>
         <div className={SOCustomize.Container}>

            <div className={SOCustomize.left}>
               {width > 860 ? Menu : smallSize}
            </div>


            <div className={SOCustomize.Right}>
               <div className={SOCustomize.storeOwnersTopTab}>

                  <div className={SOCustomize.NavList} >
                     <div>
                        <ul className={SOCustomize.NavList1} >
                           <li><div> <AiFillBank /> Account: $12,990 </div></li>
                           <li><BsEyeSlash /></li>
                           <li><FiBell /></li>
                           <li><FiMail /></li>
                        </ul>
                     </div>

                     <div>
                        <ul className={SOCustomize.NavList2} >
                           <li className={SOCustomize.liFlex}> <FaStore /> </li>
                           <li className={SOCustomize.liFlex}> Evans Bex Electronics Store </li>
                           <li> <img src={Kemi} alt="A profile pic" /> </li>
                        </ul>
                     </div>
                  </div>

               </div>





               <div className={SOCustomize.customize}>
                  <div className={SOCustomize.customizeCont}>


                     <form onSubmit={handleSubmit}>
                        <div className={SOCustomize.top}>
                           <div className={SOCustomize.tit}> <span> <FiBox /> </span> Customize Shop</div>

                           <div className={SOCustomize.save}> <button onclick={handleSubmit} > Save</button> </div>

                        </div>



                        <div className={SOCustomize.middle}>


                           <div className={SOCustomize.middleLeft}>

                              <div className={SOCustomize.store}> Store Info </div>

                              <div className={SOCustomize.storeDet}>

                                 <label> <input type="text" placeholder="Store Name" required name={shopName} onChange={e => setShopName(e.target.value)} value={shopName} /> </label>

                                 <label> <input type="text" placeholder="Owners Name" required name="employeeName" onChange={e => setemployeeName(e.target.value)} value={employeeName} /> </label>

                                 <label> <input type="email" placeholder="E-mail" required name="email" onChange={e => setemail(e.target.value)} value={email} /> </label>



                                 <label className={SOCustomize.descText}>

                                    <textarea placeholder="Store Description Max(60)" required rows={3} cols={30} name="description" maxLength={60} onChange={e => setdescription(e.target.value)} value={description} />

                                    <div>
                                       This is a short description of what your store offers. Be short and precise as this is what customers would see.
                                    </div>

                                 </label>

                                 <label> <input type="text" placeholder="Store Full Legal Address" required name="address" onChange={e => setaddress(e.target.value)} value={address} /> </label>

                                 <div>
                                    <label> <input type="text" placeholder="City" required name="city" onChange={e => setcity(e.target.value)} value={city} /> </label>
                                    <label> <input type="text" placeholder="State/Region" required name="state" onChange={e => setstate(e.target.value)} value={state} /> </label>
                                 </div>

                                 <div>
                                    <label> <input type="text" placeholder="Country" required name="country" onChange={e => setcountry(e.target.value)} value={country} /> </label>
                                    <label> <input type="number" placeholder="Zip/Post Code" required name="postalCode" onChange={e => setpostalCode(e.target.value)} value={postalCode} /> </label>
                                 </div>

                                 <label>   <input type="number" placeholder="Phone Number" required name="phoneNumber" onChange={e => setphoneNumber(e.target.value)} value={phoneNumber} /> </label>

                                 <label>   <input type="text" placeholder="Additional Info" required name="shopOwnerId" onChange={e => setshopOwnerId(e.target.value)} value={shopOwnerId} /> </label>

                              </div>

                           </div>

                           <div className={SOCustomize.middleRight}>
                              <div className={SOCustomize.middleRightTit}>Store Appearance</div>



                              <label>   Upload Image: <input type="file" /> </label>

                              <label>   Upload Cover Image:  <input type="file" /> </label>



                              <div className={SOCustomize.shopCoverImage}>

                                 <div className={SOCustomize.shopImage}> </div>
                              </div>

                           </div>

                        </div>

                     </form>






                  </div>
               </div>
            </div>

         </div>
      </div>


   )

}


export default StoreOwnersCustomizeShop;
