
import './App.css';
import PreLaunch from './containers/prelaunch/prelaunch';
import PostRegistration from './containers/prelaunch/postRegistration';
import PreReg from './containers/prelaunch/preReg';
import Home from './containers/prelaunch/Home';
import shopReg from './containers/prelaunch/ShopReg';
import CustormerReg from './containers/prelaunch/customerReg';
import HomePage from './containers/prelaunch/HomePage';
import Features from './containers/prelaunch/features';
import SpecificStore from "./containers/UserScreens/SpecificStore";
import SpecStore from "./containers/UserScreens/SpecStore";
import Cart from "./containers/UserScreens/Cart";
import Wishlist from "./containers/UserScreens/Wishlist";
import ProductDetails from "./containers/UserScreens/ProductDetails";
import Checkout from "./containers/UserScreens/Checkout";
import StoreOwnersStore from "./containers/StoreOwnersScreens/StoreOwnersStore";
import SOMarketing from "./containers/StoreOwnersScreens/SOMarketing";
import SOEditProduct from "./containers/StoreOwnersScreens/SOEditProduct";
import SOAddProduct from "./containers/StoreOwnersScreens/SOAddProduct";
import SOCustomize from "./containers/StoreOwnersScreens/SOCustomizeShop";
import SODashboard from "./containers/StoreOwnersScreens/SODashboard";
import SignUp from './containers/auth/signup';
import Login from './containers/auth/login';
import ResetPassword from './containers/auth/resetPassword';
import VerifyMail from './containers/auth/verifyMail';
import Error404 from './containers/auth/error404';
import UserAddress from "./containers/UserScreens/UserProfile/UserAddress";
import UserDetails from "./containers/UserScreens/UserProfile/UserDetails";
import UserOrders from "./containers/UserScreens/UserProfile/UserOrders";
import AllProducts from './containers/UserScreens/AllProducts'
import AllStores from './containers/UserScreens/AllStores'
import SOSales from "./containers/StoreOwnersScreens/SOSales"
import SOHistory from "./containers/StoreOwnersScreens/SOHistory"
import { Route, Switch } from 'react-router-dom/';


function App() {
  return (
   <>
     <Switch>
       <Route exact path="/" component={PreLaunch} />
       <Route exact path="/postRegistration" component={PostRegistration} />
       <Route exact path="/specStore" component={SpecStore} />
       <Route exact path="/specificStore/:storename" component={SpecificStore} />
       <Route exact path="/cart" component={Cart} />
       <Route exact path="/checkout" component={Checkout} />
       <Route exact path="/wishlist" component={Wishlist} />
       <Route exact path="/productDetails/:productName" component={ProductDetails} />
       <Route exact path="/storeOwnersStore" component={StoreOwnersStore} />
       <Route exact path="/soAddProduct" component={SOAddProduct} />
       <Route exact path="/soEditProduct" component={SOEditProduct} />
       <Route exact path="/soDashboard" component={SODashboard} />
       <Route exact path="/soCustomize" component={SOCustomize} />
       <Route exact path="/soMarketing" component={SOMarketing} />
       <Route exact path="/soHistory" component={SOHistory} />
       <Route exact path="/soSales" component={SOSales} />
       <Route exact path="/pre-registration" component={PreReg} />
       <Route exact path="/resetPassword" component={ResetPassword} />
       <Route exact path="/verifyMail" component={VerifyMail} />
       <Route exact path="/login" component={Login} />
       <Route exact path="/home" component={Home} />
       <Route exact path="/homepage" component={HomePage} />
       <Route exact path="/features" component={Features} />
       <Route exact path="/signup" component={SignUp} />
       <Route exact path="/shopReg" component={shopReg} /> 
       <Route exact path="/customerReg" component={CustormerReg} /> 
       <Route exact path="/userAddress" component={UserAddress} /> 
       <Route exact path="/userDetails" component={UserDetails} /> 
       <Route exact path="/userOrders" component={UserOrders} /> 
       <Route exact path="/products" component={AllProducts} /> 
       <Route exact path="/stores" component={AllStores} /> 
       <Route component={Error404} />
     </Switch>
   </>
  )
}

export default App;