import React, { Component, useState, useEffect } from 'react'
import classes from './Filter.module.css'
import { MdStarBorder } from "react-icons/md";
import { FiFilter } from "react-icons/fi";
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

const Filter = props => {

   const [filappear, setFilappear] = useState(false);
   const [width, setWidth] = useState(window.innerWidth);

   const updateWidth = () => {
      setWidth(window.innerWidth);
   }

   useEffect(() => {
      window.addEventListener("resize", updateWidth);
      return () => window.removeEventListener("resize", updateWidth);
   })

   const filShow = () => {
      setFilappear(!filappear)
   }

   let smallSize = <div onClick={filShow}> <FiFilter className={classes.menuButton} /> Filters </div>

   let Filter = <div onClick={filShow}>
      <div className={classes.filTitle}>Filters</div>
      <div className={classes.filterbox}>

         <div className={classes.box1}>
            <div className={classes.filTitle}>Multi Range</div>
            <label><input type="radio" name="multiRange" value="$10" /> below $10  </label>
            <label><input type="radio" name="multiRange" value="$10 - $100" /> $10 - $100</label>
            <label><input type="radio" name="multiRange" value="$100 - $500" /> $100 - $500</label>
            <label><input type="radio" name="multiRange" value="$500up" /> above $500 </label>
            <label><input type="radio" name="multiRange" value="all" /> All</label>

         </div>

         <div className={classes.box2}>
            <div className={classes.filTitle}>
               <div>Slider </div>
               <div>$0 - $5000</div>
            </div>
            <div>
               <Range
                  min={0}
                  max={5000}
                  step={10}
                  defaultValue={[0, 5000]}
                  allowCross={false}
                  trackStyle={{ backgroundColor: '#000033' }}
                  handleStyle={{ backgroundColor: '#fdd25a' }}

               />
            </div>

         </div>

         <div className={classes.box3}>
            <div className={classes.filTitle}>Category</div>
            <div>
               <label><input type="checkbox" name="cellPhones" value="cellPhones" /> Cell Phones</label>
               <label> 1920</label>
            </div>
            <div>
               <label><input type="checkbox" name="compTab" value="compTab" /> Computers & Tablets</label>
               <label> 1920</label>
            </div>
            <div>
               <label><input type="checkbox" name="cellPhoAcc" value="cellPhoAcc" /> Cell Phones Accessories</label>
               <label> 462</label>
            </div>
            <div>
               <label><input type="checkbox" name="appliances" value="appliances" /> Appliances</label>
               <label> 6556</label>
            </div>
            <div>
               <label><input type="checkbox" name="audio" value="audio" /> Audios</label>
               <label> 120</label>
            </div>
            <div>
               <label><input type="checkbox" name="iPhAcc" value="iPhAcc" /> iPhone Accessories</label>
               <label> 353</label>
            </div>
            <div>
               <label><input type="checkbox" name="CamCam" value="CamCam" /> Cameras & Camcorders</label>
               <label> 45</label>
            </div>
            <div>
               <label><input type="checkbox" name="iphcc" value="iphcc" /> iPhone Cases & Clips</label>
               <label> 456</label>
            </div>
            <div>
               <label><input type="checkbox" name="tvHt" value="tvHt" /> TV & Home Theatre</label>
               <label> 55</label>
            </div>
            <div>
               <label><input type="checkbox" name="smallKitAppl" value="smallKitAppl" /> Small Kitchen Appliances</label>
               <label> 10</label>
            </div>

         </div>

         <div className={classes.box4}>
            <div className={classes.filTitle}>Brand</div>
            <div>
               <label><input type="checkbox" name="insigni" value="insigni" /> Insigni</label>
               <label> 1920</label>
            </div>
            <div>
               <label><input type="checkbox" name="Samsung" value="Samsung" /> Samsung</label>
               <label> 1920</label>
            </div>
            <div>
               <label><input type="checkbox" name="Apple" value="Apple" /> Apple</label>
               <label> 462</label>
            </div>
            <div>
               <label><input type="checkbox" name="HP" value="HP" /> HP</label>
               <label> 6556</label>
            </div>
            <div>
               <label><input type="checkbox" name="Sony" value="Sony" /> Sony</label>
               <label> 120</label>
            </div>
            <div>
               <label><input type="checkbox" name="Metra" value="Metra" /> Metra</label>
               <label> 353</label>
            </div>
            <div>
               <label><input type="checkbox" name="Dyne" value="Dyne" /> Dyne</label>
               <label> 45</label>
            </div>
            <div>
               <label><input type="checkbox" name="LG" value="LG" /> LG</label>
               <label> 456</label>
            </div>
            <div>
               <label><input type="checkbox" name="Canon" value="Canon" /> Canon</label>
               <label> 55</label>
            </div>
            <div>
               <label><input type="checkbox" name="Speck" value="Speck" /> Speck</label>
               <label> 10</label>
            </div>

         </div>



         <div className={classes.box5}>
            <div className={classes.filTitle}>Ratings</div>
            <div>
               <label><div><span><MdStarBorder /><MdStarBorder /><MdStarBorder /><MdStarBorder /><MdStarBorder /> </span></div> </label>
               <label> 1920</label>
            </div>
            <div>
               <label><div><span><MdStarBorder /><MdStarBorder /><MdStarBorder /><MdStarBorder /></span><MdStarBorder /> </div> </label>
               <label> 1920</label>
            </div>
            <div>
               <label><div><span><MdStarBorder /><MdStarBorder /><MdStarBorder /></span><MdStarBorder /><MdStarBorder /> </div> </label>
               <label> 462</label>
            </div>
            <div>
               <label><div><span><MdStarBorder /><MdStarBorder /></span><MdStarBorder /><MdStarBorder /><MdStarBorder /> </div> </label>
               <label> 6556</label>
            </div>
            <div>
               <label><div><span><MdStarBorder /></span><MdStarBorder /><MdStarBorder /><MdStarBorder /><MdStarBorder /> </div> </label>
               <label> 120</label>
            </div>


         </div>

         <div className={classes.clearFilters}>
            CLEAR ALL FILTERS
            </div>


      </div>
   </div>

   if (filappear) {
      smallSize = Filter
   }


   return (

      <div className={classes.filters}>
         {width > 640 ?  Filter  :  smallSize  }
      </div>


   )

}


export default Filter
