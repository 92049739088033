import React, { Component } from 'react'
import cart from './Cart.module.css';
import Header from '../../components/majors/Headers'
import CoverImage from '../../components/majors/CoverImage'
import Footer from '../../components/majors/Footer'
import CategoryBar from '../../components/majors/CategoryBar'
import Filter from '../../components/majors/Filter'
import UserProducts from '../../components/majors/UserProducts'
import Kemi from '../../assets/prodItems/Kemi.jpg'
import ApplePodsPic from '../../assets/prodItems/applePods.png';
import AppleWatchPic from '../../assets/prodItems/appleWatch.png';
import IphonePic from '../../assets/prodItems/iPhone.png';
import LaptopPic from '../../assets/prodItems/laptop.jpg';
import Saloon from '../../assets/prodItems/saloon.jpg';
import { FiSearch } from "react-icons/fi";
import { IoMdHeart, IoMdCart, IoMdHeartEmpty, IoMdMenu } from "react-icons/io";
import { BsCreditCard, BsEyeSlash } from "react-icons/bs";
import { FiGrid, FiTrash2 } from "react-icons/fi";
import { MdStore } from "react-icons/md";
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';


class Cart extends Component {

   state = {
      cartItems: [],
      prices: 0,
   };

   getProduct = async () => {
      const req = {
         userEmail: localStorage.getItem('userEmail')
      }
      /* const cartItems = await axios.post("http://localhost/bamzi/api/productCart/details", req); */
      const cartItems = await axios.post("https://bamziapi.ivyarc.com/api/productCart/details", req); 
      this.setState({ cartItems: cartItems.data }, console.log(cartItems.data));
   };


   getPrice = async () => {
      const itemsList = this.state.cartItems.length;
      let totalPrice = 0;
      console.log(itemsList)
      if (itemsList == undefined ) {
         this.setState({ prices: totalPrice });
      } else {
         this.state.cartItems.forEach(product => {
            totalPrice += product.productPrice;
            console.log(totalPrice)
         });
         console.log(totalPrice);
         this.setState({ prices: totalPrice });
      }
   }

   storeCart = async()=> {
      localStorage.setItem("cart", JSON.stringify(this.state.cartItems));
      console.log(this.state.cartItems);
   }

   componentDidMount() {
      this.getProduct();
      setTimeout(() => {
         this.getPrice();
      }, 2000);
      setTimeout(() => {
         this.storeCart();
      }, 5000);
   }


   onSubmit(cartItem) {
      // fetch("http://localhost/bamzi/api/productCart/delete", {
         fetch("https://bamziapi.ivyarc.com/api/productCart/delete", {
         method: "DELETE",
         headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
         },
         body: JSON.stringify({
            productName: cartItem.productName,
            userEmail: window.localStorage.getItem("userEmail"),
         })
      }).then((Response) => Response.json())
         .then((result) => {
            if (result.message == "success") {
               swal({
                  title: "Error!",
                  text: "Product Not In Cart.",
                  icon: 'error',
                  button: true
               });
               //this.props.history.push("/login");
            }
            else {
               swal({
                  text: "Product Removed From Cart...",
                  icon: 'success',
                  button: true
               });
               window.location.reload(true);
            }
         });
   };



   render() {
      const { cartItems } = this.state;
      const itemsList = cartItems.length;

      /* console.log(this.state);
      console.log(this.state.prices);
      this.setState({prices: this.state.prices + cartItems.productPrice})
      console.log(this.state.prices); */

      return (
         <div className={cart.Cart}>
            <div className={cart.Container}>

               {/* Navbar Section */}
               <Header
                  A1="Products" A1r="/products"
                  A2="Stores" A2r="/stores"
                  A3={<input type="text" name="searchBar" placeholder="Search anything" />}

                  B1={<span> Wishlist <IoMdHeartEmpty /> </span>}  B1r="/wishlist"
                  B2={<span> Cart <IoMdCart /></span>} B2r="/cart"
                  B3={<img src={Kemi} alt="A profile pic" />} B3r="/userDetails"
               /* B4= {loggedIn ? "Logout" : "Login"  */

                  style={{ backgroundColor: "#fff", borderBottom: "1px solid #ccc" }}

               />

               {/* Content */}

               <div className={cart.Content} >
                  <div className={cart.left}>
                     <div className={cart.cartHead}>

                        <div className={cart.cartSubTit}>
                           <div className={cart.cartTit}>
                              <div>Shopping Cart <span>{itemsList}</span> </div>

                           </div>
                           <div>
                              <span> <FiTrash2 /></span>Delete All
                           </div>
                        </div>
                     </div>

                     {cartItems.length > 0 ? cartItems.map(item => {
                        const { id, productRating, productName, description, productPrice, active, productQuantity, productSize, productColor, productOwner, productImage1, productImage2, productImage3 } = item;
                        const cartItem = { id, productRating, productName, description, productPrice, active, productQuantity, productSize, productColor, productOwner, productImage1, productImage2, productImage3 }

                        return (
                           <div className={cart.store}>
                              <div className={cart.storeTit}>
                                 <div><MdStore /> {productOwner} </div>
                              </div>


                              <div className={cart.storeProd}>

                                 <div className={cart.storeProdPicCont}>
                                    <img src={ApplePodsPic} alt="A profile pic" className={cart.storeProdPic} />

                                 </div>

                                 <div className={cart.storeProdDet}>
                                    <div className={cart.storeProdDetTit}>{productName}</div>
                                    <div className={cart.storeProdDetVar}>
                                       Color <div className={cart.storeProdSmallCol} style={{ backgroundColor: productColor }}> </div>
                                       Size <div className={cart.storeProdSmallSize}> {productSize} </div>
                                    </div>
                                    <div className={cart.storeProdDetPri}>US ${productPrice}</div>
                                    <div className={cart.storeProdDetShip}>Shipping US $18.99 all Express Delivery</div>
                                    <div className={cart.storeProdDetEst}>Estimated Delivery Time: 25 - 30 days </div>
                                 </div>

                                 <div className={cart.storeProdAct}>
                                    <div>
                                       <div> <IoMdHeart />  </div>
                                       <div onClick={() => { this.onSubmit(cartItem) }}> <FiTrash2 /> </div>
                                    </div>

                                    <div className={cart.storeProdDetQty} >
                                       <div>QTY </div>
                                       <div className={cart.storeProdSmallInc}> - </div>
                                       <div className={cart.storeProdSmallPri}> {productQuantity} </div>
                                       <div className={cart.storeProdSmallInc}> + </div>

                                    </div>

                                 </div>

                              </div>

                           </div>
                        )
                     }) :
                        <div>
                           No Item(s) In Cart
                        </div>
                     }


                  </div>



                  <div className={cart.right}>

                     <div className={cart.orderSummary}>
                        <div className={cart.orderSummaryTit}>Order Summary</div>

                        <div className={cart.breakdown}>
                           <div>
                              <div>Shipping Fee</div>
                              <div>$10.70</div>
                           </div>
                           <div>
                              <div>Sub Total</div>
                              <div>$19.00</div>
                           </div>

                        </div>

                        <div className={cart.total}>
                           <div>Total</div>
                           <div>${this.state.prices}</div>
                        </div>

                        <div className={cart.proceed}>
                        <Link className={cart.Link} to="/checkout"  onClick={() => { this.storeCart(this.state.cartItem) }}> Proceed({itemsList}) </Link>

                        </div>

                     </div>

                     <div className={cart.secure}>
                        <div className={cart.secureTit}> SECURED PAY</div>
                        <div className={cart.secureText}>
                           Payment are secured with Bamzi and additional security is ensured with Money Guaranss
                        </div>
                        <div className={cart.secureIcons}>
                           <IoMdHeart />
                           <IoMdCart />
                           <IoMdHeartEmpty />
                        </div>
                     </div>

                  </div>

               </div>


            </div>



         </div>



      )

   }

}

export default Cart;
