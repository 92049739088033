import React, { Component } from 'react';
import signupstyles from './signup.module.css';
/* import signupstyles from '../prelaunch/prelaunch.module.css'; */
import { MdStore } from "react-icons/md";
import { IoMdCart } from "react-icons/io";
import LeftImg from '../../assets/preReg/Home - 7-image.jpg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faGooglePlusG } from '@fortawesome/free-brands-svg-icons';
import Navbar from '../../components/Navbar';
import Axios from 'axios';
import swal from 'sweetalert';
import Header from '../../components/majors/Headers'





class SignUp extends Component {

    constructor() {
        super();
        this.signIn = this.signIn.bind(this);
        this.onchangeName = this.onchangeName.bind(this);
        this.onchangeEmail = this.onchangeEmail.bind(this);
        this.onchangePassword = this.onchangePassword.bind(this);
        this.customerTypeSell = this.customerTypeSell.bind(this);
        this.customerTypeBuy = this.customerTypeBuy.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            name: 'blank-field',
            email: '',
            password: '',
            customerType: "Seller",
            disabled: true
        };
    };

    onchangeName(e) {
        this.setState({
            name: e.target.value
        });
    };

    onchangeEmail(e) {
        this.setState({
            email: e.target.value
        });
    };

    onchangePassword(e) {
        this.setState({
            password: e.target.value
        });
    };

    customerTypeSell(e) {
        e.preventDefault();
        this.setState({
            customerType: "Seller"
        })
    };

    customerTypeBuy(e) {
        e.preventDefault();
        this.setState({
            customerType: "Buyer"
        })
    };

    onSubmit(event) {
        fetch("https://bamziapi.ivyarc.com/api/register", {
        /* fetch("http://localhost/bamzi/api/register", { */
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: this.state.name,
                email: this.state.email,
                password: this.state.password,
                customerType: this.state.customerType,
            })
        }).then((Response) => Response.json())
            .then((result) => {
                if (result.message == "success") {
                    swal({
                        title: "Registered!",
                        text: "Account Created Successfully..",
                        icon: 'success',
                        button: true
                    });
                    //console.log(this.state.customerType);
                    //this.props.history.push("/login")
                    if (this.state.customerType == "Seller") {
                        this.props.history.push("/soCustomize")
                    }
                    else if (this.state.customerType == "Buyer") {
                        this.props.history.push("/userDetails")
                    }
                }
                else {
                    swal({
                        title: "Error",
                        text: "User With Email Already Exist....",
                        icon: 'error',
                        button: true
                    });
                }
            })

    };


    signIn() {
        this.props.history.push("/login")
    }

    render() {

        let Custype = (
            <div className={signupstyles.btn}>
                <button className={signupstyles.btnBuy} onClick={this.customerTypeBuy} onBlur={this.checkmail} >
                    <div className={signupstyles.btnAlgn}> <IoMdCart />
                        <span className={signupstyles.btnResText}> Buyer </span>
                    </div>
                </button>

                <button className={signupstyles.btnSell} onClick={this.customerTypeSell} onBlur={this.checkmail} >
                    <div className={signupstyles.btnAlgn}> <MdStore />
                        <span className={signupstyles.btnResText}> Seller </span>
                    </div>
                </button>
            </div>
        )


        if (this.state.customerType === "Buyer") {
            Custype = (
                <div className={signupstyles.btn}>
                    <button className={signupstyles.btnSell} onClick={this.customerTypeBuy} onBlur={this.checkmail} >
                        <div className={signupstyles.btnAlgn}> <IoMdCart />
                            <span className={signupstyles.btnResText}> Buyer </span>
                        </div>
                    </button>

                    <button className={signupstyles.btnBuy} onClick={this.customerTypeSell} onBlur={this.checkmail} >
                        <div className={signupstyles.btnAlgn}> <MdStore />
                            <span className={signupstyles.btnResText}> Seller </span>
                        </div>
                    </button>
                </div>
            )

        }


        return (
            <div className={signupstyles.bodyContainer}>
                {/* Navbar Section */}
                <Header
                    A1="Products" A1r="/products"
                    A2="Stores"
                    A3="Pricing"
                    A4="Features" A4r="/features"




                />


                <div className={signupstyles.bodyContent}>

                    <div className={signupstyles.innerBodyContent}>
                        <div className={signupstyles.leftSection}>
                            <div className={signupstyles.leftContent}>
                                <div className={signupstyles.fieldSet}>
                                    <div className={signupstyles.actionBtn}>
                                        <div className={signupstyles.signin} onClick={this.signIn}> Sign In </div>
                                        <div className={signupstyles.signup}> Sign Up </div>
                                    </div>

                                    <div className={signupstyles.gmail}>
                                        <FontAwesomeIcon icon={faGooglePlusG} size='2x' /> <span> Sign Up with Gmail</span>
                                    </div>
                                    <div className={signupstyles.facebook}>
                                        <FontAwesomeIcon icon={faFacebook} size='2x' /> <span>Sign Up with Facebook</span>
                                    </div>

                                    <div className={signupstyles.preForm} >
                                        <form action=".">
                                          {/*   <div><input type="text" placeholder="Full Name" className={signupstyles.inputForm} value={this.state.name} name="name" onChange={this.onchangeName} /></div> */}
                                            <div><input type="email" placeholder="Email Address" className={signupstyles.inputForm} value={this.state.email} name="email" onChange={this.onchangeEmail} required /></div>
                                            <div><input type="password" placeholder="Password - min: 6" minLength={6} className={signupstyles.inputForm} value={this.state.password} name="password" onChange={this.onchangePassword} required /></div>

                                            {Custype}

                                        </form>
                                        <div className={signupstyles.BookBtn} type="submit" onClick={this.onSubmit}>
                                            <div>
                                                Sign Up
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className={signupstyles.rightSection}>

                            <div className={signupstyles.rightBg}>
                                <img src={LeftImg} alt="leftImage" />
                            </div>



                        </div>
                    </div>
                </div>

            </div>

        )

    }

}

export default SignUp



