import React, { Component } from 'react';
import loginstyles from './login.module.css';
import LeftImg from '../../assets/preReg/Home - 7-image.jpg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faGooglePlusG } from '@fortawesome/free-brands-svg-icons';
import Navbar from '../../components/Navbar';
import Axios from 'axios';
import swal from 'sweetalert';
import Header from '../../components/majors/Headers'
import {Link} from 'react-router-dom';


class Login extends Component {

    constructor() {
        super();
        this.state = {
            email: '',
            password: ''
        }
        this.password = this.password.bind(this);
        this.email = this.email.bind(this);
        this.login = this.login.bind(this);
        this.signUp = this.signUp.bind(this);
    }

    email(event) {
        this.setState({ email: event.target.value })
    }
    password(event) {
        this.setState({ password: event.target.value })
    }

    login(event) {
        fetch("https://bamziapi.ivyarc.com/api/login", { 
        /* fetch("http://localhost/bamzi/api/login", { */
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: this.state.email,
                password: this.state.password
            })
        }).then((Response) => Response.json())
            .then((result) => {
                if (result.message == "success") {
                    // console.log(result);
                    setTimeout(() => {
                        localStorage.setItem("token", result.token.accessToken);
                    }, 3000);
                    localStorage.setItem("userEmail", result.user.email);
                    this.props.history.push("/homepage")
                    
                }
                else {
                    swal({
                        title: "Error",
                        text: "Invalid Email/Password...",
                        icon: 'error',
                        button: true
                     });
                }
            })

    }

    signUp(){
        this.props.history.push("/signup")
    }


    render() {
        return (
            <div className={loginstyles.bodyContainer}>
                {/* Navbar Section */}
                <Header
                    A1="Products" A1r="/products"
                    A2="Stores"
                    A3="Pricing"
                    A4="Features" A4r="/features"




                />

                <div className={loginstyles.bodyContent}>

                    <div className={loginstyles.innerBodyContent}>
                        <div className={loginstyles.leftSection}>
                            <div className={loginstyles.leftContent}>
                                <div className={loginstyles.fieldSet}>
                                    <div className={loginstyles.actionBtn}>
                                        <div className={loginstyles.signin}> Sign In </div>
                                        <div className={loginstyles.signup} onClick={this.signUp}> Sign Up </div>
                                    </div>

                                    <div className={loginstyles.gmail}>
                                        <FontAwesomeIcon icon={faGooglePlusG} size='2x' /> <span> Sign Up with Gmail</span>
                                    </div>
                                    <div className={loginstyles.facebook}>
                                        <FontAwesomeIcon icon={faFacebook} size='2x' /> <span>Sign Up with Facebook</span>
                                    </div>

                                    <div className={loginstyles.preForm} >
                                        <form >
                                            <div><input type="email" placeholder="Email Address" className={loginstyles.inputForm} onChange={this.email} /></div>
                                            <div><input type="password" placeholder="Password" className={loginstyles.inputForm} onChange={this.password} /></div>
                                            <div className={loginstyles.BookBtn}>
                                                <div onClick={this.login}>
                                                    Sign In
                                                </div>
                                                <span className={loginstyles.password}><Link to="/verifyMail">Forgotten Password?</Link></span>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className={loginstyles.rightSection}>

                            <div className={loginstyles.rightBg}>
                                <img src={LeftImg} alt="leftImage" />
                            </div>

                        </div>

                    </div>


                </div>
            </div>


        )

    }

}

export default Login



