import React, { Component, useState, useEffect } from 'react'
import SOAddProduct from './SOAddProduct.module.css'
import Footer from '../../components/majors/Footer'
import Filter from '../../components/majors/Filter'
import StoreProducts from '../../components/majors/StoreProducts'
import { FiBell, FiHelpCircle, FiGrid, FiCircle, FiHome, FiMail, FiBox } from "react-icons/fi";
import { BsCreditCard, BsEyeSlash } from "react-icons/bs";
import { VscAccount, VscBellDot } from "react-icons/vsc";
import { FaRegCalendarAlt, FaStore } from "react-icons/fa";
import { IoMdCart, IoMdHeart, IoMdPeople } from "react-icons/io";
import { AiFillBank } from "react-icons/ai";
import { MdAddShoppingCart } from "react-icons/md";
import Kemi from '../../assets/prodItems/Kemi.jpg'
import Trophy from '../../assets/sellerDashboard/trophy.png'
import { CgMenuBoxed } from "react-icons/cg";
import { Link } from 'react-router-dom'
import swal from 'sweetalert'

const StoreOwnersAddProduct = props => {
   const [productName, setproductName] = useState('')
   const [productPrice, setproductPrice] = useState('')
   const [productRating, setproductRating] = useState('')
   const [description, setdescription] = useState('')
   const [active, setactive] = useState('1')
   const [productImage1, setproductImage1] = useState('')
   const [productImage2, setproductImage2] = useState('')
   const [productImage3, setproductImage3] = useState('')
   const [productCategory, setproductCategory] = useState('')
   const [productQuantity, setproductQuantity] = useState('')
   const [productSize, setproductSize] = useState('')
   const [productColor, setproductColor] = useState(null)
   const [productOwner, setproductOwner] = useState('')

   async function storeProduct() {
      // const shopData = { shopName, employeeName, email, address, city, state, country, postalCode, description, shopOwnerId, phoneNumber }
      fetch("https://bamziapi.ivyarc.com/api/products", {
      /* fetch("http://localhost/bamzi/api/products", { */
         method: "POST",
         headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
         },
         body: JSON.stringify({
            productName: productName,
            productPrice: productPrice,
            productRating: productRating,
            description: description,
            active: active,
            productImage1: productImage1,
            productImage2: productImage2,
            productImage3: productImage3,
            productCategory: productCategory,
            productQuantity: productQuantity,
            productSize: productSize,
            productColor: productColor,
            productOwner: productOwner,
         })
      }).then((Response) => Response.json())
         .then((result) => {
            console.log(result);
            if (result.message == "success") {
               swal({
                  title: "Created!",
                  text: " Product Added Successfully...",
                  icon: 'success',
                  button: true
               });
            }
            else {
               swal({
                  title: "Error!",
                  text: "Please Fill All Fields...",
                  icon: 'error',
                  button: true
               });
            }
         });
   }


   const handleSubmit = e => {
      e.preventDefault()
      storeProduct()
   }

   const colorSet = value => {
      console.log(value)
      setproductColor(value)
   }

   const [menuappear, setMenuappear] = useState(false);
   const [width, setWidth] = useState(window.innerWidth);

   const updateWidth = () => {
      setWidth(window.innerWidth);
   }

   useEffect(() => {
      window.addEventListener("resize", updateWidth);
      return () => window.removeEventListener("resize", updateWidth);
   })

   const menuShow = () => {
      setMenuappear(!menuappear)
   }






   let smallSize = <div> <CgMenuBoxed onClick={menuShow} className={SOAddProduct.menuButton} style={{ fontSize: "2.5em", color: "#000033" }} /> </div>

   let Menu = <div className={SOAddProduct.leftCent} onClick={menuShow}>
      <div className={SOAddProduct.Title}><Link to="/homepage" className={SOAddProduct.Link}> BAM<span className={SOAddProduct.ZI}>ZI</span> </Link></div>

      <div className={SOAddProduct.Section1}>
         <div> <Link to='/soDashboard' className={SOAddProduct.Link}> <span><FiHome /></span>  Dashboard </Link> </div>
         <div> <span><FiCircle /></span> Analytics </div>
         <div>  <Link to='/soMarketing' className={SOAddProduct.Link}> <span><FiCircle /></span> Marketing </Link> </div>
      </div>


      <div className={SOAddProduct.Section2}>
         <div className={SOAddProduct.SecTitle}  >PRODUCTS</div>

         <div>  <Link to='/storeOwnersStore' className={SOAddProduct.Link}> <span><FiBox /></span> All Products </Link> </div>
         <div> <Link to='/soAddProduct' className={SOAddProduct.Link}> <span><MdAddShoppingCart /></span> Add Products </Link> </div>
         <div> <Link to='/soSales' className={SOAddProduct.Link}> <span><IoMdCart /></span> Sales </Link></div>
         <div> <span><FiGrid /></span> Categories </div>
      </div>

      <div className={SOAddProduct.Section3}>
         <div className={SOAddProduct.SecTitle}  >Transactions </div>
         <div> <Link to='/soHistory' className={SOAddProduct.Link}><span><FaRegCalendarAlt /></span> History </Link></div>
         <div> <span><BsCreditCard /></span> Billings </div>
      </div>

      <div className={SOAddProduct.Section4}>
         <div className={SOAddProduct.SecTitle}  >PRODUCTS </div>
         <div> <span><VscAccount /></span> Account </div>
         <div> <Link to='/soCustomize' className={SOAddProduct.Link}> <span><FiBox /></span> Customise Shop </Link> </div>
         <div> <span><FiHelpCircle /></span> Help </div>
      </div>

   </div>

   if (menuappear) {
      smallSize = Menu
   }

   return (
      <div className={SOAddProduct.StoreOwnersStore}>
         <div className={SOAddProduct.Container}>

            <div className={SOAddProduct.left}>
               {width > 860 ? Menu : smallSize}
            </div>


            <div className={SOAddProduct.Right}>
               <div className={SOAddProduct.storeOwnersTopTab}>

                  <div className={SOAddProduct.NavList} >
                     <div>
                        <ul className={SOAddProduct.NavList1} >
                           <li><div> <AiFillBank /> Account: $12,990 </div></li>
                           <li><BsEyeSlash /></li>
                           <li><FiBell /></li>
                           <li><FiMail /></li>
                        </ul>
                     </div>

                     <div>
                        <ul className={SOAddProduct.NavList2} >
                           <li className={SOAddProduct.liFlex}> <FaStore /> </li>
                           <li className={SOAddProduct.liFlex}> Evans Bex Electronics Store </li>
                           <li> <img src={Kemi} alt="A profile pic" /> </li>
                        </ul>
                     </div>
                  </div>

               </div>





               <div className={SOAddProduct.addProduct}>
                  <div className={SOAddProduct.addProductCont}>




                     <div className={SOAddProduct.tit}>ADD PRODUCT</div>

                     <form onSubmit={handleSubmit}>
                        <label> Product Name:  <input type="text" placeholder="required" required name={productName} onChange={e => setproductName(e.target.value)} value={productName} /> </label>

                        <label> Product Price:  <input type="number" placeholder="required" required name={productPrice} onChange={e => setproductPrice(e.target.value)} value={productPrice} /> </label>

                        <label> Product Description:  <textarea placeholder="required" required rows={5} cols={50} name={description} onChange={e => setdescription(e.target.value)} value={description} /> </label>

                        <div>
                           <label> Product Rating:  <input type="number" placeholder="required" required name={productRating} onChange={e => setproductRating(e.target.value)} value={productRating} /> </label>

                           <label> Product Availability:
                                 <select name={active} value={active} onChange={e => setactive(e.target.value)}>
                                 <option value="1">In Stock</option>
                                 <option value="0">Out of Stock</option>
                              </select>
                           </label>
                        </div>

                        <div>
                           <label> Product Image 1:  <input type="text" placeholder="required - enter url" required name={productImage1} onChange={e => setproductImage1(e.target.value)} value={productImage1} /> </label>

                           <label> Product Image 2:  <input type="text" placeholder="optional" name={productImage2} onChange={e => setproductImage2(e.target.value)} value={productImage2} /> </label>

                           <label> Product Image 3:  <input type="text" placeholder="optional" name={productImage3} onChange={e => setproductImage3(e.target.value)} value={productImage3} /> </label>
                        </div>

                        <div>
                           <label> Product Category:  <input type="text" placeholder="required" required name={productCategory} onChange={e => setproductCategory(e.target.value)} value={productCategory} /> </label>

                           <label> Product Quantity:  <input type="number" placeholder="required" required name={productQuantity} onChange={e => setproductQuantity(e.target.value)} value={productQuantity} /> </label>

                           <label> Product Size:  <input type="text" placeholder="required" required name={productSize} onChange={e => setproductSize(e.target.value)} value={productSize} /> </label>
                        </div>

                        <div>
                           <label> Product Color:<input type="color" required name={productColor} value={productColor} onChange={e => setproductColor(e.target.value)} /> </label>
                              
                           <label> Product Owner:  <input type="text" placeholder="required" required name={productOwner} onChange={e => setproductOwner(e.target.value)} value={productOwner} /> </label>
                        </div>

                        <button type="submit" onClick={handleSubmit}> Save Product</button>
                     </form>






                  </div>
               </div>
            </div>

         </div >
      </div >


   )

}


export default StoreOwnersAddProduct;
