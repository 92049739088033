import React, { Component } from 'react'
import classes from './CoverImage.module.css';
import ComputerPic from '../../assets/prodItems/computer.jpg';
import { IoMdHeart, IoMdCart, IoMdHeartEmpty, IoMdMenu } from "react-icons/io";
import axios from 'axios'



var pageURL = decodeURIComponent(window.location.href);
var splitURL = pageURL.split('/');
var storeName = splitURL[4]
console.log(storeName)

class CoverImage extends Component {
   state = {
      storeProduct: [],
      disabled: false
   };

   getProduct = async () => {
      const req = {
         storeName: storeName
      }
      /* const storeProduct = await axios.post("http://localhost/bamzi/public/api/shops/details", req); */
      const storeProduct = await axios.post("https://bamziapi.ivyarc.com/api/shops/details", req);
      this.setState({ storeProduct: storeProduct.data });
      console.log(storeProduct.data)
   };

   
   componentDidMount() {
      this.getProduct();
   }

   render() {

      const {storeProduct} = this.state;
      console.log(storeProduct.data)

      return (

         <div className={classes.coverImage}>
            <div className={classes.storeCaption}>
               <div className={classes.storeImageCont}>
                  <img src={ComputerPic} alt="A profile pic" className={classes.storeImage} />
               </div>

               <div className={classes.captText}>
                  <div> {storeName} Store </div>
                  <span>
                     <small>{storeProduct.description}</small>
                  </span>

               </div>

               <div className={classes.captIcon}><IoMdHeart /></div>
            </div>
         </div>


      )

   }

}

export default CoverImage;
