import React, { Component } from 'react'
import userOrds from './UserOrders.module.css';
import Header from '../../../components/majors/Headers'
import Kemi from '../../../assets/prodItems/Kemi.jpg'
import ApplePodsPic from '../../../assets/prodItems/applePods.png';
import AppleWatchPic from '../../../assets/prodItems/appleWatch.png';
import Saloon from '../../../assets/prodItems/saloon.jpg';
import { FiGrid, FiTrash2 } from "react-icons/fi";
import { IoMdHeart, IoMdCart, IoMdHeartEmpty, IoMdMenu } from "react-icons/io";
import { VscAccount } from "react-icons/vsc";
import { RiShoppingBag3Fill } from "react-icons/ri";
import { FaShippingFast } from "react-icons/fa"
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';


class UserOrders extends Component {


   render() {

      return (
         <div className={userOrds.Details}>
            <div className={userOrds.Container}>

               {/* Navbar Section */}
               <Header
                  A1="Products" A1r="/products"
                  A2="Stores" A2r="/stores"
                  A3={<input type="text" name="searchBar" placeholder="Search anything" />}

                  B1={<span> Wishlist <IoMdHeartEmpty /> </span>}  B1r="/wishlist"
                  B2={<span> Cart <IoMdCart /></span>} B2r="/cart"
                  B3={<img src={Kemi} alt="A profile pic" />} B3r="/userDetails"
               /* B4= {loggedIn ? "Logout" : "Login"  */

                  style={{ backgroundColor: "#fff", borderBottom: "1px solid #ccc" }}

               />

               {/* Content */}

               <div className={userOrds.Content} >

                  <div className={userOrds.left}>

                  <div className={userOrds.detailsInfo}> <Link to='/userDetails' className={userOrds.Link}> <span><VscAccount /></span> Personal Details </Link> </div>
                     <div className={userOrds.orderInfo}> <span><RiShoppingBag3Fill /></span> Orders </div>
                     <div className={userOrds.addressInfo}> <Link to='/userAddress' className={userOrds.Link}> <span><FaShippingFast /></span> Shipping Address </Link></div>

                  </div>

                  <div className={userOrds.right}>

                     <div className={userOrds.accountInfo}>

                        Orders
                        
                        <div className={userOrds.storeProd}>

                           <div className={userOrds.storeProdPicCont}>
                              <img src={ApplePodsPic} alt="A profile pic" className={userOrds.storeProdPic} />

                           </div>
                           
                           <div className={userOrds.storeProdDetCont}> 
                           <div className={userOrds.storeProdDet}>
                              <div className={userOrds.storeProdDetTit}>Apple Pods v4.0</div>
                              <div className={userOrds.storeProdDetPri}>
                                 <div> US $399</div>
                                 <div>Quantity: 1</div>
                              </div>
                           </div>

                           <div className={userOrds.storeProdAct}>
                              <div className={userOrds.storeProdActDate}>
                                 <div> 01/02/2021  </div>
                                 <div> Delivery Confirmed </div>
                              </div>

                              <div className={userOrds.storeProdDetQty} >
                                 View Details
                              </div>

                           </div>
                           </div>

                        </div>

                        <div className={userOrds.storeProd}>

                           <div className={userOrds.storeProdPicCont}>
                              <img src={AppleWatchPic} alt="A profile pic" className={userOrds.storeProdPic} />

                           </div>
                           
                           <div className={userOrds.storeProdDetCont}> 
                           <div className={userOrds.storeProdDet}>
                              <div className={userOrds.storeProdDetTit}>Apple Watch 2.0 Ipv4</div>
                              <div className={userOrds.storeProdDetPri}>
                                 <div> US $399</div>
                                 <div>Quantity: 1</div>
                              </div>
                           </div>

                           <div className={userOrds.storeProdAct}>
                              <div className={userOrds.storeProdActDate}>
                                 <div> 01/02/2021  </div>
                                 <div> Delivery Confirmed </div>
                              </div>

                              <div className={userOrds.storeProdDetQty} >
                                 View Details
                              </div>

                           </div>
                           </div>

                        </div>

                        <div className={userOrds.storeProd}>

                           <div className={userOrds.storeProdPicCont}>
                              <img src={Saloon} alt="A profile pic" className={userOrds.storeProdPic} />

                           </div>
                           
                           <div className={userOrds.storeProdDetCont}> 
                           <div className={userOrds.storeProdDet}>
                              <div className={userOrds.storeProdDetTit}>Weaving Fibre set of wigs 12cm length silk</div>
                              <div className={userOrds.storeProdDetPri}>
                                 <div> US $399</div>
                                 <div>Quantity: 1</div>
                              </div>
                           </div>

                           <div className={userOrds.storeProdAct}>
                              <div className={userOrds.storeProdActDate}>
                                 <div> 01/02/2021  </div>
                                 <div> Delivery Confirmed </div>
                              </div>

                              <div className={userOrds.storeProdDetQty} >
                                 View Details
                              </div>

                           </div>
                           </div>

                        </div>


                     </div>

                  </div>


               </div>

            </div>

         </div>



      )

   }

}

export default UserOrders;
