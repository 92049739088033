import React, { Component, useState, useEffect } from 'react'
import SOMarketing from './SOMarketing.module.css'
import Footer from '../../components/majors/Footer'
import Filter from '../../components/majors/Filter'
import StoreProducts from '../../components/majors/StoreProducts'
import { FiBell, FiHelpCircle, FiGrid, FiCircle, FiHome, FiMail, FiBox } from "react-icons/fi";
import { BsCreditCard, BsEyeSlash } from "react-icons/bs";
import { VscAccount, VscBellDot } from "react-icons/vsc";
import { FaRegCalendarAlt, FaStore } from "react-icons/fa";
import { IoMdCart, IoMdHeart, IoMdPeople } from "react-icons/io";
import { AiFillBank } from "react-icons/ai";
import { MdAddShoppingCart } from "react-icons/md";
import Kemi from '../../assets/prodItems/Kemi.jpg'
import Trophy from '../../assets/sellerDashboard/trophy.png'
import { CgMenuBoxed } from "react-icons/cg";
import {Link } from 'react-router-dom'


const StoreOwnersMarketing = props => {


   const [menuappear, setMenuappear] = useState(false);
   const [width, setWidth] = useState(window.innerWidth);

   const updateWidth = () => {
      setWidth(window.innerWidth);
   }

   useEffect(() => {
      window.addEventListener("resize", updateWidth);
      return () => window.removeEventListener("resize", updateWidth);
   })

   const menuShow = () => {
      setMenuappear(!menuappear)
   }






   let smallSize = <div> <CgMenuBoxed onClick={menuShow} className={SOMarketing.menuButton} style={{ fontSize: "2.5em", color: "#000033" }} /> </div>

   let Menu = <div className={SOMarketing.leftCent} onClick={menuShow}>
      <div className={SOMarketing.Title}><Link to="/homepage" className={SOMarketing.Link}> BAM<span className={SOMarketing.ZI}>ZI</span> </Link></div>

      <div className={SOMarketing.Section1}>
         <div> <Link to='/soDashboard' className={SOMarketing.Link}> <span><FiHome /></span>  Dashboard </Link> </div>
         <div> <span><FiCircle /></span> Analytics </div>
         <div>  <Link to='/soMarketing' className={SOMarketing.Link}> <span><FiCircle /></span> Marketing </Link> </div>
      </div>


      <div className={SOMarketing.Section2}>
         <div className={SOMarketing.SecTitle}  >PRODUCTS</div>
         
         <div>  <Link to='/storeOwnersStore' className={SOMarketing.Link}> <span><FiBox /></span> All Products </Link> </div>
         <div> <Link to='/soAddProduct' className={SOMarketing.Link}> <span><MdAddShoppingCart /></span> Add Products </Link> </div>
         <div> <span><IoMdCart /></span> Sales </div>
         <div> <span><FiGrid /></span> Categories </div>
      </div>

      <div className={SOMarketing.Section3}>
         <div className={SOMarketing.SecTitle}  >Transactions</div>
         <div> <span><FaRegCalendarAlt /></span> History </div>
         <div> <span><BsCreditCard /></span> Billings </div>
      </div>

      <div className={SOMarketing.Section4}>
         <div className={SOMarketing.SecTitle}  >PRODUCTS</div>
         <div> <span><VscAccount /></span> Account </div>
         <div> <Link to='/soCustomize' className={SOMarketing.Link}> <span><FiBox /></span> Customise Shop </Link> </div>
         <div> <span><FiHelpCircle /></span> Help </div>
      </div>

   </div>

   if (menuappear) {
      smallSize = Menu
   }

   return (
      <div className={SOMarketing.StoreOwnersStore}>
         <div className={SOMarketing.Container}>

            <div className={SOMarketing.left}>
               {width > 860 ? Menu : smallSize}
            </div>


            <div className={SOMarketing.Right}>
               <div className={SOMarketing.storeOwnersTopTab}>

                  <div className={SOMarketing.NavList} >
                     <div>
                        <ul className={SOMarketing.NavList1} >
                           <li><div> <AiFillBank /> Account: $12,990 </div></li>
                           <li><BsEyeSlash /></li>
                           <li><FiBell /></li>
                           <li><FiMail /></li>
                        </ul>
                     </div>

                     <div>
                        <ul className={SOMarketing.NavList2} >
                           <li className={SOMarketing.liFlex}> <FaStore /> </li>
                           <li className={SOMarketing.liFlex}> Evans Bex Electronics Store </li>
                           <li> <img src={Kemi} alt="A profile pic" /> </li>
                        </ul>
                     </div>
                  </div>

               </div>





               <div className={SOMarketing.Marketing}>
                  <div className={SOMarketing.MarketingCont}>

                     <div className={SOMarketing.marketingTit}> Marketing </div>
                     <div className={SOMarketing.marketingSubTit}> Every product needs a customer. Use the best marketing practice to acquire new customers, raise the average order value and keep buyers coming back for more. </div>

                     <div className={SOMarketing.traffic}>
                        <div className={SOMarketing.moreTraffic}>
                           <div className={SOMarketing.moreTrafficTit}> Get more traffic </div>
                           <div className={SOMarketing.moreTrafficSubTit}> Start an advertising campaign to reach your target audience and get more traffic into your store. </div>
                        </div>

                        <div className={SOMarketing.trafficMeans}>

                           <div className={SOMarketing.google}>
                              <div className={SOMarketing.image}>
                                 <img src={Trophy} alt="dummyPic" />
                              </div>
                              <div className={SOMarketing.cent}>
                                 <div className={SOMarketing.centCent}>
                                    <div className={SOMarketing.centCentTit}>Advertise across Google</div>
                                    <div className={SOMarketing.centCentText}> Advertise your products to people who have displayed interest in similar products. Launch an automated ad campaign in 5 minutes right from your store's dashboard.</div>
                                 </div>
                                 <div className={SOMarketing.getStarted}> Get Started </div>
                              </div>
                           </div>

                           <div className={SOMarketing.facebook}>
                              <div className={SOMarketing.image}>
                                 <img src={Trophy} alt="dummyPic" />
                              </div>
                              <div className={SOMarketing.cent}>
                                 <div className={SOMarketing.centCent}>
                                    <div className={SOMarketing.centCentTit}>Promote and sell on Facebook</div>
                                    <div className={SOMarketing.centCentText}> Connect with Facebook to get your very own Facebook Shop and launch an ad campaign to show your products in newsfeed of potential customers. </div>
                                 </div>
                                 <div className={SOMarketing.getStarted}> Get Started </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className={SOMarketing.customers}>
                        <div className={SOMarketing.loyal}>
                           <div className={SOMarketing.loyalTit}> Make customers loyal </div>
                           <div className={SOMarketing.loyalSubTit}> Engage past customers to build loyalty and have them return as repeat buyers. </div>
                        </div>
                        <div className={SOMarketing.loyalMeans}>

                           <div className={SOMarketing.retain}>
                              <div className={SOMarketing.image}>
                                 <img src={Trophy} alt="dummyPic" />
                              </div>
                              <div className={SOMarketing.cent}>
                                 <div className={SOMarketing.centCent}>
                                    <div className={SOMarketing.centCentTit}> Retain customers with automated emails </div>
                                    <div className={SOMarketing.centCentText}> Automated marketing emails remind customers of the products they liked, offer related goods, personal discounts, and more. The emails are sent in response to customers actions and timely encourage them to come back and buy. </div>
                                 </div>
                                 <div className={SOMarketing.getStarted}> Get Started </div>
                              </div>
                           </div>

                           <div className={SOMarketing.engage}>
                              <div className={SOMarketing.image}>
                                 <img src={Trophy} alt="dummyPic" />
                              </div>
                              <div className={SOMarketing.cent}>
                                 <div className={SOMarketing.centCent}>
                                    <div className={SOMarketing.centCentTit}> Engage customers with email newsletter </div>
                                    <div className={SOMarketing.centCentText}> Send out newsletter promoting new arrivals, discounts and special offers to convert first-time customers to repeat buyers.  </div>
                                 </div>
                                 <div className={SOMarketing.getStarted}> Get Started </div>
                              </div>
                           </div>

                        </div>
                     </div>



                  </div>
               </div>
            </div>

         </div>
      </div>


   )

}


export default StoreOwnersMarketing;
