import React, { Component, setState } from 'react'
import ProdRev from './ProdReviews.module.css';
import Header from '../../components/majors/Headers'
import CoverImage from '../../components/majors/CoverImage'
import Footer from '../../components/majors/Footer'
import Kemi from '../../assets/prodItems/Kemi.jpg'
import ApplePodsPic from '../../assets/prodItems/applePods.png';
import AppleWatchPic from '../../assets/prodItems/appleWatch.png';
import IphonePic from '../../assets/prodItems/iPhone.png';
import LaptopPic from '../../assets/prodItems/laptop.jpg';
import Saloon from '../../assets/prodItems/saloon.jpg';
import { FiSearch } from "react-icons/fi";
import { IoMdHeart, IoMdCart, IoMdHeartEmpty, IoMdMenu } from "react-icons/io";
import { BsCreditCard, BsEyeSlash } from "react-icons/bs";
import { FiGrid, FiTrash2 } from "react-icons/fi";
import { MdStore, MdStar } from "react-icons/md";
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';



class ProdReviews extends Component {


   render() {

      return (
         <div className={ProdRev.ProdReviews}>
            <div className={ProdRev.Container}>


               {/* Content */}

               <div className={ProdRev.Content}>
                  
                  <div className={ProdRev.head}>
                     <div className={ProdRev.customerRev}>Customer's Reviews (1000)</div>
                     <div className={ProdRev.avgRatings}> <MdStar /> <MdStar /> <MdStar /> <MdStar /> <MdStar /> </div>
                     <div className={ProdRev.avgRatingNum}> 4.9 </div>
                     <div className={ProdRev.verifiedSeller}> Verified Seller</div>
                     <div className={ProdRev.report}> Report Item</div>
                  </div>

                  <div className={ProdRev.revCont}>
                     <div className={ProdRev.revLeft}>
                        <div className={ProdRev.revPic}>
                           <img src={Kemi} alt="a reviewers pic" />
                        </div>
                        
                        <div className={ProdRev.revNameDate}>
                           <div className={ProdRev.revName}>Omolola Daniel</div>
                           <div className={ProdRev.revDate}>3/12/2020</div>
                        </div>

                     </div>

                     <div className={ProdRev.revRight}>
                        <div className={ProdRev.ratings}>  <MdStar /> <MdStar /> <MdStar /> <MdStar /> <MdStar /> </div>
                        <div className={ProdRev.revText}> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquid dolores eligendi consequuntur qui praesentium quaerat sunt impedit eos quisquam tempora, sapiente ullam earum ipsum velit! Ut vitae deserunt voluptatum ipsam?</div>
                     </div>
                  </div>

                  <div className={ProdRev.revCont}>
                     <div className={ProdRev.revLeft}>
                        <div className={ProdRev.revPic}>
                           <img src={Kemi} alt="a reviewers pic" />
                        </div>
                        
                        <div className={ProdRev.revNameDate}>
                           <div className={ProdRev.revName}>Omolola Daniel</div>
                           <div className={ProdRev.revDate}>3/12/2020</div>
                        </div>

                     </div>

                     <div className={ProdRev.revRight}>
                        <div className={ProdRev.ratings}>  <MdStar /> <MdStar /> <MdStar /> <MdStar /> <MdStar /> </div>
                        <div className={ProdRev.revText}> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquid dolores eligendi consequuntur qui praesentium quaerat sunt impedit eos quisquam tempora, sapiente ullam earum ipsum velit! Ut vitae deserunt voluptatum ipsam?</div>
                     </div>
                  </div>

                  <div className={ProdRev.revCont}>
                     <div className={ProdRev.revLeft}>
                        <div className={ProdRev.revPic}>
                           <img src={Kemi} alt="a reviewers pic" />
                        </div>
                        
                        <div className={ProdRev.revNameDate}>
                           <div className={ProdRev.revName}>Omolola Daniel</div>
                           <div className={ProdRev.revDate}>3/12/2020</div>
                        </div>

                     </div>

                     <div className={ProdRev.revRight}>
                        <div className={ProdRev.ratings}>  <MdStar /> <MdStar /> <MdStar /> <MdStar /> <MdStar /> </div>
                        <div className={ProdRev.revText}> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquid dolores eligendi consequuntur qui praesentium quaerat sunt impedit eos quisquam tempora, sapiente ullam earum ipsum velit! Ut vitae deserunt voluptatum ipsam?</div>
                     </div>
                  </div>


                  <div className={ProdRev.revCont}>
                     <div className={ProdRev.revLeft}>
                        <div className={ProdRev.revPic}>
                           <img src={Kemi} alt="a reviewers pic" />
                        </div>
                        
                        <div className={ProdRev.revNameDate}>
                           <div className={ProdRev.revName}>Omolola Daniel</div>
                           <div className={ProdRev.revDate}>3/12/2020</div>
                        </div>

                     </div>

                     <div className={ProdRev.revRight}>
                        <div className={ProdRev.ratings}>  <MdStar /> <MdStar /> <MdStar /> <MdStar /> <MdStar /> </div>
                        <div className={ProdRev.revText}> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquid dolores eligendi consequuntur qui praesentium quaerat sunt impedit eos quisquam tempora, sapiente ullam earum ipsum velit! Ut vitae deserunt voluptatum ipsam?</div>
                     </div>
                  </div>

                  <div className={ProdRev.revCont}>
                     <div className={ProdRev.revLeft}>
                        

                     </div>

                     <div className={ProdRev.revRight}>
                        <div className={ProdRev.write}>
                           Write your review
                        </div>
                     </div>
                  </div>


                  
                  
               </div>


            </div>



         </div>



      )

   }

}

export default ProdReviews;
