import React, { Component } from 'react';
import Slider from 'infinite-react-carousel';
import Carousel from './Carousel.module.css';
import Carousel1 from '../../assets/features/ElectronicsRUs.jpg';
import Carousel2 from '../../assets/features/Carousel2.png';
import Carousel3 from '../../assets/features/Carousel3.jpg';
import Carousel4 from '../../assets/features/WorkSchoolHome.jpg';



class CustomSlider extends Component {


   render() {
      const settings = {
         arrowsBlock: false,
         autoplay: true,
         className: Carousel.size,
         duration: 10
         /* shift: 5 */
         /* centerMode: true, */
      };

      return (
         <div >
            {/* <span>CustomSlider</span> */}
            <Slider {...settings} >
               <div  className={Carousel.CarCont} >
                  <img src={Carousel1} alt="Carousel Pic" className={Carousel.CarSize} />
               </div>
               <div  className={Carousel.CarCont} >
                  <img src={Carousel2} alt="Carousel Pic" className={Carousel.CarSize} />
               </div>
               <div  className={Carousel.CarCont} >
                  <img src={Carousel3} alt="Carousel Pic" className={Carousel.CarSize} />
               </div>
               <div  className={Carousel.CarCont} >
                  <img src={Carousel4} alt="Carousel Pic" className={Carousel.CarSize} />
               </div>
            </Slider>
         </div>
      );
   }
}

export default CustomSlider;