import React, { Component, useState, useEffect } from 'react'
import SOStore from './StoreOwnersStore.module.css'
import Footer from '../../components/majors/Footer'
import Filter from '../../components/majors/Filter'
import StoreProducts from '../../components/majors/StoreProducts'
import { FiBell, FiHelpCircle, FiGrid, FiCircle, FiHome, FiMail, FiBox } from "react-icons/fi";
import { BsCreditCard, BsEyeSlash } from "react-icons/bs";
import { VscAccount } from "react-icons/vsc";
import { FaRegCalendarAlt, FaStore } from "react-icons/fa";
import { IoMdCart } from "react-icons/io";
import { AiFillBank } from "react-icons/ai";
import { MdAddShoppingCart } from "react-icons/md";
import Kemi from '../../assets/prodItems/Kemi.jpg'
import { CgMenuBoxed } from "react-icons/cg";
import { Link } from 'react-router-dom'
import axios from 'axios'


const StoreOwnersStore = props => {
   const [product, setProducts] = useState('')
   const Name = localStorage.getItem("shopName")

   async function storeProduct() {
      fetch("https://bamziapi.ivyarc.com/api/shops/products", {
      /* fetch("http://localhost/bamzi/api/shops/products", { */
         method: "POST",
         headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
         },
         body: JSON.stringify({
            storeName: localStorage.getItem("shopName"),
         })
      }).then((Response) => Response.json())
         .then((result) => {
            if (result) {
               console.log(result);
               setProducts(result);
               setTimeout(() => {
                  console.log(product);
               }, 2000);
            }
            else {
               console.log('error')
            }
         });
   }

   useEffect(() => {
      storeProduct();
   }, []);



   const [menuappear, setMenuappear] = useState(false);
   const [width, setWidth] = useState(window.innerWidth);

   const updateWidth = () => {
      setWidth(window.innerWidth);
   }

   useEffect(() => {
      window.addEventListener("resize", updateWidth);
      return () => window.removeEventListener("resize", updateWidth);
   })

   const menuShow = () => {
      setMenuappear(!menuappear)
   }






   let smallSize = <div> <CgMenuBoxed onClick={menuShow} className={SOStore.menuButton} style={{ fontSize: "2.5em", color: "#000033" }} /> </div>

   let Menu = <div className={SOStore.leftCent} onClick={menuShow}>
      <div className={SOStore.Title}><Link to="/homepage" className={SOStore.Link}> BAM<span className={SOStore.ZI}>ZI</span> </Link></div>

      <div className={SOStore.Section1}>
         <div> <Link to='/soDashboard' className={SOStore.Link}> <span><FiHome /></span>  Dashboard </Link> </div>
         <div> <span><FiCircle /></span> Analytics </div>
         <div>  <Link to='/soMarketing' className={SOStore.Link}> <span><FiCircle /></span> Marketing </Link> </div>
      </div>


      <div className={SOStore.Section2}>
         <div className={SOStore.SecTitle}  >PRODUCTS</div>

         <div>  <Link to='/storeOwnersStore' className={SOStore.Link}> <span><FiBox /></span> All Products </Link> </div>
         <div> <Link to='/soAddProduct' className={SOStore.Link}> <span><MdAddShoppingCart /></span> Add Products </Link> </div>
         <div> <Link to='/soSales' className={SOStore.Link}>  <span><IoMdCart /></span> Sales </Link></div>
         <div> <span><FiGrid /></span> Categories </div>
      </div>

      <div className={SOStore.Section3}>
         <div className={SOStore.SecTitle}  >Transactions</div>
         <div> <Link to='/soHistory' className={SOStore.Link}>  <span><FaRegCalendarAlt /></span> History </Link></div>
         <div> <span><BsCreditCard /></span> Billings </div>
      </div>

      <div className={SOStore.Section4}>
         <div className={SOStore.SecTitle}  >PRODUCTS</div>
         <div> <span><VscAccount /></span> Account </div>
         <div> <Link to='/soCustomize' className={SOStore.Link}> <span><FiBox /></span> Customise Shop </Link> </div>
         <div> <span><FiHelpCircle /></span> Help </div>
      </div>

   </div>

   if (menuappear) {
      smallSize = Menu
   }

   return (
      <div className={SOStore.StoreOwnersStore}>
         <div className={SOStore.Container}>

            <div className={SOStore.left}>
               {width > 860 ? Menu : smallSize}
            </div>


            <div className={SOStore.Right}>
               <div className={SOStore.storeOwnersTopTab}>

                  <div className={SOStore.NavList} >
                     <div>
                        <ul className={SOStore.NavList1} >
                           <li><div> <AiFillBank /> Account: $12,990 </div></li>
                           <li><BsEyeSlash /></li>
                           <li><FiBell /></li>
                           <li><FiMail /></li>
                        </ul>
                     </div>

                     <div>
                        <ul className={SOStore.NavList2} >
                           <li className={SOStore.liFlex}> <FaStore /> </li>
                           <li className={SOStore.liFlex}> Evans Bex Electronics Store </li>
                           <li> <img src={Kemi} alt="A profile pic" /> </li>
                        </ul>
                     </div>
                  </div>

               </div>





               <div className={SOStore.SpecStore}>
                  <div className={SOStore.SpecStoreCont}>


                     <Filter />


                     <StoreProducts />

                  </div>
               </div>
            </div>

         </div>
      </div>


   )

}


export default StoreOwnersStore
