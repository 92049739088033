import React, { Component } from 'react';
import error404 from './error404.module.css';
import LeftImg from '../../assets/preReg/Home - 7-image.jpg';
import { BsExclamationTriangleFill } from "react-icons/bs";
import {Link} from 'react-router-dom';
import Header from '../../components/majors/Headers'


class Error404 extends Component {
   render() {
      return (
         <div className={error404.bodyContainer}>
            
            <Header />
   
            <div className={error404.bodyContent} >

                  <div className={error404.innerBodyContent} >
                     <div className={error404.leftSection}>
                        <div className={error404.leftContent}>
                           <div className={error404.pageNotFound}>
                              Page Not Found
                           </div>
                        </div>
                     </div>

                     <div className={error404.rightSection}>

                        <div className={error404.rightBg}>
                           <div className={error404.errortext}>
                             <span><BsExclamationTriangleFill /></span>
                              OOPs!!! Looks like the page you are looking for is not available or missing. Kindly contact us for any futher problem.
                           </div>
                           <div className={error404.homepageBtn}>
                              <div>
                                 <Link className={error404.Link} to="/homepage" > Go back to HomePage </Link>
                              </div>
                              
                           </div>
                        </div>

                     </div>

                  </div>


               </div>
            </div>


            )
      
         }
      
      }
      
export default Error404;