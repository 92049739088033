import React, { Component } from 'react'
import classes from './Footer.module.css'
import { TiSocialFacebookCircular, TiSocialTwitterCircular, TiSocialPinterestCircular, TiSocialLinkedinCircular } from "react-icons/ti";
import Bitc from '../../assets/small-Icons/small-bitc.png'
import Cod from '../../assets/small-Icons/small-cod.png'
import Disc from '../../assets/small-Icons/small-disc.png'
import Master from '../../assets/small-Icons/small-master.png'
import Mastro from '../../assets/small-Icons/small-mastro.png'
import Ppal from '../../assets/small-Icons/small-ppal.png'
import Visa from '../../assets/small-Icons/small-visa.png'
import W from '../../assets/small-Icons/small-w.png'
import swal from 'sweetalert';
import axios from 'axios';




const Footer = (props) => {
   
   function contact(e){
      e.preventDefault();
      var email = document.getElementById('emailContact').value;
     
 
      fetch("https://bamziapi.ivyarc.com/api/contact",{
      /* fetch("http://localhost/bamzi/api/contact",{ */
         method: "POST",
         headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            email: email,
        })
      }).then((Response) => Response.json())
         .then((result) => {
            if(result.message == "success"){
               swal({
                  title: "Sucbribed!",
                  text: "We will be in touch.",
                  icon: 'success',
                  button: true
               });
            }
            else{
               swal({
                  title: "Error",
                  text: "Already Suscribed...",
                  icon: 'error',
                  button: true
               });
            }
         })
   }

      return (

         <div className={classes.footer} style={props.style}>

            <div className={classes.mailBox}>
               <div>
                  <div className={classes.contactM}>Contact Us!</div>
                  <span>Feel free to keep yourself constantly updated with the latest products and offers, brouht right to you. </span>
               </div>
               <div className={classes.btn}>
                  <input type="text" placeholder="Email here"  id="emailContact" className={classes.email} />
                  <button className={classes.btnSend} onClick={contact}> Send </button>
               </div>
            </div>

            <div className={classes.btmftr} >
               <div className={classes.btmftrCont}>
                  <div className={classes.btmftrFlex1}>
                     <div className={classes.ftrTitle}>BAM<span className={classes.ZI}>ZI</span></div>
                     <div className={classes.brdIcons}>
                        <TiSocialFacebookCircular />
                        <TiSocialTwitterCircular />
                        <TiSocialLinkedinCircular />
                        <TiSocialPinterestCircular />
                        <img src={W} alt="brands-logo" className={classes.brdIcon} />
                        <img src={Bitc} alt="brands-logo" className={classes.brdIcon} />
                        <img src={Cod} alt="brands-logo" className={classes.brdIcon} />
                        <img src={Disc} alt="brands-logo" className={classes.brdIcon} />
                        <img src={Master} alt="brands-logo" className={classes.brdIcon} />
                        <img src={Ppal} alt="brands-logo" className={classes.brdIcon} />
                        <img src={Visa} alt="brands-logo" className={classes.brdIcon} />
                        <img src={Mastro} alt="brands-logo" className={classes.brdIcon} />
                     </div>

                  </div>

                  <div className={classes.btmftrFlex2}>
                     <div className={classes.ftrTitle}>Links </div>
                     <div>
                           <div>Home</div>
                           <div>Pricing</div>
                           <div>Download</div>
                           <div>About</div>
                           <div>Service</div>
                     </div>
                  </div>

                  <div className={classes.btmftrFlex3}>
                     <div className={classes.ftrTitle}>Support </div>
                     <div>
                           <div>FAQ</div>
                           <div>How it Works</div>
                           <div>Features</div>
                           <div>Contact</div>
                           <div>Reporting</div>
                     </div>
                  </div>

                  <div className={classes.btmftrFlex4}>
                     <div className={classes.ftrTitle}>Contact Us </div>
                     <div>
                           <div>+44 7444 151270</div>
                           <div>contact@ivyarc.com</div>
                           <div>Lagos, Nigeria</div>
                     </div>
                  </div>


               </div>
            </div>

            <div className={classes.copyRightBar}>
               <div className={classes.copyRightBarCont}>
                  <div>Copyright and design by @ivyarc </div>
                  <div>Terms of Use &nbsp;&nbsp; &iota; &nbsp;&nbsp; Privacy Policy</div>
               </div>
            </div>

         </div >


      )

   

}

export default Footer
