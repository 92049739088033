import React, { Component } from 'react';
import reset from './resetPassword.module.css';
import LeftImg from '../../assets/preReg/Home - 7-image.jpg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faGooglePlusG } from '@fortawesome/free-brands-svg-icons';
import Navbar from '../../components/Navbar';
import swal from 'sweetalert';
import Header from '../../components/majors/Headers'




class ResetPassword extends Component {
    constructor() {
        super();
        this.onchangeToken = this.onchangeToken.bind(this);
        this.onchangePassword = this.onchangePassword.bind(this);
        this.onchangePasswordConfirm = this.onchangePasswordConfirm.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
           token: '',
           password: '',
           password_confirm: ''
        };
     };

     onchangeToken(e) {
        this.setState({
           token: e.target.value
        });
     };

     onchangePassword(e) {
        this.setState({
            password: e.target.value
        });
     };

     onchangePasswordConfirm(e) {
        this.setState({
           password_confirm: e.target.value
        });
     };

     onSubmit(event){
         fetch("https://bamziapi.ivyarc.com/api/reset", { 
        // fetch("http://localhost/bamzi/api/reset", {
            method: "POST",
            headers: {
                "Accept":"application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                token: this.state.token,
                password: this.state.password,
                password_confirm: this.state.password_confirm
            })
        }).then((Response) => Response.json())
            .then((result) => {
                if(result.message == "success"){
                    swal({
                        title: "Updated!",
                        text: "Password Updated Successfully...",
                        icon: 'success',
                        button: true
                     });
                    this.props.history.push("/login")
                }
                else{
                    swal({
                        title: "Error!",
                        text: "Please Fill All Fields...",
                        icon: 'error',
                        button: true
                     });
                }
            })

    };

    
    render() {
        return (
            <div className={reset.bodyContainer}>
                {/* Navbar Section */}
                <Header
                    A1="Products" A1r="/products"
                    A2="Stores"
                    A3="Pricing"
                    A4="Features" A4r="/features"

                />



                <div className={reset.bodyContent}>

                    <div className={reset.innerBodyContent}>
                        <div className={reset.leftSection}>
                            <div className={reset.leftContent}>
                                <div className={reset.veryCaption}> 
                                    Reset Password
                                </div>
                                <div className={reset.verySubCaption}> 
                                    Email@gmail.com
                                </div>
                                
                                <div className={reset.fieldSet}>
                                    
                                    <div className={reset.preForm} >
                                        <form action=".">
                                            <div><input type="text" placeholder="Enter OTP" className={reset.inputForm} value={this.state.token} name="token" onChange={this.onchangeToken} required  /></div>
                                            <div><input type="password" placeholder="New Password" className={reset.inputForm} value={this.state.password} name="password" onChange={this.onchangePassword} required /></div>
                                            <div><input type="password" placeholder="Repeat Password" className={reset.inputForm} value={this.state.password_confirm} name="password_confirm" onChange={this.onchangePasswordConfirm} required /></div>
                                        </form>
                                        <div className={reset.BookBtn} onClick={this.onSubmit}>
                                            <div>
                                                Reset
                                            </div>
                                        </div>
                                    </div>  

                                </div>
                            </div>
                        </div>

                        <div className={reset.rightSection}>

                            <div className={reset.rightBg}>
                                <img src={LeftImg} alt="leftImage" />
                            </div>

                        </div>

                    </div>


                </div>
            </div>

        )

    }

}

export default ResetPassword



