import React, { Component } from 'react'
import Stores from './AllStores.module.css'
import {Link} from 'react-router-dom'
import Mask from '../../assets/Mask.png'
import iPhone from '../../assets/homePage/iphone.png'
import { MdStar } from "react-icons/md";
import Car from '../../assets/homePage/car.jpg'
import Food from '../../assets/homePage/food.jpg'
import Phone from '../../assets/homePage/phones.jpg'
import CartBox from '../../assets/homePage/cartBox.png'
import agicFoods from '../../assets/homePage/agicFoods.png'
import stripImage from '../../assets/homePage/stripImage.png'
import Shoes from '../../assets/homePage/shoes.jpg'
import fashion from '../../assets/homePage/fashion.jpg'
import ApplePodsPic from '../../assets/prodItems/applePods.png';
import IphonePic from '../../assets/prodItems/iPhone.png';
import LaptopPic from '../../assets/prodItems/laptop.jpg';
import { FaLongArrowAltRight } from "react-icons/fa";
import { IoMdHeart, IoMdCart, IoMdHeartEmpty, IoMdMenu } from "react-icons/io";
import SidePic1 from '../../assets/features/Carousel2.png';
import SidePic2 from '../../assets/features/Carousel3.jpg';
import Header from '../../components/majors/Headers'
import Footer from '../../components/majors/Footer'
import Carousel from '../../components/minors/Carousel'
import Kemi from '../../assets/prodItems/Kemi.jpg'
import Filter from '../../components/majors/Filter'
import Product from '../../components/majors/Products'
import axios from 'axios';
import swal from 'sweetalert';

/* var pageURL = decodeURIComponent(window.location.href);
var splitURL = pageURL.split('/');
var storeName = splitURL[4]
console.log(storeName); */

class AllStores extends Component {

   state = {
      shops: [],
   }

   getShops = async () => {
      const shops = await axios.get("https://bamziapi.ivyarc.com/api/shops")
      /* const shops = await axios.get("http://localhost/bamzi/api/shops") */
      this.setState({ shops: shops.data })
   };

   componentDidMount() {
      this.getShops();
   }


   render() {

      const { shops } = this.state;
      return (
         <div className={Stores.Home}>

            {/* Navbar Section */}
            <Header
               A1="Products" A1r="/products"
               A2="Stores" A2r="/stores"
               A3={<input type="text" name="searchBar" placeholder="Search anything" />}

               B1={<span> Wishlist <IoMdHeartEmpty /> </span>} B1r="/wishlist"
               B2={<span> Cart <IoMdCart /></span>} B2r="/cart"
               B3={<img src={Kemi} alt="A profile pic" />} B3r="/userDetails"
               /* B4= {loggedIn ? "Logout" : "Login"  */

               style={{ backgroundColor: "#e9f0ff", borderBottom: "1px solid #ccc" }}

            />


            <div className={Stores.Container}>


               <div className={Stores.welcome}>
                  Glad to have you here.
                  <div>Navigate through specific stores for specific products. </div>
               </div>

               <div className={Stores.list}>

                  {shops.length > 0 ? shops.map(item => {
                     const { id, shopName, description } = item;

                     return (
                        <div className={Stores.store}>

                           <div className={Stores.image}>
                              <img src={fashion} alt="storeImg" />
                           </div>

                           <div className={Stores.details}>


                              <div className={Stores.title}>
                                 <Link to={`/specificstore/:${shopName}`}>
                                    {shopName}
                                 </Link>
                              </div>

                              <div className={Stores.desc}>{description} </div>

                              <div className={Stores.categories}>
                                 <div className={Stores.categoriesTit}>Categories</div>
                                 <div className={Stores.categoriesCent}>
                                    <div>Women's Fashion</div>
                                    <div>Men's Fashion</div>
                                    <div>Accessories</div>
                                    <div>Shoes</div>
                                 </div>
                              </div>

                           </div>
                        </div>
                     )
                  }): 
                     <div>
                        No Shop(s)
                     </div>
                  }



               </div>

            </div>







            {/* footer */}
            <Footer
               style={{ backgroundColor: "#e9f0ff" }}
            />

         </div>
      )

   }

}

export default AllStores;
