import React, { Component, setState } from 'react'
import ProdSpec from './ProdSpec.module.css';
import Header from '../../components/majors/Headers'
import CoverImage from '../../components/majors/CoverImage'
import Footer from '../../components/majors/Footer'
import Kemi from '../../assets/prodItems/Kemi.jpg'
import descCoverImage from '../../assets/productDetails/ElectronicsRUs.jpg'
import ApplePodsPic from '../../assets/prodItems/applePods.png';
import AppleWatchPic from '../../assets/prodItems/appleWatch.png';
import IphonePic from '../../assets/prodItems/iPhone.png';
import LaptopPic from '../../assets/prodItems/laptop.jpg';
import Saloon from '../../assets/prodItems/saloon.jpg';
import { FiSearch } from "react-icons/fi";
import { IoMdHeart, IoMdCart, IoMdHeartEmpty, IoMdMenu } from "react-icons/io";
import { BsCreditCard, BsEyeSlash } from "react-icons/bs";
import { FiGrid, FiTrash2 } from "react-icons/fi";
import { MdStore, MdStar } from "react-icons/md";
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';


var pageURL = decodeURIComponent(window.location.href);
var splitURL = pageURL.split('/');
var productName = splitURL[4]


const ProductSpec = (props) => {





   return (
      <div className={ProdSpec.ProductSpec}>
         <div className={ProdSpec.Container}>


            {/* Content */}

            <div className={ProdSpec.Content} >

               <div className="to">

                  <div className={ProdSpec.descText}>
                     {props.description}
                  </div>

                  <div className={ProdSpec.descCoverImage}>
                     <img src={props.descCoverImage} alt="A profile pic" className={ProdSpec.descCoverImageCent} />
                  </div>

                  <div className={ProdSpec.descExtra}></div>

               </div>

            </div>
         </div>

      </div>

   )

}



export default ProductSpec;
