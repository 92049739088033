import React,  {Component } from 'react';
import StoreLogo from '../assets/preReg/BAMZI.png';
import navstyles from './Navbar.module.css';




class Navbar extends Component {
   render(){

      return(
        <nav className="navbar navbar-expand-lg navbar-light bg-transparent text-bold">
            <a href="#" className="navbar-brand ml-5"><img src={StoreLogo} alt="StoreLogo" style={{width:"100px"}}></img></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ml-5">
                    <li className="nav-item ml-5 active">
                        <a className="nav-link" href="#">{this.props.list1} <span className="sr-only">(current)</span></a>
                    </li>
                    <li className="nav-item ml-5">
                        <a className="nav-link" href="#">{this.props.list2}</a>
                    </li>
                    <li className="nav-item ml-5">
                        <a className="nav-link" href="#">{this.props.list3}</a>
                    </li>
                    <li className="nav-item ml-5">
                        <a className="nav-link " href="#">{this.props.list4}</a>
                    </li>
                </ul>
            </div>
        </nav>
      )

   }

}

export default Navbar

