import React, { Component } from 'react'
import Products from './AllProducts.module.css'
import Mask from '../../assets/Mask.png'
import iPhone from '../../assets/homePage/iphone.png'
import { MdStar } from "react-icons/md";
import Car from '../../assets/homePage/car.jpg'
import Food from '../../assets/homePage/food.jpg'
import Phone from '../../assets/homePage/phones.jpg'
import CartBox from '../../assets/homePage/cartBox.png'
import agicFoods from '../../assets/homePage/agicFoods.png'
import stripImage from '../../assets/homePage/stripImage.png'
import Shoes from '../../assets/homePage/shoes.jpg'
import fashion from '../../assets/homePage/fashion.jpg'
import ApplePodsPic from '../../assets/prodItems/applePods.png';
import IphonePic from '../../assets/prodItems/iPhone.png';
import LaptopPic from '../../assets/prodItems/laptop.jpg';
import { FaLongArrowAltRight } from "react-icons/fa";
import { IoMdHeart, IoMdCart, IoMdHeartEmpty, IoMdMenu } from "react-icons/io";
import SidePic1 from '../../assets/features/Carousel2.png';
import SidePic2 from '../../assets/features/Carousel3.jpg';
import Header from '../../components/majors/Headers'
import Footer from '../../components/majors/Footer'
import Carousel from '../../components/minors/Carousel'
import Kemi from '../../assets/prodItems/Kemi.jpg'
import Filter from '../../components/majors/Filter'
import Product from '../../components/majors/Products'


/* var pageURL = decodeURIComponent(window.location.href);
var splitURL = pageURL.split('/');
var storeName = splitURL[4]
console.log(storeName); */

class AllProducts extends Component {


   render() {

      return (
         <div className={Products.Home}>

            {/* Navbar Section */}
            <Header
               A1="Products" A1r="/products"
               A2="Stores" A2r="/stores"
               A3={<input type="text" name="searchBar" placeholder="Search anything" />}

               B1={<span> Wishlist <IoMdHeartEmpty /> </span>} B1r="/wishlist"
               B2={<span> Cart <IoMdCart /></span>} B2r="/cart"
               B3={<img src={Kemi} alt="A profile pic" />} B3r="/userDetails"
               /* B4= {loggedIn ? "Logout" : "Login"  */
               

               style={{ backgroundColor: "#fff", borderBottom: "1px solid #ccc" }}

            />


            <Carousel />


            {/* SpecStoreProds */}
            <div className={Products.SpecStore}>
               <div className={Products.SpecStoreCont}>

                  {/* Filter */}
                  <Filter />

                  {/* ProductsView */}
                  <Product />

               </div>
            </div>



            {/* footer */}
            <Footer />

         </div>
      )

   }

}

export default AllProducts;
