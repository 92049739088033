import React, { Component } from 'react'
import classes from './UserProducts.module.css'
import { IoMdHeart, IoMdCart, IoMdHeartEmpty, IoMdMenu } from "react-icons/io";
import { FiGrid } from "react-icons/fi";
import ApplePodsPic from '../../assets/prodItems/applePods.png';
import AppleWatchPic from '../../assets/prodItems/appleWatch.png';
import IphonePic from '../../assets/prodItems/iPhone.png';
import LaptopPic from '../../assets/prodItems/laptop.jpg';
import { MdStar, MdStarBorder } from "react-icons/md";


class UserProducts extends Component {



   render() {

      return (

         <div className={classes.productView}>

            <div className={classes.prodTop}>
               <div>7,618 results found in 5ms</div>
               <div className={classes.prodTopIcons}>
                  <div><FiGrid /></div>
                  <div> <IoMdMenu /></div>
               </div>

            </div>

            <div></div>
            <div className={classes.prodItems}>
               <div className={classes.prodCard}>
                  <div className={classes.prodImage}>
                     <img src={AppleWatchPic} alt="A profile pic" className={classes.prodPic} />
                  </div>

                  <div className={classes.prodRatPrice}>
                     <div className={classes.prodRat}> 3.4 <span><MdStar /></span></div>
                     <div> $399</div>
                  </div>

                  <div className={classes.prodTitleDesc}>
                     <div>Apple Watch Series 4 GPS</div>
                     <span>Redesigned from scratch and completely revised</span>
                  </div>

                  <div className={classes.prodWishCart}>
                     <div className={classes.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                     <div className={classes.prodCart}> <IoMdCart /> ADD TO CART </div>
                  </div>

               </div>


               <div className={classes.prodCard}>
                  <div className={classes.prodImage}>
                     <img src={ApplePodsPic} alt="A profile pic" className={classes.prodPic} />
                  </div>

                  <div className={classes.prodRatPrice}>
                     <div className={classes.prodRat}> 3.4 <span><MdStar /></span></div>
                     <div> $399</div>
                  </div>

                  <div className={classes.prodTitleDesc}>
                     <div>Apple Pods Series Redtooth 5</div>
                     <span>Redesigned from scratch and completely revised</span>
                  </div>

                  <div className={classes.prodWishCart}>
                     <div className={classes.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                     <div className={classes.prodCart}> <IoMdCart /> ADD TO CART </div>
                  </div>

               </div>


               <div className={classes.prodCard}>
                  <div className={classes.prodImage}>
                     <img src={AppleWatchPic} alt="A profile pic" className={classes.prodPic} />
                  </div>

                  <div className={classes.prodRatPrice}>
                     <div className={classes.prodRat}> 3.4 <span><MdStar /></span></div>
                     <div> $399</div>
                  </div>

                  <div className={classes.prodTitleDesc}>
                     <div>Apple Watch Series 4 GPS</div>
                     <span>Redesigned from scratch and completely revised</span>
                  </div>

                  <div className={classes.prodWishCart}>
                     <div className={classes.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                     <div className={classes.prodCart}> <IoMdCart /> ADD TO CART </div>
                  </div>

               </div>


               <div className={classes.prodCard}>
                  <div className={classes.prodImage}>
                     <img src={ApplePodsPic} alt="A profile pic" className={classes.prodPic} />
                  </div>

                  <div className={classes.prodRatPrice}>
                     <div className={classes.prodRat}> 3.4 <span><MdStar /></span></div>
                     <div> $399</div>
                  </div>

                  <div className={classes.prodTitleDesc}>
                     <div>Apple Pods Series Redtooth 5</div>
                     <span>Redesigned from scratch and completely revised</span>
                  </div>

                  <div className={classes.prodWishCart}>
                     <div className={classes.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                     <div className={classes.prodCart}> <IoMdCart /> ADD TO CART </div>
                  </div>

               </div>


               <div className={classes.prodCard}>
                  <div className={classes.prodImage}>
                     <img src={IphonePic} alt="A profile pic" className={classes.prodPic} />
                  </div>

                  <div className={classes.prodRatPrice}>
                     <div className={classes.prodRat}> 3.4 <span><MdStar /></span></div>
                     <div> $399</div>
                  </div>

                  <div className={classes.prodTitleDesc}>
                     <div>iPhone XIVV</div>
                     <span>Redesigned from scratch and completely revised and completely revised</span>
                  </div>

                  <div className={classes.prodWishCart}>
                     <div className={classes.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                     <div className={classes.prodCart}> <IoMdCart /> ADD TO CART </div>
                  </div>

               </div>


               <div className={classes.prodCard}>
                  <div className={classes.prodImage}>
                     <img src={LaptopPic} alt="A profile pic" className={classes.prodPic} />
                  </div>

                  <div className={classes.prodRatPrice}>
                     <div className={classes.prodRat}> 3.4 <span><MdStar /></span></div>
                     <div> $399</div>
                  </div>

                  <div className={classes.prodTitleDesc}>
                     <div>Xiaomi Laptop Kiddies Version</div>
                     <span>Redesigned from scratch and completely revised</span>
                  </div>

                  <div className={classes.prodWishCart}>
                     <div className={classes.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                     <div className={classes.prodCart}> <IoMdCart /> ADD TO CART </div>
                  </div>

               </div>


               <div className={classes.prodCard}>
                  <div className={classes.prodImage}>
                     <img src={IphonePic} alt="A profile pic" className={classes.prodPic} />
                  </div>

                  <div className={classes.prodRatPrice}>
                     <div className={classes.prodRat}> 3.4 <span><MdStar /></span></div>
                     <div> $399</div>
                  </div>

                  <div className={classes.prodTitleDesc}>
                     <div>iPhone XIVV</div>
                     <span>Redesigned from scratch and completely revised and completely revised</span>
                  </div>

                  <div className={classes.prodWishCart}>
                     <div className={classes.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                     <div className={classes.prodCart}> <IoMdCart /> ADD TO CART </div>
                  </div>

               </div>


               <div className={classes.prodCard}>
                  <div className={classes.prodImage}>
                     <img src={LaptopPic} alt="A profile pic" className={classes.prodPic} />
                  </div>

                  <div className={classes.prodRatPrice}>
                     <div className={classes.prodRat}> 3.4 <span><MdStar /></span></div>
                     <div> $399</div>
                  </div>

                  <div className={classes.prodTitleDesc}>
                     <div>Xiaomi Laptop Kiddies Version</div>
                     <span>Redesigned from scratch and completely revised</span>
                  </div>

                  <div className={classes.prodWishCart}>
                     <div className={classes.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                     <div className={classes.prodCart}> <IoMdCart /> ADD TO CART </div>
                  </div>

               </div>

               <div className={classes.prodCard}>
                  <div className={classes.prodImage}>
                     <img src={ApplePodsPic} alt="A profile pic" className={classes.prodPic} />
                  </div>

                  <div className={classes.prodRatPrice}>
                     <div className={classes.prodRat}> 3.4 <span><MdStar /></span></div>
                     <div> $399</div>
                  </div>

                  <div className={classes.prodTitleDesc}>
                     <div>Apple Pods Series Redtooth 5</div>
                     <span>Redesigned from scratch and completely revised</span>
                  </div>

                  <div className={classes.prodWishCart}>
                     <div className={classes.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                     <div className={classes.prodCart}> <IoMdCart /> ADD TO CART </div>
                  </div>

               </div>

               <div className={classes.prodCard}>
                  <div className={classes.prodImage}>
                     <img src={LaptopPic} alt="A profile pic" className={classes.prodPic} />
                  </div>

                  <div className={classes.prodRatPrice}>
                     <div className={classes.prodRat}> 3.4 <span><MdStar /></span></div>
                     <div> $399</div>
                  </div>

                  <div className={classes.prodTitleDesc}>
                     <div>Xiaomi Laptop Kiddies Version</div>
                     <span>Redesigned from scratch and completely revised</span>
                  </div>

                  <div className={classes.prodWishCart}>
                     <div className={classes.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                     <div className={classes.prodCart}> <IoMdCart /> ADD TO CART </div>
                  </div>

               </div>

               <div className={classes.prodCard}>
                  <div className={classes.prodImage}>
                     <img src={AppleWatchPic} alt="A profile pic" className={classes.prodPic} />
                  </div>

                  <div className={classes.prodRatPrice}>
                     <div className={classes.prodRat}> 3.4 <span><MdStar /></span></div>
                     <div> $399</div>
                  </div>

                  <div className={classes.prodTitleDesc}>
                     <div>Apple Watch Series 4 GPS</div>
                     <span>Redesigned from scratch and completely revised</span>
                  </div>

                  <div className={classes.prodWishCart}>
                     <div className={classes.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                     <div className={classes.prodCart}> <IoMdCart /> ADD TO CART </div>
                  </div>

               </div>

               <div className={classes.prodCard}>
                  <div className={classes.prodImage}>
                     <img src={IphonePic} alt="A profile pic" className={classes.prodPic} />
                  </div>

                  <div className={classes.prodRatPrice}>
                     <div className={classes.prodRat}> 3.4 <span><MdStar /></span></div>
                     <div> $399</div>
                  </div>

                  <div className={classes.prodTitleDesc}>
                     <div>iPhone XIVV</div>
                     <span>Redesigned from scratch and completely revised and completely revised</span>
                  </div>

                  <div className={classes.prodWishCart}>
                     <div className={classes.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                     <div className={classes.prodCart}> <IoMdCart /> ADD TO CART </div>
                  </div>

               </div>

               <div className={classes.prodCard}>
                  <div className={classes.prodImage}>
                     <img src={IphonePic} alt="A profile pic" className={classes.prodPic} />
                  </div>

                  <div className={classes.prodRatPrice}>
                     <div className={classes.prodRat}> 3.4 <span><MdStar /></span></div>
                     <div> $399</div>
                  </div>

                  <div className={classes.prodTitleDesc}>
                     <div>iPhone XIVV</div>
                     <span>Redesigned from scratch and completely revised and completely revised</span>
                  </div>

                  <div className={classes.prodWishCart}>
                     <div className={classes.prodWish}> <IoMdHeartEmpty /> WISHLIST</div>
                     <div className={classes.prodCart}> <IoMdCart /> ADD TO CART </div>
                  </div>

               </div>

            </div>

         </div>

      )

   }

}

export default UserProducts;