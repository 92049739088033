import React, { Component, useState, useEffect } from 'react'
import classes from './Header.module.css'
import Kemi from '../../assets/prodItems/Kemi.jpg'
import { FiSearch } from "react-icons/fi";
import { CgMenuBoxed } from "react-icons/cg";
import { IoMdHeart, IoMdCart, IoMdHeartEmpty, IoMdMenu } from "react-icons/io";
import { Link } from 'react-router-dom'




const Header = props => {



   const [navappear, setNavappear] = useState(false);
   const [width, setWidth] = useState(window.innerWidth);

   const updateWidth = () => {
      setWidth(window.innerWidth);
   }

   useEffect(() => {
      window.addEventListener("resize", updateWidth);
      return () => window.removeEventListener("resize", updateWidth);
   })

   const menuShow = () => {
      setNavappear(!navappear)
   }

   let smallSize = <div> <CgMenuBoxed onClick={menuShow} className={classes.menuButton} /> </div>

   let navMenu = <div className={classes.NavList} onClick={menuShow}>

      <div>
         <ul className={classes.NavList1} >
            <li> <Link className={classes.Link} to={props.A1r} >{props.A1}</Link></li>
            <li> <Link className={classes.Link} to={props.A2r} >{props.A2}</Link></li>
            <li> <Link className={classes.Link} to={props.A3r} >{props.A3}</Link></li>
            <li> <Link className={classes.Link} to={props.A4r} >{props.A4}</Link></li>
         </ul>
      </div>

      <div>
         <ul className={classes.NavList2} >
            <li> <Link className={classes.Link} to={props.B1r} >{props.B1}</Link></li>
            <li> <Link className={classes.Link} to={props.B2r} >{props.B2}</Link></li>
            <li> <Link className={classes.Link} to={props.B3r} >{props.B3}</Link></li>
            <li> <Link className={classes.Link} to={props.B4r} >{props.B4}</Link></li>
         </ul>
      </div>

   </div>

   if (navappear) {
      smallSize = navMenu 
   }


   return (
      <div className={classes.Header} style={props.style}>
         <div className={classes.HeaderCont} style={props.style2} >
            <div className={classes.Title}>
               <Link className={classes.Link} to="/homepage" >
                  BAM<span className={classes.ZI}>ZI</span>
               </Link>
            </div>

            {width > 800 ?  navMenu  :  smallSize  }



         </div>
      </div>

   )



}

export default Header;
