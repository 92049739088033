import React, { Component } from 'react'
import classes from './CategoryBar.module.css'
import { VscListSelection } from "react-icons/vsc";



class CategoryBar extends Component {

   render() {

      return (

         <div className={classes.catBar}>
            <div className={classes.catBarCont}>
               <div className={classes.catBarStCat}> <VscListSelection /> &nbsp;&nbsp; Store Categories </div>
               <div>
                  <ul>
                     <li>Apple</li>
                     <li>Samsung</li>
                     <li>Meizu</li>
                     <li>Xiaomi</li>
                     <li>Honor</li>
                     <li>Huawei</li>
                     <li>Asus</li>
                     <li>Nokia</li>
                     <li>Motorola</li>
                  </ul>
               </div>
            </div>
         </div>

      )

   }

}

export default CategoryBar
