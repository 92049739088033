import React, { Component } from 'react'
import prelaunch from './prelaunch.module.css'
import DummyPic from '../../assets/Mask.png'
import { MdStore } from "react-icons/md";
import { IoMdCart } from "react-icons/io";
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert';
import Header from '../../components/majors/Headers'


class PreLaunch extends Component {
   constructor() {
      super();
      this.onchangeFullname = this.onchangeFullname.bind(this);
      this.onchangeName = this.onchangeName.bind(this);
      this.onchangeEmail = this.onchangeEmail.bind(this);
      this.onchangeIndustry = this.onchangeIndustry.bind(this);
      this.customerTypeSell = this.customerTypeSell.bind(this);
      this.customerTypeBuy = this.customerTypeBuy.bind(this);
      this.onSubmit = this.onSubmit.bind(this);
      this.checkmail = this.checkmail.bind(this);
      

      this.state = {
         fullname: "",
         name: "",
         email: "",
         industry: "",
         customerType: "Seller",
         disabled: true
      };
   };


   onchangeFullname(e) {
      this.setState({
         fullname: e.target.value
      });
   };
   onchangeName(e) {
      this.setState({
         name: e.target.value
      });
   };
   onchangeEmail(e) {
      this.setState({
         email: e.target.value
      });
   };
   onchangeIndustry(e) {
      this.setState({
         industry: e.target.value
      });
   };

   customerTypeSell(e) {
      e.preventDefault();
      this.setState({
         customerType: "Seller"
      })
   };
   
   customerTypeBuy(e) {
      e.preventDefault();
      this.setState({
         customerType: "Buyer"
      })
   };

   checkmail(){
      var emailVal = {email: this.state.email};

      $.ajax({
          url: "https://bamziapi.ivyarc.com/api/checkmail",
         // url: "http://localhost/bamzi/api/checkmail",
         type: "POST",
         data: emailVal,
      }).done(function(response){
         if(response === "Email Already In Use."){
            swal({
               title: "Error",
               text: "User With Email Already Exist....",
               icon: 'error',
               button: true
            });
         }else{
            $("bookBtn").prop('disabled', false); 
         }
   });
};

/**
   checkmail(e){
      e.preventDefault();
      const valdata = {
         email: this.state.email
      }
      axios.post('http://localhost/bamzi/api/checkmail', valdata)
      .then(res => alert("email val"))
   };
*/

   onSubmit(e) {
      e.preventDefault();
      const regdata = {
         fullname: this.state.fullname,
         name: this.state.name,
         email: this.state.email,
         industry: this.state.industry,
         customerType: this.state.customerType
      }
      // axios.post('http://localhost/bamzi/api/preorder', regdata)
    axios.post("https://bamziapi.ivyarc.com/api/preorder", regdata)
      .then((res) => {
         swal({
            title: "Registered!",
            text: "PreOrder Created Successfully..",
            icon: 'success',
            button: true
         });
         this.props.history.push("/postRegistration");
      })
      
   };


   render() {

      let Custype = (
         <div className={prelaunch.btn}>
            <button className={prelaunch.btnBuy} onClick={this.customerTypeBuy} onBlur={this.checkmail} >
               <div className={prelaunch.btnAlgn}> <IoMdCart />
                  <span className={prelaunch.btnResText}> Buyer </span>
               </div>
            </button>

            <button className={prelaunch.btnSell} onClick={this.customerTypeSell} onBlur={this.checkmail} >
               <div className={prelaunch.btnAlgn}> <MdStore />
                  <span className={prelaunch.btnResText}> Seller </span>
               </div>
            </button>
         </div>
      )

      if (this.state.customerType === "Buyer"){
         Custype = (
            <div className={prelaunch.btn}>
               <button className={prelaunch.btnSell} onClick={this.customerTypeBuy} onBlur={this.checkmail} >
                  <div className={prelaunch.btnAlgn}> <IoMdCart />
                     <span className={prelaunch.btnResText}> Buyer </span>
                  </div>
               </button>

               <button className={prelaunch.btnBuy} onClick={this.customerTypeSell} onBlur={this.checkmail} >
                  <div className={prelaunch.btnAlgn}> <MdStore />
                     <span className={prelaunch.btnResText}> Seller </span>
                  </div>
               </button>
            </div>
         )

      }


      return (
         <div className={prelaunch.PreLaunch}>
            <div className={prelaunch.Container}>
                {/* Navbar Section */}
                <Header 
                  A1="Contact Us" 
                  A2="Features"    A2r="/features"

                  style2={{width: '100%', padding: '20px 48px 0px'}}
                  
                  
               />

               <div className={prelaunch.flex}>
                  <div className={prelaunch.content}>
                     <div className={prelaunch.contentText}>
                        <span className={prelaunch.caption}>Get Ready, Online stores coming through</span>
                        <p className={prelaunch.subcaption}>Get amazing rewards and discounts as you become amongst the first to explore our new and vast digital market.</p>
                     </div>

                     <div className={prelaunch.form}>

                        <form>
                           <div className={prelaunch.btn}>
                              <input type="text" placeholder="Full Name" className={prelaunch.name} value={this.state.fullname} onBlur={this.checkmail} name="name" onChange={this.onchangeFullname} Required />
                           </div>

                           <div className={prelaunch.btn}>
                              <input type="email" placeholder="Email here" className={prelaunch.email} value={this.state.email} onBlur={this.checkmail} name="email" onChange={this.onchangeEmail} required />
                              <input type="text" placeholder="Industry" className={prelaunch.industry} value={this.state.industry} onBlur={this.checkmail} name="industry" onChange={this.onchangeIndustry} required />
                           </div>

                           {Custype}

                           
                           <button className={prelaunch.btnRes} onClick={this.onSubmit} id="bookBtn" >
                              <div className={prelaunch.btnAlgn}>
                                 <span className={prelaunch.btnResText}>  Book your reservation </span>
                              </div>
                           </button>
                        </form>
                     </div>


                  </div>

                  <div className={prelaunch.DummyPic}>

                     <img src={DummyPic} alt="DummyPic" />

                  </div>
               </div>
            </div>
         </div>
      )

   }

}

export default PreLaunch
